import React, {useContext, useEffect, useRef, useState} from "react";
import L from "leaflet";
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet-contextmenu/dist/leaflet.contextmenu"
import "leaflet-contextmenu/dist/leaflet.contextmenu.css"
import {apiAnalysis, apiExternal} from "../../service";
import 'leaflet-geosearch/dist/geosearch.css'
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {AnalysisContext} from "../../context/AnalysisContext";
import MapLayerSwitcher from "../maptools/layerSwitcher/MapLayerSwitcherSidebar";
import MapLegend from "../maptools/MapLegend";
import * as proj4leaflet from "proj4leaflet";
import "../../utils/TileLayer.ProjWMTS"
import CircularProgress from "@material-ui/core/CircularProgress";
import {ErrorContext} from "../../context/ErrorContext";
import 'leaflet.tilelayer.colorfilter'
import FeatureInfoSidebar from "../maptools/featureInfo/FeatureInfoSidebar";
import MeasureTools from "../maptools/MeasureTools";
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-geometryutil'
import proj4 from 'proj4'
import {gugikHeights} from "../../utils/ExtService";
import 'leaflet-measure-path'
import 'leaflet-measure-path/leaflet-measure-path.css'
import IsochroneToolbar from "../maptools/isochonesToolbar/IsochronesToolbar";
import PoiManager from "../maptools/poiManager/PoiManager";
import {add} from "proj4/lib/projections";

import 'leaflet-iconmaterial/dist/leaflet.icon-material.css'
import 'leaflet-iconmaterial/dist/leaflet.icon-material'
import instance from "../../api/SettingsInstance";

import 'leaflet.beautifymarker/leaflet-beautify-marker-icon'
import 'leaflet.beautifymarker/leaflet-beautify-marker-icon.css'

import 'leaflet-pegman/leaflet-pegman.css'
import 'leaflet-pegman/leaflet-pegman'
import SpatialTools from "../maptools/SpatialToolbar";
import moment from "moment";
import {decode} from '@here/flexpolyline'

// import 'leaflet-pegman'
const provider = new OpenStreetMapProvider();

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function ResultMap(props) {

    let {
        setFeatureInfo, style,setStyle,
        paiOpacity, setPaiOpacity, analysisId, indicatorLayers,
        baseLayer, overLayer,
        addSpinnerMsg, removeSpinnerMsg,
        bestAreas, setBestAreas,
        bestAreasVisible, setBestAreasVisible,
        analysisGeomWKT, setAnalysisGeomWKT,
        featureInfoComponents, setFeatureInfoComponents,
        hexPercentilesInfo, setHexPercentilesInfo,
        measureLine, setMeasureLine,
        measurePolygon, setMeasurePolygon,
        measurePoint, setMeasurePoint,
        clearMeasures, setClearMeasures,
        analysisGeometry,

        isochroneStart, setIsochroneStart,
        addIsoPoint, setAddIsoPoint,
        generateIso, setGenerateIso,
        isoTime, setIsoTime,
        isoTransportType, setIsoTransportType,
        removeIso, setRemoveIso,

        addUserPoint, setAddUserPoint,
        userPointLabel, setUserPointLabel,
        userPointColor, setUserPointColor,
        hideUserPoint, setHideUserPoint,
        isAnalysisVisible, setIsAnalysisVisible,
        mapZoomLvl, setMapZoomLvl,

        routeOrigin, setRouteOrigin,
        routeDestination, setRouteDestination,
        routeTransportType, setRouteTransportType,
        generateRoute, setGenerateRoute,
        removeRoutes, setRemoveRoutes,
        addRouteOrigin, setAddRouteOrigin,
        addRouteDestination, setAddRouteDestination


    } = useContext(AnalysisContext)


    let {setInfo} = useContext(ErrorContext);

    const mapRef = useRef(null);
    const [analysisData, setAnalysisData] = useState(null);
    const [currentStyle, setCurrentStyle] = useState("standard");


    function getColor(d) {
        return d > 0.9 ? '#00962d' :
            d > 0.8 ? '#2fb11b' :
                d > 0.7 ? '#5fcc09' :
                    d > 0.6 ? '#93e100' :
                        d > 0.5 ? '#c9f000' :
                            d > 0.4 ? '#ffff00' :
                                d > 0.3 ? '#ffca00' :
                                    d > 0.2 ? '#fe9500' :
                                        d > 0.1 ? '#f66200' :
                                            d > 0 ? '#e73102' :
                                                d >= 0 ? '#d70003' :
                                                    '#ffffff';
    }

    function deafultStyle(feature) {
        return {
            fillColor: getColor(feature.properties["wynik"]),
            weight: 0.4,
            opacity: 1,
            color: 'white',
            // dashArray: '3',
            // fillOpacity: 0.55
            fillOpacity: paiOpacity
        };
    }



    let selectedObject = L.geoJSON(null, {
        color: 'red',
        weight: 10,
        fillOpacity: 0,
        layerName: "hex"
    })

    function selectFeature(e) {
        var layer = e.target;
        var geojsonFakeData = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": layer.feature.geometry
                }
            ]
        }

        selectedObject
            .clearLayers()
            .addData(geojsonFakeData)
            .addTo(mapRef.current)
            .bringToFront()

        setFeatureInfo(layer.feature.properties)
        // console.log(layer.feature.properties.id, props.data)

        console.log(layer.feature.properties)
        apiAnalysis.getAnalysisByAnalysisIdComponentvalues({
            analysis_id: props.data,
            hexId: layer.feature.properties.id
        })
            .then(body => {
                setFeatureInfoComponents(body)
                console.log(body)
            })

        apiAnalysis.getAnalysisByAnalysisIdPercentiles({
            analysis_id: props.data,
            hexId: layer.feature.properties.id
        }).then(body => {
                console.log(body)
                setHexPercentilesInfo(body)
            }
        )

    }


    function onEachFeature(feature, layer) {
        layer.on({
            click: selectFeature,
        });
    }


    let paiAnalysis = L.geoJSON(false, {
        layerName: "pai",
        onEachFeature: onEachFeature,

    })


    function getColor2(hexagonValue, style) {
        let result, d;
        if (hexagonValue === 't') {
            d = 1
        } else if (hexagonValue === 'f') {
            d = 0
        } else {
            d = hexagonValue
        }
        style.forEach((element, index) => {
            if (parseFloat(d) <= element.up_lim &&
                parseFloat(d) > element.low_lim) {
                result = 'rgb(' + element.RGB + ')'
            } else if (parseFloat(d) === 0) {
                result = 'rgb(' + style[0].RGB + ')'
            } else if (style[0].low_lim == parseFloat(d)) {
                result = 'rgb(' + style[0].RGB + ')'
            }
        })
        return result
    }

    function indicatorStyle(feature) {
        return {
            fillColor: getColor2(feature.properties[style.id], style.interval),
            weight: 0.4,
            opacity: 1,
            color: 'white',
            // dashArray: '3',
            // fillOpacity: 0.7
            fillOpacity: paiOpacity
        };
    }


    useEffect(
        () => {
            const openStreetView = (e) => {
                window.open('http://maps.google.com/maps?q=&layer=c&cbll=' + e.latlng.lat + ',' + e.latlng.lng, '', 'height=400,width=550')
                console.log(mapRef.current.getZoom())

            }

            mapRef.current = L.map(props.id, {
                // center: [props.center[1], props.center[0]],
                center: [51, 21],
                zoom: 5,
                zoomControl: false,
                // maxBounds: [
                //     //south west
                //     [40.712, -74.227],
                //     //north east
                //     [40.774, -74.125]
                // ],
                doubleClickZoom: false,
                contextmenu: true,
                maxZoom: 21,
                contextmenuWidth: 120,
                contextmenuItems: [
                    {
                        text: "StreetView",
                        callback: openStreetView
                    }]
            }).whenReady(() => {
                console.log("lead ready")
                apiAnalysis.getAnalysisByAnalysisIdResult({analysis_id: props.data})
                    // .getAnalysisByAnalysisId(props.data)
                    .then(body => {
                        console.log(body, 'paiGeojson')
                        // paiAnalysis.addData(body)
                        setAnalysisData(body)
                        mapRef.current.fitBounds(L.geoJSON(body).getBounds())
                    })
                    .catch(reason => {
                        console.log(reason)
                    })

            }).on("zoom", () => {
                setMapZoomLvl(mapRef.current.getZoom())
            })


            const searchControl = new GeoSearchControl({
                position: 'topright',
                provider: provider,
            });

            // mapRef.current.addControl(searchControl);

            L.control.scale({
                metric: true,
                imperial: false,
                position: 'bottomright'
            }).addTo(mapRef.current);

            // var gugik_orto_nowe = L.tileLayer.wms('https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMS/StandardResolution', {
            //     layers: 'Raster',
            //     format: 'image/jpeg',
            //     transparent: true,
            //     maxZoom: 21
            // })
            // var KIUT = L.tileLayer.wms("https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaUzbrojeniaTerenu", {
            //     //layers: 'przewod_wodociagowy',
            //     layers: 'przewod_wodociagowy,przewod_kanalizacyjny,przewod_gazowy,przewod_elektroenergetyczny,przewod_telekomunikacyjny,przewod_cieplowniczy',
            //     format: 'image/png',
            //     transparent: true,
            //     attribution: "test",
            //     maxZoom: 21
            //
            // });
            // var EGIB = L.tileLayer.wms("https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow", {
            //     layers: 'dzialki,numery_dzialek',
            //     format: 'image/png',
            //     transparent: true,
            //     attribution: "test",
            //     maxZoom: 21
            //
            // });
            // var OpenStreetMap_HOT = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            //     maxZoom: 21,
            //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
            // })
            // .addTo(mapRef.current)

            // var baseMaps = {
            //     "Podkład mapowy (OSM)": OpenStreetMap_HOT,
            //     "Ortofotomapa": gugik_orto_nowe,
            // };
            //
            // var overlayMaps = {
            //     "Uzbrojenie terenu (KIUT)": KIUT,
            //     "Ewidencja gruntów (EGIB)": EGIB,
            // };

            //nie usuwać
            // L.control.layers(baseMaps, overlayMaps).addTo(mapRef.current);

            var pegmanControl = new L.Control.Pegman({
                apiKey: 'AIzaSyBsnhMdof7HsKpqyFwK3_xCJQRTbdVF92M', // CHANGE: with your google maps api key
                position: 'bottomright', // position of control inside the map
                // theme: "leaflet-pegman-v3-small", // or "leaflet-pegman-v3-small"
                panoDiv: '#panorama'
            });
            pegmanControl.addTo(mapRef.current);

        }, []
    );


    useEffect(
        () => {
            if (analysisData !== null) {
                // let paiAnalysis = L.geoJSON(false, {
                //     layerName: "pai",
                //     onEachFeature: onEachFeature,
                // })

                // setStyle("standard")

                console.log(style, "style")
                if (isAnalysisVisible) {
                    console.log(style, "style", deafultStyle)
                    paiAnalysis.addData(analysisData)

                        // (style === 'style' || style=== "standard") ? deafultStyle : indicatorStyle
                    paiAnalysis.setStyle(deafultStyle)
                    // paiAnalysis.setStyle((style === undefined) ? deafultStyle : indicatorStyle)
                    paiAnalysis.addTo(mapRef.current);

                    paiAnalysis.bringToFront()
                    // console.log(paiAnalysis.getBounds())
                    // mapRef.current.fitBounds(paiAnalysis.getBounds())
                    // mapRef.current.fitBounds()



                } else {
                    mapRef.current.eachLayer((layer) => {
                        if (layer.options.layerName === "hex") {
                            layer.removeFrom(mapRef.current)
                        }

                        if (layer.options.layerName === "pai") {
                            layer.removeFrom(mapRef.current)

                        }
                    })
                }


            }

        }, [analysisData, isAnalysisVisible]
    )


    useEffect(() => {


        if (style !== "standard") {
                mapRef.current.eachLayer((layer) => {
                    if (layer.options.layerName === "pai") {
                        layer.setStyle(indicatorStyle)
                    }
                })
            } else if (style === "standard") {
                mapRef.current.eachLayer((layer) => {
                    if (layer.options.layerName === "pai") {
                        layer.setStyle(deafultStyle)
                    }
                })
            }

        }, [style, isAnalysisVisible]
    )

    useEffect(
        () => {
            if (indicatorLayers.value) {
                indicatorLayers.value.map((element) => {
                    switch (element.type) {
                        case "WMS":
                            console.log("wms logic")
                            let transWms = L.tileLayer.wms(instance.geoserverUrl + "/pai/wms?", {
                                layerType: "transWMS",
                                layerName: element.geoserver_layer_name,
                                jwt: JSON.parse(localStorage.pai_tokens).access_token,
                                layers: element.geoserver_layer_name,
                                viewparams: `analysis_id:${analysisId}`,
                                cql_filter: `INTERSECTS(geom,${analysisGeomWKT})`,
                                format: 'image/png',
                                transparent: true
                            })

                            if (!indicatorLayers.visibleOnMap) {
                                mapRef.current.eachLayer((layer) => {
                                    if (layer.options.layerName === element.geoserver_layer_name) {
                                        layer.removeFrom(mapRef.current)
                                    }
                                })
                            } else {
                                transWms.setZIndex(99999)
                                mapRef.current.addLayer(transWms);

                            }

                            break;
                        case "WFS":
                            console.log("wfs logic")
                            let wfsLayer = element.geoserver_layer_name.substr(4)
                            // let icon = indicatorLayers.value[0].symbol
                            if (!indicatorLayers.visibleOnMap) {
                                mapRef.current.eachLayer((layer) => {
                                    if (layer.options.typeName === wfsLayer) {
                                        layer.removeFrom(mapRef.current)
                                    }
                                })
                            } else {
                                fetch(instance.geoserverUrl +"/pai/ows" +
                                    "?service=WFS&version=1.0.0&request=GetFeature" +
                                    "&typeName=pai%3A" + wfsLayer +
                                    "&outputFormat=application%2Fjson" +
                                    "&srsName=EPSG:4326" +
                                    "&cql_filter=" + `INTERSECTS(geom,${analysisGeomWKT})` +
                                    "&jwt=" + JSON.parse(localStorage.pai_tokens).access_token

                                )
                                    .then((response) => {
                                        response.json()
                                            .then((data) => {
                                                L.geoJSON(data, {
                                                    typeName: wfsLayer,
                                                    pointToLayer: (feature, latlng) => {
                                                        var opt = {
                                                            borderColor: '#000000',
                                                            borderWidth: 0,
                                                            // html: `<span class="material-icons md-10">${indicatorLayers.value[0].symbol}</span>`,
                                                            html: `<span class="material-icons md-10">${indicatorLayers.value[0].symbol}</span>`,
                                                            // backgroundColor:indicatorLayers.value[0].style,
                                                            backgroundColor: 'rgb(255,217,102,1)',
                                                            // backgroundColor: indicatorLayers.value[0].style.replaceAll('"','\''),
                                                            textColor: "white",
                                                            // icon: 'leaf',
                                                            iconSize: [20, 20],
                                                            popupAnchor: [7, 0],
                                                            //     html: `<span style="{{font-size: 10px}}" class="material-icons md-36">${indicatorLayers.value[0].symbol}</span>`,
                                                            // html: `<span class="material-icons">indicatorLayers.value[0].symbol</span>`,
                                                            iconShape: 'circle-dot'
                                                        }

                                                        // console.log(opt)
                                                        return L.marker(latlng, {
                                                            icon: L.BeautifyIcon.icon(element.style)
                                                        })
                                                        // .on({
                                                        // mouseover: (e) => {
                                                        //     e.target.setIcon(L.divIcon({
                                                        //         className: 'location-pin',
                                                        //         // html: '<span class="material-icons">indicatorLayers.value[0].symbol</span>',
                                                        //         html: `<i class="material-icons md-18">${indicatorLayers.value[0].symbol}</i>`,
                                                        //         // html: '<span class="material-icons">tram</span><span>' + geoJsonPoint.properties.name+ '</span>',
                                                        //         iconSize: [40, 40],
                                                        //         iconAnchor: [20, 40]
                                                        //     }));
                                                        // },
                                                        //     mouseout: (e) => {
                                                        //         e.target.setIcon(L.divIcon({
                                                        //             className: 'location-pin',
                                                        //             html: `<i class="material-icons md-18">${indicatorLayers.value[0].symbol}</i>`,
                                                        //             iconSize: [30, 30],
                                                        //             iconAnchor: [15, 30]
                                                        //         }));
                                                        //     },
                                                        // });

                                                    },
                                                    onEachFeature: (feature, layer) => {
                                                        var popupContent = ""
                                                        for (const [key, value] of Object.entries(feature.properties)) {
                                                            popupContent += `<b>${key}</b>: ${value !== null ? value : "-"}</br>`
                                                        }
                                                        layer.bindPopup(popupContent);
                                                    }
                                                }).addTo(mapRef.current)


                                            }).catch((err) => {
                                            console.log('coś poszło nie tak', err);
                                        })
                                    });
                                // console.log(wfstPoint2.options.layerName)
                                // wfstPoint2.addTo(mapRef.current)
                            }

                            break;
                    }

                })




                if (analysisGeomWKT === null) {
                    mapRef.current.eachLayer((layer) => {
                        if (layer.options.layerType === "transWMS") {
                            layer.removeFrom(mapRef.current)
                        }
                    })
                }


            }
        }
        ,
        [indicatorLayers, analysisGeomWKT]
    )

    // useEffect(
    //     () => {
    //         if (indicatorLayers.value) {
    //             let layers = indicatorLayers.value.map((element) => {
    //                 return element.geoserver_layer_name
    //             })
    //
    //             console.log(instance.geoserverUrl)
    //
    //             let transWms = L.tileLayer.wms(instance.geoserverUrl + "/pai/wms?", {
    //                 layerType: "transWMS",
    //                 layerName: JSON.stringify(layers),
    //                 jwt: JSON.parse(localStorage.pai_tokens).access_token
    //             })
    //             if (!indicatorLayers.visibleOnMap) {
    //                 mapRef.current.eachLayer((layer) => {
    //                     if (layer.options.layerName === JSON.stringify(layers)) {
    //                         layer.removeFrom(mapRef.current)
    //                     }
    //                 })
    //             } else {
    //                 transWms.setParams({
    //                     zIndex: 99999999,
    //                     // layers: "metro",
    //                     layers: layers,
    //                     viewparams: `analysis_id:${analysisId}`,
    //                     cql_filter: `INTERSECTS(geom,${analysisGeomWKT})`,
    //                     format: 'image/png',
    //                     transparent: true
    //                 }, false)
    //
    //                 mapRef.current.addLayer(transWms);
    //                 transWms.setZIndex(99999)
    //                 // transWms.setOpacity(0.5)
    //             }
    //             // console.log(analysisGeomWKT)
    //             console.log(layers)
    //             console.log(JSON.stringify(layers))
    //
    //
    //             if (analysisGeomWKT === null) {
    //                 mapRef.current.eachLayer((layer) => {
    //                     if (layer.options.layerType === "transWMS") {
    //                         layer.removeFrom(mapRef.current)
    //                     }
    //                 })
    //             }
    //
    //         }
    //     }, [indicatorLayers, analysisGeomWKT])

    useEffect(
        () => {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.layerName === "pai") {
                    layer.setStyle({fillOpacity: paiOpacity})
                }
            })
        }, [paiOpacity]
    )

    useEffect(
        () => {
            let crs2180 = new L.Proj.CRS(
                "EPSG:2180",
                "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 units=m +no_defs",
                {}
            );

            var base;
            console.log("baseLayer ", baseLayer.uri, baseLayer.options)

            if (baseLayer.service_type === "tms") {
                console.log(baseLayer, "baseLayer")
                base = L.tileLayer(baseLayer.uri, baseLayer.options)
                // .on("loading", (e) => {
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("load", (e) => {
                //     removeSpinnerMsg(e.target.options.layerName)
                // })
                // .on("tileerror", (e) => {
                //
                // })
            } else if (baseLayer.service_type === "wms") {
                // console.log(baseLayer)
                base = L.tileLayer.wms(baseLayer.uri, baseLayer.options)
                // .on("loading", (e) => {
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("load", (e) => {
                //     removeSpinnerMsg(e.target.options.layerName)
                // })
                // .on("tileerror", (e) => {
                //
                // })
            } else if (baseLayer.service_type === "wmts") {
                // console.log(baseLayer)
                baseLayer.options.crs = crs2180
                base = L.tileLayer.projwmts(baseLayer.uri, baseLayer.options)
                // .on("loading", (e) => {
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("load", (e) => {
                //     removeSpinnerMsg(e.target.options.layerName)
                // })
                // .on("tileerror", (e) => {
                //
                // })
            } else if (baseLayer.service_type === "tmsFilter") {
                console.log("tmsF", baseLayer.uri, baseLayer.options)
                base = L.tileLayer.colorFilter(baseLayer.uri, baseLayer.options)

                // .on("loading", (e) => {
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("load", (e) => {
                //     removeSpinnerMsg(e.target.options.layerName)
                // })
                // .on("tileerror", (e) => {
                //
                // })
            }

            mapRef.current.eachLayer((layer) => {
                if (layer.options.baseLayer === true) {
                    layer.removeFrom(mapRef.current)
                    removeSpinnerMsg(layer.options.layerName)
                }
            })

            base.bringToBack()
                .addTo(mapRef.current)
            // mapRef.current.addLayer(base).bringToBack();


        }, [baseLayer])


    useEffect(() => {
        let crs2180 = new L.Proj.CRS(
            "EPSG:2180",
            "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 units=m +no_defs",
            {}
        );

        if (overLayer.value.service_type === "wmts") {
            overLayer.value.options.crs = crs2180
        }

        let layerUrl =  (overLayer.value.layer_source === "internal  ")?
            instance.geoserverUrl+ "/pai/wms" : overLayer.value.uri

        let layerTypes = {
            "tms": L.tileLayer(layerUrl, overLayer.value.options),
            "wms": L.tileLayer.wms(layerUrl, overLayer.value.options),
            "wmts": L.tileLayer.projwmts(layerUrl, overLayer.value.options),
            "nonTiled": L.nonTiledLayer.wms(layerUrl, overLayer.value.options)
        }

        if (overLayer.visibleOnMap) {
          (overLayer.value.layer_source === "internal")?
              layerTypes[overLayer.value.service_type]
                  .setParams({jwt: JSON.parse(localStorage.pai_tokens).access_token})
                  .setZIndex(100)
              : layerTypes[overLayer.value.service_type]
                  .setZIndex(100)


            // layerTypes[overLayer.value.service_type]
            //     .setParams({jwt: JSON.parse(localStorage.pai_tokens).access_token})
            //     .setZIndex(100)

            // instance.geoserverUrl
            // .on("tileloadstart", (e) => {
            //     setInfo({message: "ładuję kafle " + e.target.options.layerName, type: 'information'})
            //     // console.log("pobieram",e.target.options.layerName)
            //     addSpinnerMsg(e.target.options.layerName)
            // })
            // .on("loading", (e) => {
            //     setInfo({message: "ładuję  " + e.target.options.layerName, type: 'information'})
            //     // console.log("trwa",e.target.options.layerName)
            //     addSpinnerMsg(e.target.options.layerName)
            // })
            // .on("load", (e) => {
            //     setInfo({message: "załadowałem " + e.target.options.layerName, type: 'success'})
            //     removeSpinnerMsg(e.target.options.layerName)
            // })
            // .on("tileerror", (e) => {
            //     setInfo({message: "błąd " + e.target.options.layerName, type: 'error'})
            //     removeSpinnerMsg(e.target.options.layerName)
            // })

            layerTypes[overLayer.value.service_type].addTo(mapRef.current)
        } else {
            mapRef.current.eachLayer((layer) => {
                // console.log(overLayer.value.options.layerName)
                if (layer.options.layerName === overLayer.value.options.layerName) {
                    layer.removeFrom(mapRef.current)
                    removeSpinnerMsg(layer.options.layerName)
                }
            })
        }
    }, [overLayer])


    useEffect(() => {
        let bestAreasObject = L.geoJSON(null, {
            layerName: "bestArea",
            color: 'blue',
            weight: 1,
            fillOpacity: 0
        })

        if (bestAreasVisible) {
            bestAreasObject
                .addData(bestAreas)
                .bringToFront()

            bestAreasObject.addTo(mapRef.current)
        } else {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.layerName === "bestArea") {
                    layer.removeFrom(mapRef.current)
                }
            })
        }

    }, [bestAreasVisible, bestAreas])

    let measureItems = new L.FeatureGroup(null, {measureType: "poly"});


    useEffect(() => {

        mapRef.current.addLayer(measureItems);
        var drawControlMeasure = new L.Control.Draw({
            draw: {
                // circlemarker: false,
                polyline: {
                    fillColor: '#000000',
                    shapeOptions: {
                        // stroke: true,
                        dashArray: '2, 10',
                        color: '#000000',
                        weight: 2,
                        opacity: 0.5,
                        fill: false,
                        clickable: true
                    },
                },
                polygon: {
                    metric: true,
                    showArea: true,
                    shapeOptions: {
                        fill: false,
                        weight: 2,
                        dashArray: '2, 10',
                        color: '#000000',
                        fillColor: '#000000',
                        fillOpacity: 0.5

                    }
                },
                // circle: false, // Turns off this drawing tool
                // rectangle: false,
                marker: {
                    icon: new L.icon({
                        iconUrl: icon,
                        shadowUrl: iconShadow,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    })
                },
            }
        });

        mapRef.current.addLayer(measureItems);

        let polylineDrawHandler;
        let polygonDrawHandler;
        let pointDrawHandler;


        if (measureLine) {
            L.drawLocal.draw.handlers.polyline.tooltip = {
                start: 'Kliknij aby zacząć pomiar odległości',
                cont: 'Kliknij aby kontynuować pomiar odległości',
                end: 'Kliknij w punkt początkowy aby zakończyć pomiar.'
            };

            polylineDrawHandler = new L.Draw.Polyline(mapRef.current, drawControlMeasure.options.draw.polyline);
            polylineDrawHandler.enable();
            mapRef.current.on('draw:created', e => {
                const layer = e.layer;
                measureItems.addLayer(layer, {measureType: "poly"});
                setMeasureLine(false)
                console.log(measureItems)

                if (e.layerType === 'polyline') {
                    console.log(layer.getLatLngs())
                    layer
                        // .bindPopup(L.GeometryUtil.length(layer.getLatLngs()) + " m")
                        // L.GeometryUtil.length()
                        // .openPopup()
                        .showMeasurements()

                }
            });

        }

        if (measurePolygon) {

            L.drawLocal.draw.handlers.polygon.tooltip = {
                start: 'Kliknij aby zacząć pomiar powierzchni obszaru',
                cont: 'Kliknij aby kontynuować pomiar powierzchni',
                end: 'Kliknij w punkt początkowy aby zakończyć pomiar.'
            };


            console.log(drawControlMeasure.options)
            polygonDrawHandler = new L.Draw.Polygon(mapRef.current, drawControlMeasure.options.draw.polygon);
            polygonDrawHandler.enable();
            mapRef.current.on('draw:created', e => {
                const layer = e.layer;
                measureItems.addLayer(layer, {measureType: "poly"});
                if (e.layerType === 'polygon') {
                    layer.showMeasurements()
                }
                setMeasurePolygon(false)
            });
        }

        if (measurePoint) {
            L.drawLocal.draw.handlers.marker.tooltip = {
                start: 'Kliknij aby sprawdzić wysokość terenu',
            };

            pointDrawHandler = new L.Draw.Marker(mapRef.current, drawControlMeasure.options.draw.marker);
            pointDrawHandler.enable();
            mapRef.current.on('draw:created', e => {
                const layer = e.layer;
                measureItems.addLayer(layer, {measureType: "poly"});
                setMeasurePoint(false)
                console.log(measureItems)
                if (e.layerType === 'marker') {

                    // apiExternal.getApisNmt({x: plpoint[1], y: plpoint[0]})
                    //     .then((body) =>{
                    //         data = body
                    //         // console.log(
                    //         //     body
                    //         // )
                    //     })


                    gugikHeights(layer.getLatLng().lng, layer.getLatLng().lat)
                        .then((response) => {
                                console.log(response)
                                layer
                                    .bindPopup(response + " m.n.p.m")
                                    .openPopup()
                            }
                        )
                }
            });
        }


        if (clearMeasures) {
            console.log("clear 2", measureItems)
            measureItems.clearLayers();
            mapRef.current.eachLayer((layer) => {
                if (layer.options.measureType === "poly") {
                    console.log(1)
                    layer.removeFrom(mapRef.current)
                } else {
                    console.log(0)
                }
            })
            //
            setClearMeasures(false)
        }

        return () => {
            drawControlMeasure.remove()


        }


    }, [measureLine, measurePolygon, measurePoint, clearMeasures])


    function addStartPoint(e) {
        new L.marker(e.latlng, {
            name: "userIsochrone",
            icon: L.IconMaterial.icon({
                icon: 'schedule',            // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: '#1273de',  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,                   // Marker outline width
                iconSize: [31, 42]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(mapRef.current)
        })
            .addTo(mapRef.current)

        setIsochroneStart([e.latlng.lat, e.latlng.lng])
        setAddIsoPoint(false)
    }

    useEffect(() => {
        if (addIsoPoint) {
            mapRef.current.on("click", addStartPoint)
        }
        return () => {
            mapRef.current.off("click", addStartPoint)
        }
    }, [addIsoPoint])


    function setRouteOriginEvent(e) {
        new L.marker(e.latlng, {
            name: "userRoute",
            icon: L.IconMaterial.icon({
                icon: 'flag',            // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: '#3bab06',  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,                   // Marker outline width
                iconSize: [21, 32]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(mapRef.current)
        })
            .addTo(mapRef.current)

        setRouteOrigin([e.latlng.lat, e.latlng.lng])
        setAddRouteOrigin(false)
    }


    useEffect(() => {
        if (addRouteOrigin) {
            mapRef.current.on("click", setRouteOriginEvent)
        }
        return () => {
            mapRef.current.off("click", setRouteOriginEvent)
        }
    }, [addRouteOrigin])


    function setRouteDestinationEvent(e) {
        new L.marker(e.latlng, {
            name: "userRoute",
            icon: L.IconMaterial.icon({
                icon: 'flag',            // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: '#f40d0d',  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,                   // Marker outline width
                iconSize: [21, 32]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(mapRef.current)
        })
            .addTo(mapRef.current)

        setRouteDestination([e.latlng.lat, e.latlng.lng])
        setAddRouteDestination(false)
    }


    useEffect(() => {
        if (addRouteDestination) {
            mapRef.current.on("click", setRouteDestinationEvent)
        }
        return () => {
            mapRef.current.off("click", setRouteDestinationEvent)
        }
    }, [addRouteDestination])


    useEffect(() => {
        if (removeRoutes) {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.name === "userRoute") {
                    layer.removeFrom(mapRef.current)
                }
            })
        }
        setRemoveRoutes(false)

    }, [removeRoutes])

    useEffect(() => {
        console.log(generateRoute)
        if (generateRoute) {
            console.log(routeDestination, routeOrigin, routeTransportType)
            if (routeDestination !== null && routeOrigin !== null && routeTransportType !== null) {

                apiExternal.getApisRouting({
                    transportMode: routeTransportType,
                    origin: routeOrigin,
                    destination: routeDestination
                })
                    .then(body => {
                        const route = body.routes[0].sections[0].polyline
                        const duration = body.routes[0].sections[0].spans[0].duration
                        const duration_time = moment.utc(duration * 1000).format('HH:mm:ss');
                        const popup = `${duration_time} </br>        
                                   ${body.routes[0].sections[0].spans[0].length / 1000} km </br> ${(routeTransportType === "car") ? "autem" : "pieszo"}`

                        console.log(popup)


                        new L.polyline(route, {
                            fill: false,
                            weight: 5,
                            color: 'white',
                            // dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                            // fillOpacity: 0.5,
                            // opacity: 0.8,
                            name: "userRoute"
                        }).addTo(mapRef.current)
                            .bringToFront()


                        new L.polyline(route, {
                            fill: false,
                            weight: 3,
                            color: '#3a3838',
                            dashArray: (routeTransportType === "car") ? '10,0' : '10,5',
                            fillOpacity: 0.05,
                            opacity: 0.8,
                            name: "userRoute"
                        })
                            .bindPopup(popup)
                            .on({
                                mouseover: (e) => {
                                    // e.target.openTooltip()
                                    // var popup = e.target.getPopup();
                                    // popup.setLatLng(e.latlng).openOn(mapRef.current);
                                    e.target.setStyle({weight: 4});
                                },
                                mouseout: (e) => {
                                    e.target.setStyle({weight: 3})
                                    //     e.target.closePopup();
                                },
                                // mousemove: (e) => {
                                //     popup.setLatLng(e.latlng).openOn(mapRef.current);
                                // }
                                click: (e) => {
                                    // var popup = e.target.getPopup();
                                    // popup.setLatLng(e.latlng).openOn(mapRef.current);
                                    // e.target.setStyle({weight: 5});
                                },
                                dblclick: (e) => {
                                    // e.target.setStyle({weight: 2})
                                    // e.target.closePopup();
                                    // e.target.removeFrom(mapRef.current)
                                },
                            })
                            .addTo(mapRef.current)
                            .bringToFront()


                    })
            } else {
                console.log("route error")
            }

        }
        setGenerateRoute(false)

    }, [generateRoute, routeOrigin, routeDestination, routeTransportType])


    useEffect(() => {
        if (removeIso) {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.name === "userIsochrone") {
                    layer.removeFrom(mapRef.current)
                }
            })
        }
        setRemoveIso(false)

    }, [removeIso])


    useEffect(() => {
        console.log(generateIso)
        if (generateIso) {
            console.log(isochroneStart, isoTime, isoTransportType)
            if (isoTime >= 1 && isoTransportType) {
                apiExternal.getApisIsochrone({
                    localization: isochroneStart,
                    time: isoTime,
                    transportType: isoTransportType
                })
                    .then((isochroneGeojson) => {
                        const encodedString = isochroneGeojson.isolines[0].polygons[0].outer
                        const decoded = decode(encodedString);
                        let array = decoded.polyline
                        if (array[0] !== array[array.length - 1]) {
                            array.push(array[0])
                        }

                        let popup = L.popup()
                            .setContent(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`)


                        new L.polyline(array, {
                            fill: false,
                            weight: 4,
                            color: 'white',
                            // dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                            // fillOpacity: 0.5,
                            opacity: 0.8,
                            name: "userIsochrone"
                        }).addTo(mapRef.current)


                        new L.polyline(array, {
                            fill: false,
                            weight: 2,
                            color: '#3a3838',
                            dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                            fillOpacity: 0.05,
                            opacity: 0.8,
                            name: "userIsochrone"
                        })
                            // .setText(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`,
                            //     {
                            //         // repeat: true,
                            //         offset: 8,
                            //         orientation: 0,
                            //         attributes: {
                            //             'font-weight': 'bold',
                            //             'font-size': '24',
                            //             'text-stroke': '2px red',
                            //         }
                            //     })
                            .bindPopup(popup)
                            .bindTooltip(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`, {sticky: true})
                            .on({
                                // mouseover: (e) => {
                                //     // var popup = e.target.getPopup();
                                //     // popup.setLatLng(e.latlng).openOn(mapRef.current);
                                //     e.target.setStyle({weight: 5});
                                // },
                                // mouseout: (e) => {
                                //     e.target.setStyle({weight: 1})
                                // //     e.target.closePopup();
                                // },
                                // mousemove: (e) => {
                                //     popup.setLatLng(e.latlng).openOn(mapRef.current);
                                // }
                                click: (e) => {
                                    var popup = e.target.getPopup();
                                    popup.setLatLng(e.latlng).openOn(mapRef.current);
                                    e.target.setStyle({weight: 5});
                                },
                                dblclick: (e) => {
                                    e.target.setStyle({weight: 2})
                                    e.target.closePopup();
                                    // e.target.removeFrom(mapRef.current)
                                },
                            })
                            .addTo(mapRef.current)
                            .bringToFront()
                    })
                    .catch(reason => {
                        console.log(reason)
                    })
            } else {
                console.log('isochrone error')
            }

        }
        setGenerateIso(false)

    }, [generateIso, isochroneStart, isoTransportType, isoTime])


    function userPoint(e) {
        L.marker(e.latlng, {
            name: "userPoint",
            draggable: true,
            tooltip: userPointLabel,
            icon: L.IconMaterial.icon({
                icon: 'fiber_manual_record',            // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: userPointColor,  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,
                iconSize: [21, 31]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(mapRef.current)
        }).bindTooltip(userPointLabel)
            .addTo(mapRef.current)

        setAddUserPoint(false)

    }


    useEffect(() => {
        if (addUserPoint) {
            mapRef.current.on("click", userPoint)
        }
        return () => {
            mapRef.current.off("click", userPoint)
        }
    }, [addUserPoint])

    useEffect(() => {
        if (hideUserPoint) {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.name === "userPoint") {
                    layer.setOpacity(0)
                        .unbindTooltip()
                }
            })
        } else {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.name === "userPoint") {
                    const tooltip = layer.options.tooltip
                    console.log(tooltip)

                    layer
                        .bindTooltip(tooltip)
                        .setOpacity(1)

                }
            })
        }
    }, [hideUserPoint])


    return (

        <>
            <div
                style={{
                    width: "100%",
                    height: "calc(100vh - 64px)",
                    position: "relative"
                }}
            >

                <div id={props.id}
                     style={{
                         position: "absolute",
                         // top: 0,
                         // left: 0,
                         width: "100%",
                         height: "calc(100vh - 64px)",
                         // height: "calc(100vh - 400px)",
                     }}>


                </div>

                <div id="panorama"
                     style={{
                         zIndex: 9999999,
                         position: "absolute",
                         bottom: 0,
                         left: 0,
                         width: "100%",
                         height: "50%",
                         // position: "relative !important"
                     }}>
                </div>
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "300px",
                    height: "70%",
                    // backgroundColor: "white",
                    zIndex: 402,
                    pointerEvents: "none",
                    // position: "relative !important"
                }}>
                    <SpatialTools/>
                </div>

                <MapLayerSwitcher/>
                <FeatureInfoSidebar/>
                <MapLegend/>
            </div>
        </>

    );
}
