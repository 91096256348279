import React from "react";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            <p>Komponent spowodował błąd</p>
            <pre>{error.message}</pre>
            {console.log(error)}
            <Box textAlign='center'>
                <Button
                    // variant='contained'
                    onClick={() => window.location.reload()}>Przeładuj stronę</Button>
            </Box>
            {/*<button onClick={resetErrorBoundary}>Try again</button>*/}
        </div>
    )
}
