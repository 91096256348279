import MUIDataTable from "mui-datatables";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {apiAnalysis, apiLocalAnalysis} from "../../service";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {PictureAsPdf, TextFields} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import {ErrorContext} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import Tooltip from "@material-ui/core/Tooltip";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


function MyLocalAnalyses() {

    const [data, setData] = useState([])
    const [openRemove, setOpenRemove] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [newName, setNewName] = useState('');
    const [analysisId, setAnalysisId] = useState(null)
    let {setInfo} = useContext(ErrorContext);

    const handleRemoveClose = () => {
        setOpenRemove(false);
    };

    const handleRemove = () => {
        remove(analysisId)
        setOpenRemove(false)
        getData()
        setAnalysisId(null)
    };

    const remove = (id) => {
        apiLocalAnalysis.deleteLocalanalysisByAnalysisId({analysis_id: id})
            .catch(reason => {
                console.log(reason)
            })
        getData()
        setInfo({message: `Usunięto analizę ${analysisId}`, type: 'success'})
    }

    const handleEditClose = () => {
        setOpenEdit(false);
    };

    const handleNewName = (e) => {
        setNewName(e.target.value)
    };

    const generateReport = (id) => {
        console.log(id)
        apiLocalAnalysis.postLocalanalysisByAnalysisIdReport({analysis_id: id})
            .then(r => {
                console.log(r)
            }).finally(() => {
                getData()
                setInfo({message: `Wygenerowano raport ${analysisId}`, type: 'success'})
            }
        )
    }

    const getReport = (reportId) => {
        console.log(reportId)
        apiLocalAnalysis.getLocalanalysisReportByReportId({report_id:reportId})
            .then(response => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', `raport_${reportId}`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            })

    };

    const handleEdit = () => {
        console.log(newName, analysisId)
        setOpenEdit(false);

        const data = {
            "name": newName
        }

        apiLocalAnalysis.patchLocalanalysisByAnalysisId({body: data, analysis_id: analysisId})
            .then(body => {
                console.log({message: body.message, type: 'success'})

            })
            .finally(() => {
                getData()
                setInfo({message: `Zaktualizowano nazwę analizy ${analysisId}`, type: 'success'})
            })
            .catch(error => {
                console.log({message: error.message, type: 'error'})
            })

        setNewName('')
        setAnalysisId(null)
    };


    function getData() {
        apiLocalAnalysis.getLocalanalysis()
            .then(value => {
                setData(value)
                // console.log(value)
            }).catch(reason => {
            console.log(reason)
        })

        // apiAnalysis.getAnalysisList({elements: 50})
        //     .then(value => {
        //         setData(value)
        //         // setInfo({message: 'Pobrano analizy użytkownika', type: 'success', duration: 500})
        //     })
        //     .catch(reason => {
        //         console.log(reason)
        //     })
    }

    useEffect(() => {
        getData()
    }, [])


    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: true,
                sort: true,
                sortDirection: 'desc',
            }
        },
        // {
        //     name: "customCategories",
        //     label: "Kategorie",
        //     options: {
        //         filter: true,
        //
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             // console.log(data[dataIndex])
        //             let value = data[dataIndex].customCategories;
        //             return value.map((val, key) => {
        //                 return <Chip label={val} key={key}/>;
        //             });
        //         },
        //     }
        // },

        {
            name: 'reports',
            label: "raporty",
            options: {
                filter: false,
                filterType: 'multiselect',
                customBodyRenderLite: (dataIndex) => {
                    console.log(data[dataIndex])
                    let value = data[dataIndex].reports;
                    return (value.length > 0) ? value.map((val, key) => {
                        return <Tooltip title={`Pobierz raport z dnia: ${val.finished_at}  [${val.file_size}]`}>
                            <Chip
                                onClick={()=>getReport(val.id)}
                                label={`${val.id}`} key={key}/>
                            {/*<PictureAsPdfIcon/>*/}
                        </Tooltip>
                    }) : <Chip label={"Brak raportu"}/>
                },
            }
        },
        // {
        //     name: "update_time",
        //     label: "Data",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     }
        // },
        {
            name: "updated_at",
            label: "Data",
            options: {
                filter: true,
                sort: true,

            }
        },

        {
            name: "name",
            label: "Nazwa",
            options: {
                type: 'date',
                filter: true,
                sort: true,
            }
        },
        // {
        //     name: "grid",
        //     label: "grid",
        //     options: {
        //         filter: true,
        //         sort: false,
        //     }
        // },

        {
            name: 'id',
            label: " ",
            options: {
                sort: false,
                filter: false,
                setCellHeaderProps: () => ({align: 'center'}),
                setCellProps: () => ({align: 'right'}),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Link
                                aria-label="Przejdź do analizy"
                                to={`/mylocalanalyses/${value}`}>
                                <Tooltip title={`Przejdź do analizy  ${value}`}>
                                    <IconButton
                                        aria-label="Przejdź do analizy"
                                    ><SearchIcon/></IconButton>
                                </Tooltip>
                            </Link>
                            <Tooltip title={`Generuj nowy raport PDF`}>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setAnalysisId(value)
                                        generateReport(value)
                                    }}
                                    // onClick={generateReport}
                                >
                                    <PictureAsPdfIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={`Zmień nazwę analizy  ${value}`}>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setOpenEdit(true)
                                        setAnalysisId(value)
                                    }}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={`Usuń analizę  ${value}`}>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setOpenRemove(true)
                                        setAnalysisId(value)
                                    }}>
                                    <DeleteForeverIcon/>
                                </IconButton>
                            </Tooltip>
                        </>
                    );

                    // customBodyRenderLite: (dataIndex) => {
                    //     console.log(data[dataIndex])
                    //     let value = data[dataIndex].id;
                    //     return


                },
            }
        }

    ];


    const options = {
        selectableRows: 'none',
        download: false,
        filter: true,
        // filterArrayFullMatch: true,
        // filterType: 'dropdown',
        responsive: 'vertical',
        // responsive: 'standard',
        enableNestedDataAccess: '.',
        // tableBodyHeight: "calc(100vh - 184px)",
        tableBodyHeight: "calc(100vh - 232px)",
        textLabels: {
            body: {
                noMatch: "...",
                toolTip: "Sortuj",
                columnHeaderTooltip: column => `Sortuj po kolumnie ${column.label}`
            },
            pagination: {
                next: "Następna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronie:",
                displayRows: "of",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz CSV",
                print: "Wydrukuj",
                viewColumns: "Widoczność kolumn",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystkie",
                title: "FILTRY",
                reset: "RESETUJ",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/ukryj kolumny",
            },
            selectedRows: {
                text: "zaznaczono wiersz(e)",
                delete: "Usuń",
                deleteAria: "Usuń zaznaczone wiersze",
            },
        }


    };


    return (
        <>

            <Dialog
                open={openEdit}
                onClose={handleEditClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Podaj nową nazwę analizy "} {analysisId}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nazwa analizy"
                            type="text"
                            fullWidth
                            onChange={handleNewName}
                        />
                        {/*Let Google help apps determine location. This means sending anonymous location data to*/}
                        {/*Google, even when no apps are running.*/}

                        {/*{analysisId}*/}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary"
                            autoFocus>
                        Anuluj
                    </Button>
                    <Button onClick={handleEdit} color="primary"
                            variant="contained">
                        Tak
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openRemove}
                onClose={handleRemoveClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Czy na pewno chcesz usnąć analizę"} {analysisId}</DialogTitle>
                {/*<DialogContent>*/}
                {/*    <DialogContentText id="alert-dialog-description">*/}
                {/*        /!*Let Google help apps determine location. This means sending anonymous location data to*!/*/}
                {/*        /!*Google, even when no apps are running.*!/*/}

                {/*        /!*{analysisId}*!/*/}
                {/*    </DialogContentText>*/}
                {/*</DialogContent>*/}
                <DialogActions>
                    <Button onClick={handleRemoveClose} color="primary"
                            autoFocus>
                        Anuluj
                    </Button>
                    <Button onClick={handleRemove} color="primary"
                            variant="contained">
                        Tak
                    </Button>
                </DialogActions>
            </Dialog>
            <MUIDataTable
                title={"Wygenerowane analizy lokalne"}
                data={data}
                columns={columns}
                options={options}
            />
        </>);

}

export default MyLocalAnalyses
