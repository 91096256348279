export const polandBox = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            18.34716796875,
                            54.85131525968606
                        ],
                        [
                            14.150390625,
                            53.904338156274704
                        ],
                        [
                            14.809570312499998,
                            50.90303283111257
                        ],
                        [
                            20.0390625,
                            49.23912083246698
                        ],
                        [
                            22.65380859375,
                            49.081062364320736
                        ],
                        [
                            23.97216796875,
                            50.51342652633956
                        ],
                        [
                            23.70849609375,
                            52.14697334064471
                        ],
                        [
                            23.90625,
                            53.001562274591464
                        ],
                        [
                            23.48876953125,
                            54.059387886623576
                        ],
                        [
                            22.8515625,
                            54.36775852406841
                        ],
                        [
                            18.34716796875,
                            54.85131525968606
                        ]
                    ]
                ]
            }
        }
    ]
}
