import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import {AnalysisContext} from "../../../context/AnalysisContext";
import {apiAnalysis} from "../../../service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import AnalysisAOI from "./steps/AnalysisAOI";
import AnalysisCustomization from "./steps/AnalysisCustomization";
import AnalysisSumUp from "./steps/AnalysisSumUp";
import Button from "@material-ui/core/Button";
import {ErrorContext} from "../../../context/ErrorContext";
import HoverRating from "../SimpleRating";
import CircularProgressWithLabel from "./steps/sumup/WaiResult";
import theme from "../../../theme";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";
import Rating from "@material-ui/lab/Rating";
import AddressAutocomplete from "../../../utils/AddressAutocomplete";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";



const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(2),
    }
}));

export default function AnalysisStepper() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();


    const [error, setError] = useState(false);
    const [calculatingAnalysis, setCalculatingAnalysis] = React.useState(false);
    const [stepperVisible, setStepperVisible] = React.useState(true);

    let {
        setAddressPoint, analysisId, setAnalysisId, setAnalysisGeometry,
        indicatorsList, setIndicatorsList, setAnalysisAoi, resetAnalysisData,
        setAoiActive,
        setStyle,
        setFeatureInfo,
        setPolygonDrawing,
        setRemovePolygon,
        polyAoiValidate, setPolyAoiValidate,
        bestAreasVisible, setBestAreasVisible,
        setBestAreas,
        setAnalysisGeomWKT,
        isAnalysisVisible, setIsAnalysisVisible,
        setPaiOpacity,
        setHexPercentilesInfo
    } = useContext(AnalysisContext)

    let {setInfo} = useContext(ErrorContext)

    const handleToCategories = () => {
        handleNext()
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleLastStep = () => {
        const data = {
            "indicator_list": indicatorsList
        }

        setCalculatingAnalysis(true)
        setStepperVisible(false)
        apiAnalysis.putAnalysisByAnalysisId({analysis_id: analysisId})
            .then(body => {
                    if (body) {
                        setInfo({message: 'Twoja analiza jest gotowa!', type: 'success'})
                        setAnalysisGeometry(body)
                        setStepperVisible(false)
                        setCalculatingAnalysis(false)
                        window.scrollTo(0, 0)
                    }
                },
                body => {
                    setStepperVisible(false)
                    setCalculatingAnalysis(false)
                    setInfo({message: "Wystąpił nieoczekiwany błąd", type: 'error'})
                })
            .catch(reason => {
                setCalculatingAnalysis(false)
                setError(true)
                setInfo({message: reason, type: 'error'})
            })
        handleNext()
        setAoiActive(false)
        setStyle("standard")
        setPolygonDrawing(false)
        setPolyAoiValidate(false)

    };

    const handleReset = () => {
        setError(false)
        resetAnalysisData();
        setActiveStep(0);
        //wyczyść tablicę wskaznikow
        setIndicatorsList([]);
        setAnalysisAoi(null)
        setCalculatingAnalysis(false);
        setStepperVisible(true);
        setAnalysisGeometry()
        setAddressPoint(null);
        setAoiActive(false);
        setPolygonDrawing(false)
        setPolyAoiValidate(false)
        setRemovePolygon(true)
        setBestAreas(null)
        setHexPercentilesInfo(null)
        setFeatureInfo(null)
        setBestAreasVisible(false)
        setAnalysisGeomWKT(null)
        setIsAnalysisVisible(true)
        setPaiOpacity(0.5)
        newAnalysis();
    };

    useEffect(() => {
        newAnalysis()
    }, [])

    function newAnalysis() {
        apiAnalysis.putAnalysis()
            .then(body => {
                setAnalysisId(body.id)
                console.log(body.id)
            }).catch(reason => {
            setInfo({message: reason, type: 'error'})
        })
    }

    function getSteps() {
        return ['Gdzie szukasz?', 'Co jest dla Ciebie ważne '];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <AnalysisAOI handleBack={handleBack}
                                 handleToCategories={handleToCategories}
                                 analysisId={analysisId}/>
                );
            case 1:
                return (
                    <AnalysisCustomization handleBack={handleBack} handleLastStep={handleLastStep}
                                           analysisId={analysisId}/>
                )
            default:
                return 'Unknown step';
        }
    }

    return (
        <>
            <div style={{
                maxHeight: "calc(100vh - 64px)",
                overflowX: 'hidden',
                overflowY: 'auto',
            }}>
            {
                stepperVisible &&
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel><Typography variant={"h3"}>Gdzie szukasz?</Typography></StepLabel>
                        <StepContent>
                            <AnalysisAOI handleBack={handleBack}
                                         handleToCategories={handleToCategories}
                                         analysisId={analysisId}/>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel><Typography variant={"h3"}>Co jest dla Ciebie ważne?</Typography></StepLabel>
                        <StepContent>
                            <AnalysisCustomization handleBack={handleBack} handleLastStep={handleLastStep}
                                                   analysisId={analysisId}/>
                        </StepContent>
                    </Step>
                </Stepper>
            }
            {
                activeStep === steps.length &&
                <Grid container elevation={0} className={classes.resetContainer}>
                    {
                        calculatingAnalysis &&
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item><CircularProgress/></Grid>
                            <Grid item><Typography>Trwa analiza... </Typography></Grid>
                        </Grid>
                    }
                    {
                        (!calculatingAnalysis && !error) &&

                        <Grid container direction="column" justify="center" alignItems="center">
                            <AnalysisSumUp analysisId={analysisId} handleReset={handleReset}/>
                        </Grid>

                    }
                    {
                        error &&
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item><Typography color={'error'}>WYSTĄPIŁ NIEOCZEKIWANY BŁĄD </Typography></Grid>
                            <Grid item><Button onClick={handleReset} className={classes.button}>Nowa
                                analiza</Button></Grid>
                        </Grid>
                    }
                </Grid>

            }
            </div>
        </>
    );
}
