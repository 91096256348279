import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AnalysisProvider} from "../../context/AnalysisContext";
import AnalysisSumUp from "../analysis/new/steps/AnalysisSumUp";
// import MiniMap from "../MiniMap";
import LocalAttractiveness from "../analysis/new/steps/sumup/LocalAttractiveness";
// import ResultMap from "../ResultMap";
// import {ResultProvider} from "../../context/ResultContext";
import MyAnalysisMapById from "./MyAnalysisMapById";
import AreaAttractiveness from "../analysis/new/steps/sumup/AreaAttractiveness";
import AnalysisParams from "../analysis/new/steps/sumup/AnalysisParams";
import FeatureInfoAccordion from "../maptools/featureInfo/FeatureInfoAccordion";
import HexCharts from "../analysis/new/steps/sumup/HexCharts";
import Box from "@material-ui/core/Box";
import Map from "../analysis/Map";
import UpDownButton from "../../utils/UpDownButton";
import {ToolProvider} from "../../context/ToolContext";
import Results from "../analysis/new/steps/sumup/Results";
import AnalysisStepper from "../analysis/new/AnalysisStepper";
import {ErrorProvider} from "../../context/ErrorContext";
import theme from "../../theme";

// import {AnalysisProvider} from "../context/AnalysisContext";
// import InfoSnackbar from "./snackbar/InfoSnackbar";
// import {ErrorProvider} from "../context/ErrorContext";


const useStyles = makeStyles((theme) => ({
    gridCustomeight: {
        maxHeight: 'calc(100%-64px)',
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // padding: theme.spacing(1),

    },
    mapContainer: {
        width: "auto",
        height: "90vh"
    }
}));

export default function MyAnalysisById({match}) {
    const classes = useStyles();

    return (
        <>
            <ToolProvider>
                <AnalysisProvider>

                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}
                            spacing={1}
                              className={classes.gridCustomeight}
                        >
                            <div style={{
                                maxHeight: "calc(100vh - 64px)",
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}>
                                <Grid container
                                      style={{padding: "8px"}}
                                >
                                    <Results analysisId={match.params.id}/>
                                </Grid>
                            </div>

                        </Grid>

                        <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
                            <Map id={"map"} data={match.params.id}/>
                        </Grid>
                    </Grid>
                    <UpDownButton/>
                </AnalysisProvider>
            </ToolProvider>
        </>
    );
}
