import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {Button, Container, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(11),
    },
    paper: {
        padding: theme.spacing(5),
    },
}));


function UserPage() {
    const classes = useStyles();
    return (
        <>
            <div className={'manualPage'}>
                <Container className={classes.container} maxWidth="xs">
                    <Paper className={classes.paper}>
                        <h1>Błędny adres</h1>
                        <Link to={"/"}>Powrót na stronę główną</Link>
                    </Paper>
                </Container>
            </div>
        </>
    );
}

export default UserPage