import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import LayersIcon from '@material-ui/icons/Layers';
import IconButton from "@material-ui/core/IconButton";
import BaseLayerRadioGroup from "./BaseLayerRadioGroup";
import OverLayerGroup from "./OverLayerGroup";

import Box from "@material-ui/core/Box";
import {ToolContext} from "../../../context/ToolContext";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({

    container: {
        height: 180,
        width: 400,
        display: "block",
        position: "absolute",
        zIndex: "990",
        right: "0px",
        top: "160px",
    },
    switcher: {
        position: "absolute",
        zIndex: "999",
        right: "0px",
        // top: "50px",
    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "absolute",
        right: "2px",
        top: "50px",
        zIndex: "990",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function MapLayerSwitcher() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    let {setOverLayer, mapZoomLvl} = useContext(ToolContext)
    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <>
            <div className={classes.switcher}>
                <Tooltip title="Panel warstw">
                <IconButton onClick={handleChange} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <LayersIcon/>
                </IconButton>
                </Tooltip>
            </div>
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                {/*<div elevation={4} className={classes.paper}>*/}
                <Box
                    width={"210px"}
                    position="absolute"
                    top="1%"
                    right="1%"
                    zIndex="990"
                    wi
                >
                    <Paper>
                        {/*<AddressAutocomplete addressHandler={() => {}}/>*/}
                        {/*<OverLayerGroup/>*/}
                        <OverLayerGroup/>
                        <BaseLayerRadioGroup/>
                        {/*<Spinner/>*/}
                    </Paper>
                </Box>
                {/*</div>*/}
            </Grow>
        </>
    );
}

export default MapLayerSwitcher
