import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {AuthContext} from "../../context/AuthContext";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import PersonIcon from '@material-ui/icons/Person';
import InputIcon from '@material-ui/icons/Input';
import {useHistory} from "react-router-dom";
import AuthDialog from "./AuthDialog";
import {apiAuth} from "../../service";
import {ErrorContext} from "../../context/ErrorContext";
import Toolbar from "@material-ui/core/Toolbar";
import {ErrorProvider} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import {Container} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function MenuAppBar() {
    const classes = useStyles();
    let history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let {isLogged, setIsLogged, setAuth, setUserLogin, setUserRole} = useContext(AuthContext)
    let {setInfo} = useContext(ErrorContext);

    const handleMenu = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logOut() {
        apiAuth.postUserLogout({})
            .then(resp => {
                    setInfo({message: resp.message, type: 'success'})
                    setIsLogged(false)
                    setAuth({})
                    setUserLogin()
                    setUserRole('')
                    sessionStorage.clear();
                    localStorage.clear()
                    handleClose()
                }
            )
    }

    return (

        <>
            <InfoSnackbar/>
            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    edge={"end"}
                >
                    {!isLogged && <PersonIcon/>}
                    {isLogged && <HowToRegIcon/>}
                </IconButton>
                {isLogged &&
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={logOut}>
                        <ExitToAppIcon/>Wyloguj
                    </MenuItem>

                    <MenuItem onClick={handleClose} component={Link} to="/priv">
                        <AccountCircle/>Konto
                    </MenuItem>
                </Menu>
                }
                {!isLogged &&

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/auth">
                        <InputIcon/>Zaloguj
                    </MenuItem>

                </Menu>

                }
            </div>
        </>


    );
}
