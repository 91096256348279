import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import {element} from "prop-types";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {getColor} from "../../../../../service/featureInfo";

const useStyles = makeStyles({
    result: {
        fontSize: 12,
        fontStyle: "italic"
    },
});

function IndicatorChartNormalised(props) {

    // const StyledLinearProgress = withStyles({
    //     height: '40px',
    //     border: '1px',
    //
    // })(LinearProgress);

    let MIN = props.min
    let MAX = props.max
    function normalise(value) {
        value = (value === "f" && !props.indicatorIsPositive) ? 0 : value
        value = (value === "t" && !props.indicatorIsPositive) ? 1 : value

        value = (value === "f" && props.indicatorIsPositive) ? 1 : value
        value = (value === "t" && props.indicatorIsPositive) ? 0 : value
        return (props.indicatorIsPositive) ?  (value - MIN) * 100 / (MAX - MIN) : (MAX - value) * 100 / (MAX - MIN)
    }




    const useStyles = makeStyles({
        root: {
            height: 10,
            borderRadius: 15,

        },
        colorPrimary: {
            backgroundColor: '#E9E9E9'
            // backgroundColor: LightenDarkenColor(getColor(props.value),50)
        },
        bar: {
            // borderRadius: 5,
            backgroundColor: "primary"
        }
    });



    const classes = useStyles();

    return (
        <React.Fragment>
            {/*<Grid item*/}
            {/*    // rowSpacing={2}*/}
            {/*    // spacing={0}*/}
            {/*      xs={6} sm={6} md={6} lg={6} xl={6}*/}
            {/*>*/}
            {/*    <div style={{*/}
            {/*        // paddingTop: 5,*/}
            {/*        // paddingBottom: 5*/}
            {/*        paddingBottom: 10*/}
            {/*    }}>*/}
            {/*        {props.name}*/}
            {/*        /!*<FormControlLabel*!/*/}
            {/*        /!*    classes={(value === element.indicator.id) ? {label: classes.labelActive} : {label: classes.labelInactive}}*!/*/}
            {/*        /!*    value={element.indicator.name}*!/*/}
            {/*        /!*    control={<Radio size={"small"} color="primary"*!/*/}
            {/*        /!*        // disabled={(element.style === null)}*!/*/}
            {/*        /!*        // checked={value === element.indicator.id}*!/*/}
            {/*        /!*                    onClick={() => handleIndicator(element.indicator.id, element.style, element.indicator.layers)}/>}*!/*/}
            {/*        /!*    // label={element.indicator.layers[0].name}*!/*/}
            {/*        /!*    label={element.indicator.name}*!/*/}
            {/*        /!*  />*!/*/}
            {/*    </div>*/}

            {/*</Grid>*/}

            {/*<Grid item*/}
            {/*      // spacing={0}*/}
            {/*      // zeroMinWidth*/}
            {/*      xs={4} sm={4} md={4} lg={4} xl={4}>*/}

            {/*<div style={{*/}
            {/*    paddingTop: 10,*/}
            {/*    // verticalAlign: "middle"*/}
            {/*    textAlign: "left"*/}
            {/*}}>*/}
            {/*    */}
            <LinearProgress
                classes={{
                    root: classes.root,
                    colorPrimary: classes.colorPrimary,
                    bar: classes.bar
                }}
                // style={{height: "7px"}}
                variant="determinate"
                // value={props.value * 100}
                value={normalise(props.value)}
            />

            {/*{normalise(props.value)}*/}
            {/*{Math.round(props.value *100)}%*/}

            {/*<center>{Math.round(props.value *100)}%</center>*/}
            {/*<center>{Math.round(props.value *100)}%</center>*/}
            {/*</div>*/}

            {/*    */}

            {/*</Grid>*/}
            {/*<Grid item*/}
            {/*      // spacing={0}*/}

            {/*      xs={2} sm={2} md={2} lg={2} xl={2}>*/}
            {/*    /!*<center>{normalise(props.value)}%</center>*!/*/}
            {/*    /!*<center>{Math.round(normalise(props.value))}%</center>*!/*/}
            {/*    <center>{Math.round(props.value *100)}%</center>*/}
            {/*/!*    <center> {element.min_value} <b>{featureInfo[element.indicator.id]}</b> {element.max_value} {element.indicator.indicatorIsPositive}</center>*!/*/}
            {/*</Grid>*/}




            {/*<center>*/}

            {/*{MIN} {props.value} {MAX}*/}
            {/*<center> {props.value}*/}
            {/*</center>*/}

            {/*    <Typography align="center"*/}
            {/*                className={classes.result}>{props.value}</Typography>*/}
            {/*</center>*/}
            {/*<center>*/}
            {/*<span>{props.value}  {typeof  props.value}</span>*/}

            {/*<span>{  JSON.stringify(props.indicatorIsPositive)}</span>*/}
            {/*<span> {MIN} {MAX} </span>*/}

            {/*<span>{props.indicatorIsPositive} </span>*/}
            {/*<span>{JSON.stringify(props.min_value)} {JSON.stringify(props.max_value)} </span>*/}
            {/*</center>*/}
        </React.Fragment>
    )
}

export default IndicatorChartNormalised
