import React, {createContext, useReducer, useState} from 'react';

export const PoiAnalysisContext = createContext();

export const PoiAnalysisProvider = props => {
    const [aoiPoint, setAoiPoint] = useState(null);
    const [result, setResult] = useState(null);
    const [aoiIsochrone, setAoiIsochrone] = useState(null);
    const [aoiDrawingActive, setAoiDrawingActive] = useState(false);
    const [aoiWkt, setAoiWkt] = useState(null);
    const [PoiRouteOrigin, setPoiRouteOrigin] = useState([])
    const [subCategoriesVisibilty, setSubCategoriesVisibility] = useState(null)
    const [categoriesVisibility , setCategoriesVisibility] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {});

    const [blockSaving, setBlockSaving] =useState(true)

    const [highlights, setHighlights] = useState(null);


    return (
        <PoiAnalysisContext.Provider
            value={{
                subCategoriesVisibilty, setSubCategoriesVisibility,
                aoiPoint, setAoiPoint,
                result, setResult,
                aoiIsochrone, setAoiIsochrone,
                aoiDrawingActive, setAoiDrawingActive,
                aoiWkt, setAoiWkt,
                categoriesVisibility , setCategoriesVisibility,
                blockSaving, setBlockSaving,
                highlights, setHighlights,
                PoiRouteOrigin, setPoiRouteOrigin
            }}>
            {props.children}
        </PoiAnalysisContext.Provider>
    )
};
