
class ApiKeyInstance {
    /**
     * @type Object
     * @description ApiKey object
     */
    apiKey = {
        value: "",
        headerOrQueryName: "Authorization",
        isQuery: false
    }
    /**
     * @type Object
     * @description fresh ApiKey object
     */
    apiKeyFresh
    /**
     * @type number
     * @description token valid time in s
     */
    validTime
    /**
     * Set Api Key
     * @method
     * @name Api#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    setApiKey = function(value, headerOrQueryName, isQuery = false) {
        value = "Bearer "+value
        this.apiKey = {value, headerOrQueryName, isQuery}
        localStorage.setItem('pai_token', this.apiKey.value)
    };

    /**
     * Set Api Key
     * @method
     * @name Api#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    setApiKeyFresh(value, headerOrQueryName, isQuery = false) {
        value = "Bearer "+value
        this.apiKeyFresh = {value, headerOrQueryName, isQuery}
    };

    /**
     * Set Api Key
     * @method
     * @name Api#setValidTime
     * @param {number} value - apiKey's value
     */
    setApiKeyValidTime(value) {
        this.validTime = value
    };

    /**
     *
     */
    constructor() {
        if (!ApiKeyInstance.instance) {
            ApiKeyInstance.instance = this;
        }
    }
}

const instance = new ApiKeyInstance();
export default instance;
