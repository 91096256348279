import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from "prop-types";
import RadiusAOI from "./aoi/RadiusAOI";
import IsochroneAOI from "./aoi/IsochroneAOI";
import FormControl from "@material-ui/core/FormControl";
import {CircularProgress, InputLabel, MenuItem, Select} from "@material-ui/core";
import {AnalysisContext} from "../../../../context/AnalysisContext";
import Grid from "@material-ui/core/Grid";
import {apiAnalysis, apiAoi} from "../../../../service";
import {ErrorContext} from "../../../../context/ErrorContext";
import AddressAutocomplete from "../../../../utils/AddressAutocomplete";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ElkGeocoder from "../../../../utils/ElkGeocoder";

AnalysisAOI.propTypes = {
    handleToCategories: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    analysisId: PropTypes.number.isRequired
};

function AnalysisAOI(props) {
    const {handleToCategories, handleBack} = props
    const [aoi, setAoi] = React.useState()
    const [validating, setValidating] = React.useState(false)


    let {
        transportType, time, radius, analysisId,
        addressPoint, inputGeometry,
        analysisAoi,
        setAnalysisAoi,
        resetAnalysisData,
        setAoiActive,
        setAddressPoint,
        polygonDrawing, setPolygonDrawing,
        polyAoiValidate, setPolyAoiValidate,
        isAoiToggleGroupDisabled
    } = useContext(AnalysisContext);
    let {setInfo} = useContext(ErrorContext);
    const [addressPointChanges, setAddressPointChanges] = React.useState(false);
    const [selected, setSelected] = React.useState(analysisAoi)

    const [view, setView] = React.useState("list");

    const handleChange = (event, nextView) => {
        setView(nextView);
        setSelected(nextView)
        setAnalysisAoi(nextView)
        resetAnalysisData(null)
    };


    useEffect(() => {
        if (addressPoint) {
            setAddressPointChanges(true)
        }
    }, [addressPoint])

    useEffect(() => {
        setAoi([
            // {
            //     "value": "isochrone",
            //     "properties": [
            //         {
            //             "value": "car",
            //             "title": "Samochód",
            //             "unit": null,
            //             "name": "Typ transportu",
            //             "type": "transport-type"
            //         },
            //         {
            //             "value": "60",
            //             "title": "1 godz",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         },
            //         {
            //             "value": "50",
            //             "title": "50 min",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         },
            //         {
            //             "value": "40",
            //             "title": "40 min",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         },
            //         {
            //             "value": "20",
            //             "title": "20 min",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         },
            //         {
            //             "value": "10",
            //             "title": "10 min",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         },
            //         {
            //             "value": "pedestrian",
            //             "title": "Pieszo",
            //             "unit": null,
            //             "name": "Typ transportu",
            //             "type": "transport-type"
            //         },
            //         {
            //             "value": "30",
            //             "title": "30 min",
            //             "unit": "min",
            //             "name": "Czas",
            //             "type": "isochrone-time"
            //         }
            //     ],
            //     "name": "Czas dojazdu",
            //     "label": "Czas dojazdu"
            // },
            // {
            //     "value": "radius",
            //     "properties": [
            //         {
            //             "value": "3",
            //             "title": "3 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         },
            //         {
            //             "value": "5",
            //             "title": "5 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         },
            //         {
            //             "value": "10",
            //             "title": "10 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         },
            //         {
            //             "value": "1",
            //             "title": "1 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         },
            //         {
            //             "value": "2",
            //             "title": "2 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         },
            //         {
            //             "value": "4",
            //             "title": "4 km",
            //             "unit": "km",
            //             "name": "Wartość",
            //             "type": "int"
            //         }
            //     ],
            //     "name": "Promień",
            //     "label": "Punkt na mapie"
            // },
            {
                "value": "radius",
                "properties": [
                    {
                        "value": "3",
                        "title": "3 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "5",
                        "title": "5 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "10",
                        "title": "10 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "1",
                        "title": "1 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "2",
                        "title": "2 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "4",
                        "title": "4 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    }
                ],
                "name": "Adres",
                "label": "Adres"
            },
            {
                "value": "radius",
                "properties": [
                    {
                        "value": "3",
                        "title": "3 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "5",
                        "title": "5 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "10",
                        "title": "10 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "1",
                        "title": "1 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "2",
                        "title": "2 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    },
                    {
                        "value": "4",
                        "title": "4 km",
                        "unit": "km",
                        "name": "Wartość",
                        "type": "int"
                    }
                ],
                "defaultValue": "2",
                "name": "Rysunek",
                "label": "Rysunek"
            },

        ])

        // apiAoi.getAoiList()
        //     .then(body => {
        //         setAoi(body)
        //         // setAoi([
        //         //     // {
        //         //     //     "value": "isochrone",
        //         //     //     "properties": [
        //         //     //         {
        //         //     //             "value": "car",
        //         //     //             "title": "Samochód",
        //         //     //             "unit": null,
        //         //     //             "name": "Typ transportu",
        //         //     //             "type": "transport-type"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "60",
        //         //     //             "title": "1 godz",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "50",
        //         //     //             "title": "50 min",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "40",
        //         //     //             "title": "40 min",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "20",
        //         //     //             "title": "20 min",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "10",
        //         //     //             "title": "10 min",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "pedestrian",
        //         //     //             "title": "Pieszo",
        //         //     //             "unit": null,
        //         //     //             "name": "Typ transportu",
        //         //     //             "type": "transport-type"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "30",
        //         //     //             "title": "30 min",
        //         //     //             "unit": "min",
        //         //     //             "name": "Czas",
        //         //     //             "type": "isochrone-time"
        //         //     //         }
        //         //     //     ],
        //         //     //     "name": "Czas dojazdu",
        //         //     //     "label": "Czas dojazdu"
        //         //     // },
        //         //     // {
        //         //     //     "value": "radius",
        //         //     //     "properties": [
        //         //     //         {
        //         //     //             "value": "3",
        //         //     //             "title": "3 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "5",
        //         //     //             "title": "5 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "10",
        //         //     //             "title": "10 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "1",
        //         //     //             "title": "1 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "2",
        //         //     //             "title": "2 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         },
        //         //     //         {
        //         //     //             "value": "4",
        //         //     //             "title": "4 km",
        //         //     //             "unit": "km",
        //         //     //             "name": "Wartość",
        //         //     //             "type": "int"
        //         //     //         }
        //         //     //     ],
        //         //     //     "name": "Promień",
        //         //     //     "label": "Punkt na mapie"
        //         //     // },
        //         //     {
        //         //         "value": "radius",
        //         //         "properties": [
        //         //             {
        //         //                 "value": "3",
        //         //                 "title": "3 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "5",
        //         //                 "title": "5 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "10",
        //         //                 "title": "10 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "1",
        //         //                 "title": "1 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "2",
        //         //                 "title": "2 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "4",
        //         //                 "title": "4 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             }
        //         //         ],
        //         //         "defaultValue": "2",
        //         //         "name": "Rysunek",
        //         //         "label": "Rysunek"
        //         //     },
        //         //     {
        //         //         "value": "radius",
        //         //         "properties": [
        //         //             {
        //         //                 "value": "3",
        //         //                 "title": "3 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "5",
        //         //                 "title": "5 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "10",
        //         //                 "title": "10 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "1",
        //         //                 "title": "1 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "2",
        //         //                 "title": "2 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             },
        //         //             {
        //         //                 "value": "4",
        //         //                 "title": "4 km",
        //         //                 "unit": "km",
        //         //                 "name": "Wartość",
        //         //                 "type": "int"
        //         //             }
        //         //         ],
        //         //         "name": "Adres",
        //         //         "label" : "Adres"
        //         //     }
        //         // ])
        //         console.log(body, "setAOI")
        //     })
        //     .catch(reason => {
        //         console.log(reason)
        //     })
    }, [])

    function handleAioChange(e) {
        console.log(e.target.value, e.target.name, "handleAIOCHANGE")
        setSelected(e.target.value)
        setAnalysisAoi(e.target.value)
        resetAnalysisData(null)
    }

    const validateAOI = () => {
        console.log(addressPoint)
        console.log(radius)
        console.log(inputGeometry!==null  &&  polyAoiValidate)
        if (addressPoint && radius || (inputGeometry!==null  &&  polyAoiValidate)) {
            let aoi_prop = []
            const data = {
                "aoi": {
                    name: analysisAoi
                },
                "aoi_property": aoi_prop,
                "geom": inputGeometry,
                "grid": 250,
            }

            if (analysisAoi === "Promień") {
                aoi_prop.push({value: radius.toString()})
            } else if (analysisAoi === "Adres") {
                // console.log(data.aoi.name)
                aoi_prop.push({value: radius.toString()})
            } else if (analysisAoi === "Rysunek") {
                // data.aoi.name = "Promień"
                // console.log(data.aoi.name)
                const fakeRadius = 2
                aoi_prop.push({value: fakeRadius.toString()})
            } else {
                aoi_prop.push({value: time.toString()})
                aoi_prop.push({value: transportType.toString()})
            }

            apiAnalysis.postAnalysisByAnalysisId({analysis_id: analysisId, body: data})
                .then(body => {
                        if (body) {
                            setValidating(false)
                            setInfo({message: body.message, type: 'success'})
                            handleToCategories()
                        }
                    },
                    body => {
                        setValidating(false)
                        setInfo({message: body.message, type: 'error'})
                    }
                )
                .catch(reason => {
                    console.log(reason.toString())
                    setValidating(false)
                    setInfo({message: reason.toString(), type: 'error'})
                })
        } else {
            setValidating(false)
            setInfo({message: 'Najpierw poprawnie określ wszystkie parametry obszaru', type: 'warning'})
        }
    }

    function addressHandler(e) {
        setAddressPoint(e.reverse())
    }

    function getContent() {
        const items = aoi.filter(item => {
            return item.name === selected
        })
        switch (analysisAoi) {
            case 'Adres':
                setAoiActive(true);
                setPolygonDrawing(false)
                return <>
                    <Grid item>
                        <FormControl>
                            <AddressAutocomplete addressHandler={addressHandler}/>
                            {/*<ElkGeocoder addressHandler={addressHandler}/>*/}
                            <RadiusAOI timeRange={items[0].properties}/>
                        </FormControl>
                    </Grid>
                </>
            case 'Promień':
                return <RadiusAOI timeRange={items[0].properties}/>
            case 'Rysunek':
                setAoiActive(false);
                setPolygonDrawing(true)
                return <>
                    <Box m={1}>
                        {/*<Typography>*/}
                        {/*    Użyj narzędzi rysowania aby zakreślić obszar badawczy*/}
                        {/*</Typography>*/}
                    </Box>
                </>
            case 'Czas dojazdu':
                const types = items[0].properties.filter(item => {
                    return item.type === 'transport-type'
                })
                const timeRanges = items[0].properties.filter(item => {
                    return item.type === 'isochrone-time'
                })
                return <IsochroneAOI typeRange={types} timeRange={timeRanges}/>
            case null:
                setAoiActive(false);
                setPolygonDrawing(false)
                return <>
                    <Box m={1}>
                        {/*<Typography>Wybierz wariant wyboru obszaru</Typography>*/}
                    </Box>
                </>

            default:
                return <> </>
        }
    }

    return (
        <>
            {
                // addressPointChanges &&
                <>
                    <div>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            // justify="center"
                            // alignItems="center"
                            spacing={2}
                        >
                            <Grid item>

                                <ToggleButtonGroup
                                    // orientation="vertical"
                                    style={{width: "100%"}}
                                    // justifyContent="center"
                                    value={view}
                                    exclusive
                                    onChange={handleChange}
                                >

                                    {aoi && aoi.map((item, index) => {
                                        return (
                                            <ToggleButton
                                                disabled={isAoiToggleGroupDisabled}
                                                value={item.name}
                                                aria-label={item.name}>
                                                {item.label}

                                            </ToggleButton>
                                        )
                                    })}

                                </ToggleButtonGroup>

                                {/*<FormControl>*/}
                                {/*    <br/>*/}
                                {/*    <InputLabel id="demo-simple-select-label">Określ obszar</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        id="demo-simple-select"*/}
                                {/*        labelId="demo-simple-select-label"*/}
                                {/*        value={analysisAoi}*/}
                                {/*        onChange={handleAioChange}*/}
                                {/*    >*/}
                                {/*        <MenuItem key={'menu-item-empty-aoi'} value={null}/>*/}
                                {/*        {aoi && aoi.map((item, index) => {*/}
                                {/*            return (*/}
                                {/*                <MenuItem key={item.value + '-' + index}*/}
                                {/*                          value={item.name}>{item.label}</MenuItem>*/}
                                {/*            )*/}
                                {/*        })}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}


                                {aoi &&
                                getContent()
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Button disabled
                                    onClick={handleBack}
                                    color="primary"
                            >
                                Wstecz
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained" color="primary"
                                onClick={validateAOI} disabled={validating}>
                                {!validating && 'Dalej'}
                                {validating && <CircularProgress size={24}/>}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}

export default AnalysisAOI;
