import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {AnalysisContext} from "../../../context/AnalysisContext";

const useStyles = makeStyles({
    result: {
        fontSize: 12,
        fontWeight: "bold",
        // wordWrap: 'break-word',
        // textOverflow: "ellipsis",
        // overflow: "hidden"
    },
});

function FeatureInfoItemValue(props) {
    let {featureInfoComponents, featureInfo} = useContext(AnalysisContext);
    const classes = useStyles();
    let conditions = props.unit


    let finalValue = (featureInfoComponents === null || featureInfoComponents[props.componentId] === null) ?
        "..." :
        featureInfoComponents[props.componentId]


    function conditionHandler(value, info) {
        if (info !== null) {
            try {
                let label = (info.label === null) ? " " : conditions.label
                let unit = info.name === null ? " " : conditions.name
                let condition = info.conditions.filter((cond) => {
                    return String(cond.input) === String(value)
                })
                let result = (condition.length >= 1) ? condition[0].output : `${label}  ${value} ${unit}`;
                return result

            } catch (error) {
                console.log(error)
            }

        } else {
            return String(value)
        }
    }

    function truncate(string, length) {
        if (typeof string === "string" && string.length > length)
            return string.substring(0, length) + '...';
        else
            return string;
    }

    return (
        <React.Fragment>
            <Tooltip
                title={conditionHandler(finalValue, props?.unit)}
            >
                <Typography align="right"
                            style={{
                                paddingRight: 10,
                                // wordWrap: 'break-word',
                                // textOverflow: "ellipsis",
                                // overflow: "hidden"
                            }}
                    // style={{paddingRight:10}}
                            className={classes.result}>

                    {truncate(conditionHandler(finalValue, props?.unit), 40)}

                </Typography>
            </Tooltip>
        </React.Fragment>
    )
}

export default FeatureInfoItemValue
