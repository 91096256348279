import React, {useContext, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Container} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {apiAuth} from "../../service";
import LoginForm from "./LoginForm";
import {ErrorContext} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";

const useStyles = makeStyles((theme) => ({}));

function SignInForm() {
    const classes = useStyles();
    const [registered, setRegistered] = useState(false)
    let {setInfo} = useContext(ErrorContext);
    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            email: "",
            password: "",
            passwordConfirm: "",
            emailIsValid: false,
            passwordIsValid: false,
            passwordConfirmIsValid: false,
            emailError: "",
            passwordError: "",
            passwordConfirmError: ""
        });

    function handleInput(e) {
        const name = e.target.name;
        const newValue = e.target.value;
        setFormInput({[name]: newValue});
    }


    function validateForm() {
        let emailValid = formInput.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        let passwordValid = formInput.password.length >= 6;
        let passwordMatch = formInput.password === formInput.passwordConfirm && formInput.passwordConfirm.length >= 6;
        (emailValid) ? setFormInput({emailIsValid: true, emailError: ""})
            : setFormInput({emailIsValid: false, emailError: "niepoprawny adres"});
        (passwordValid) ? setFormInput({passwordIsValid: true, passwordError: ""})
            : setFormInput({passwordIsValid: false, passwordError: "hasło powinno mieć minimum 6 znaków"});
        (passwordMatch) ? setFormInput({passwordConfirmIsValid: true, passwordConfirmError: ""})
            : setFormInput({passwordConfirmIsValid: false, passwordConfirmError: "hasło nie pasują do siebie"});

        return (emailValid && passwordValid && passwordMatch)
    }

    function signIn() {
        if (validateForm()) {
            apiAuth.postUserRegister({body: {"email": formInput.email, "password": formInput.password}})
                .then(resp => {
                        console.log(resp)
                        setRegistered(true)
                        setInfo({message: "Witaj nowy użytkowniku. Możesz się zalogować", type: 'success'})
                    },
                )
                .catch(reason => {
                    setRegistered(false)
                    setFormInput({emailIsValid: false, emailError: reason.message})
                    // setInfo({message: reason.message, type: 'error'})
                    console.log(reason.message)
                })
        }
        setInfo({message: null, type: null})
    }

    return (

        <>
            {!registered &&
            <Container>
                <FormControl>
                    <TextField
                        label="e-mail"
                        name="email"
                        error={formInput.emailError !== ""}
                        // defaultValue={formInput.name}
                        // className={classes.textField}
                        helperText={formInput.emailError}
                        onChange={handleInput}
                    />

                    <TextField
                        label="hasło"
                        name="password"
                        type="password"
                        error={formInput.passwordError !== ""}
                        // className={classes.textField}
                        helperText={formInput.passwordError}
                        onChange={handleInput}
                    />

                    <TextField
                        label="powtórz hasło"
                        name="passwordConfirm"
                        type="password"
                        error={formInput.passwordConfirmError !== ""}
                        // defaultValue={formInput.email}
                        // className={classes.textField}
                        helperText={formInput.passwordConfirmError}
                        onChange={handleInput}
                    />

                    <Button
                        // variant="contained"
                        color="primary"
                        // className={classes.button}
                        onClick={signIn}
                    >
                        Rejestracja
                    </Button>

                </FormControl>

            </Container>
            }
            {registered &&
                <>
                <LoginForm/>
                <InfoSnackbar/>
                </>
            }

        </>
    );
}

export default SignInForm
