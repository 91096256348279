import * as React from 'react';
import MaterialTable from "material-table";
import {apiUser} from "../../service";
import {useEffect} from "react";
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Button} from "@material-ui/core";
import {FilterNone} from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function Editable() {
    const {useState} = React;
    const [filtering, setFiltering] = useState(false);
    const [muiTableKey, setMuiTableKey] = useState(1);

    const [columns, setColumns] = useState([
        // {title: 'id', field: 'id'},
        {title: 'email', field: 'email'},
        {
            title: 'deleted',
            field: 'deleted',
            // initialEditValue: 'initial edit value',
            type: 'boolean'
        },
        {title: 'active', field: 'active', type: 'boolean'},
        {
            title: 'role',
            field: 'role.name',
            // render: (rowData) => {
            //     return <div>{rowData.role.name}</div>;
            // },
            lookup: {"ADMIN":"ADMIN", "USER":"USER"},
        },
    ]);

    const [data, setData] = useState();

    function getUsers() {
        apiUser.getUserList()
            .then(resp => {
                setData(resp)
            })
            .catch(reason => {
                console.log(reason.message)
            })
    }

    function updateAccount(user) {
        // console.log(user.email, user.id, user.active, user.deleted, user.role.name)
        apiUser.postUserAdminByUserId({
            user_id: user.id,
            body: {
                deleted: user.deleted,
                active: user.active,
                role: {name: user.role.name}
            }
        })
            .then(resp => {
                console.log(resp)
                getUsers()
            })
            .catch(reason => {
                console.log(reason)
            })
    }


    useEffect(() => {
        getUsers()
    }, []);

    return (
        <MaterialTable
            key={muiTableKey}
            title="Użytkownicy"
            options={{
                filtering: filtering,
                // rowsPerPage: 10
                paging: false,
                maxBodyHeight: "calc(100vh - 130px)",

            }}
            icons={tableIcons}
            columns={columns}
            // rowsPerPageOptions={[5, 10, 20, 30]}
            data={data}
            // rowsPerPage={10}

            actions={[
                // {
                //     icon: () => <FilterNone/>,
                //     // icons={{ Filter: () => <div /> }} ,// <== this solves it
                //     tooltip: "clear all filters",
                //     isFreeAction: true,
                //     onClick: (event) => {
                //         // setMuiTableKey(1)
                //         console.log(muiTableKey + 1)
                //         setMuiTableKey(muiTableKey + 1); // set new key causing remount
                //     }
                // },
                {
                    icon: () => <FilterList/>,
                    tooltip: "Filtry",
                    isFreeAction: true,
                    onClick: (event) => {
                        setFiltering(!filtering)
                    }
                }
            ]}

            editable={{
                // onRowAdd: newData =>
                //     new Promise((resolve, reject) => {
                //         setTimeout(() => {
                //             setData([...data, newData]);
                //
                //             resolve();
                //         }, 1000)
                //     }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);

                            // console.log(newData)
                            updateAccount(newData)
                            resolve();
                        }, 100)
                    }),
                // onRowDelete: oldData =>
                //     new Promise((resolve, reject) => {
                //         setTimeout(() => {
                //             const dataDelete = [...data];
                //             const index = oldData.tableData.id;
                //             dataDelete.splice(index, 1);
                //             setData([...dataDelete]);
                //
                //             resolve()
                //         }, 1000)
                //     }),
            }}
        />
    )
}
