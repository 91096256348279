import React, {useContext} from 'react';
import {AnalysisContext} from "../../../../../context/AnalysisContext";
import {Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

IsochroneAOI.propTypes = {
    timeRange: PropTypes.array.isRequired,
    typeRange: PropTypes.array.isRequired
};
function IsochroneAOI(props) {
    let {time, setTime, transportType, setTransportType} = useContext(AnalysisContext);

    let {timeRange, typeRange} = props

    const handleChangeTransportType = (e) => {
        setTransportType(e.target.value)
    };

    function sortFuncNum(a, b) {
        let aa = Number.parseInt(a.value)
        let bb = Number.parseInt(b.value)
        if (aa < bb) {
            return -1
        } else {
            return 1
        }
    }

    function sortFuncStr(a, b) {
        let aa = a.value
        let bb = b.value
        if (aa < bb) {
            return 1
        } else {
            return -1
        }
    }

    const handleChangeTime = (e) => {
        setTime(e.target.value)
    }

    return (
        <>
            <Grid item>
                <FormControl>
                    <InputLabel id="isochrone-transport-type-select-label">Typ transportu</InputLabel>
                    <Select
                        id="isochrone-transport-type-select"
                        labelId="isochrone-transport-type-select-label"
                        value={transportType}
                        onChange={handleChangeTransportType}
                    >
                        <MenuItem key={'isochrone-type-item-empty'} value={null}/>
                        {typeRange && typeRange.sort(sortFuncStr).map((item, index) => {
                            return (
                                <MenuItem key={'isochrone-type-item-select-' + index}
                                          value={item.value}>{item.title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl>
                    <InputLabel id="isochrone-time-select-label">Wartość (min)</InputLabel>
                    <Select
                        id="isochrone-time-select"
                        labelId="isochrone-time-select-label"
                        value={time >= 1 ? time : null}
                        onChange={handleChangeTime}
                    >
                        <MenuItem key={'isochrone-time-item-empty'} value={null}/>
                        {timeRange && timeRange.sort(sortFuncNum).map((item, index) => {
                            return (
                                <MenuItem key={'isochrone-time-item-select-' + index}
                                          value={Number.parseInt(item.value)}>{item.title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
}

export default IsochroneAOI;
