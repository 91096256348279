import React, {useContext} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NewAnalysisPage from "./analysis/NewAnalysisPage";
import {Link, Route, Switch} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MapIcon from '@material-ui/icons/Map';
import PinDropIcon from  "@material-ui/icons/PinDrop"
import SettingsIcon from '@material-ui/icons/Settings';
import {Button, Container} from "@material-ui/core";
import ManualPage from "./ManualPage";
import MyAnalysisById from "./myanalyses/MyAnalysisById";
import UserPage from "./auth/UserPage";
import PrivateRoute from "./auth/PrivateRoute";
import {AuthContext} from "../context/AuthContext";
import AccountMenu from "./auth/AccountMenu";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import AdminPage from "./admin/AdminPage";
import ErrorPage from "../utils/ErrorPage";
import {ErrorProvider} from "../context/ErrorContext";
import InfoSnackbar from "./snackbar/InfoSnackbar";
import PoiAnalysisPage from "./PoiAnalysis/PoiAnalysis";
import MyLocalAnalyses from "./mylocalanalysis/MyLocalAnalysis";
import MyLocalAnalysisById from "./mylocalanalysis/MyLocalAnalysisById";
import Archive from "./Archive";
import MyAnalyses from "./myanalyses/MyAnalysesListMUIT";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        variant: 'dense',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        variant: 'dense',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    title: {
        flexGrow: 1
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    linkUpper: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    }
}));

export default function MiniDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let {isLogged, auth, userLogin, setUserLogin, userRole, setUserRole} = useContext(AuthContext)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div
            className={classes.root}
        >
            <CssBaseline/>
            <AppBar
                // position="static"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h5" noWrap className={classes.title}>
                        PAI - Platforma Atrakcyjności Inwestycyjnej
                    </Typography>
                    <h5>
                        {userLogin} {userRole}
                    </h5>
                    <AccountMenu/>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>

                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Instrukcja" classes={{primary: classes.linkUpper}}/>
                    </ListItem>

                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/analyze"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <MapIcon/>
                            {/*<AddCircleIcon/>*/}
                        </ListItemIcon>
                        <ListItemText primary="Analiza Obszarowa" classes={{primary: classes.linkUpper}}/>
                    </ListItem>

                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/localanalyze"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <PinDropIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Analiza Lokalna" classes={{primary: classes.linkUpper}}/>
                    </ListItem>


                </List>
                {/*<Divider/>*/}
                {/*<List>*/}
                {/*    <ListItem button component={React.forwardRef((p, _ref) => {*/}
                {/*        return (<Link to={"/auth"} {...p} />)*/}
                {/*    })}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <AccountCircleIcon/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Logowanie" classes={{primary: classes.linkUpper}}/>*/}
                {/*    </ListItem>*/}
                {/*</List>*/}
                <Divider/>
                {isLogged &&
                <List>
                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/archive"} {...p} />)
                        // return (<Link to={"/myanalyses"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <AssessmentIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Moje Analizy" classes={{primary: classes.linkUpper}}/>
                    </ListItem>
                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/priv"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <AccountBoxRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Użytkownik" classes={{primary: classes.linkUpper}}/>
                    </ListItem>
                </List>
                    // <Divider/>
                }
                {isLogged && userRole === "ADMIN" &&
                <List>
                    <ListItem button component={React.forwardRef((p, _ref) => {
                        return (<Link to={"/admin"} {...p} />)
                    })}>
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Administracja" classes={{primary: classes.linkUpper}}/>
                    </ListItem>
                    <InfoSnackbar/>
                </List>

                }
            </Drawer>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>
                    <PrivateRoute path="/analyze">
                        <NewAnalysisPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/localanalyze">
                        <PoiAnalysisPage/>
                    </PrivateRoute>
                    {/*<Route path="/analyze" exact component={NewAnalysisPage}/>*/}
                    <Route path="/" exact component={ManualPage}/>
                    <Route path="/auth" component={UserPage}/>
                    <Route path="/myanalyses/:id" component={MyAnalysisById}/>
                    {/*<Route path="/myanalyses" exact component={CenteredTabs}/>*/}
                    {/*<Route path="/myanalyses/:id" component={MyLocalAnalysisById}/>*/}
                    <Route path="/mylocalanalyses/:id" component={MyLocalAnalysisById}/>
                    <PrivateRoute path="/myanalyses">
                        <MyAnalyses/>
                    </PrivateRoute>
                    <PrivateRoute path="/myanalyses/:id">
                        <MyAnalyses/>
                    </PrivateRoute>
                    <PrivateRoute path="/priv">
                        <PrivateComponent/>
                    </PrivateRoute>
                    <PrivateRoute path="/admin">
                        <AdminPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/archive">
                        <Archive/>
                    </PrivateRoute>
                    <PrivateRoute path="/mylocalanalyses">
                        <MyLocalAnalyses/>
                    </PrivateRoute>
                    <PrivateRoute path="/mylocalanalyses/:id">
                        <MyLocalAnalysisById/>
                    </PrivateRoute>
                    <Route path="*" component={ErrorPage}/>
                </Switch>
            </main>
        </div>
    );
}


const PrivateComponent = () => {
    let {isLogged, auth, userLogin} = useContext(AuthContext)
    return (
        <>
            <h3>Panel użytkownika</h3>
            <FormControl>
                <TextField
                    label="imię"
                    name="name"
                    // defaultValue={formInput.name}
                    // className={classes.textField}
                    // helperText="e.g. name@gmail.com"
                    // onChange={handleInput}
                    // error={authError !== ""}
                />
                <TextField
                    label="nazwisko"
                    name="surname"
                    // defaultValue={formInput.name}
                    // className={classes.textField}
                    // helperText="e.g. name@gmail.com"
                    // onChange={handleInput}
                    // error={authError !== ""}
                />
                <TextField
                    label="nazwisko"
                    name="surname"
                    // defaultValue={auth.userLogin}
                    // className={classes.textField}
                    // helperText="e.g. name@gmail.com"
                    // onChange={handleInput}
                    // error={authError !== ""}
                />
                <TextField
                    label="NIP"
                    name="NIP"
                    // defaultValue={formInput.name}
                    // className={classes.textField}
                    // helperText="e.g. name@gmail.com"
                    // onChange={handleInput}
                    // error={authError !== ""}
                />
                <TextField
                    label="e-mail"
                    name="login"

                    defaultValue={userLogin}
                    // className={classes.textField}
                    // helperText="e.g. name@gmail.com"
                    // onChange={handleInput}
                    // error={authError !== ""}
                />
                <TextField
                    label="hasło"
                    name="password"
                    type="password"
                    // error={authError !== ""}
                    // defaultValue={formInput.email}
                    // className={classes.textField}
                    // helperText={authError !== "" ? authError : "wprowadź hasło użytkownika"}
                    // helperText={authError}
                    // onChange={handleInput}
                />
                <Button
                    // variant="contained"
                    color="primary"
                    // className={classes.button}
                    // onClick={logIn}
                >
                    Aktualizuj
                </Button>
                {/*<h3>Płatności</h3>*/}
                {/*<TextField*/}
                {/*    id="date"*/}
                {/*    label="abonament"*/}
                {/*    type="date"*/}
                {/*    defaultValue="2022-05-24"*/}
                {/*    // className={classes.textField}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<Button*/}
                {/*    // variant="contained"*/}
                {/*    color="primary"*/}
                {/*    // className={classes.button}*/}
                {/*    // onClick={logIn}*/}
                {/*>Ureguluj</Button>*/}
                <Typography>Twój token wygaśnie o {localStorage.getItem('expiration_time')} </Typography>
            </FormControl>
        </>
    )
}


