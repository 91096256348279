import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Slide from "@material-ui/core/Slide";

import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles((theme) => ({
    root: {
        height: 175,
        width: 400,
        position: "absolute",
        zIndex: "9999",
        right: "0px",
        top: "150px",
    },
    switcher: {
        position: "absolute",
        zIndex: "1299",
        left: "0px",
        bottom: "25px",
    },
    paper: {
        height: 30,
        width: 240,
        display: "block",
        position: "absolute",
        left: "44px",
        bottom: "34px",
        zIndex: "990",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4
    },
    media: {
        height: 0,
        width: 500,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        transform: "rotate(90deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    expandOpen: {
        transform: "rotate(-90deg)"
    },
}));

function MapToolbar() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
        setChecked((prev) => !prev);
    };

    const handleChange = () => {
        // setChecked((prev) => !prev);
        setExpanded(!expanded);
    };


    return (
        <div>
            <div className={classes.switcher}>
                <IconButton
                    boxShadow={1}
                    variant="contained"
                    size="small"
                    color="primary"
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="Pokaż legendę"

                >
                    <ExpandMoreIcon/>
                </IconButton>
            </div>
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                {/*<Slide direction="right" in={checked} mountOnEnter unmountOnExit>*/}


                {/*    <div className={classes.paper}>*/}
                <Paper className={classes.paper}>
                    <Grid container>

                        <Grid>
                            <Tooltip title={"bardzo niska"}><FiberManualRecordSharpIcon
                                style={{color: 'rgba(214, 0, 4, 0.55)'}}
                                fontSize={"small"}/></Tooltip>
                            <Tooltip title={"niska"}><FiberManualRecordSharpIcon
                                style={{color: 'rgba(245, 98, 0, 0.55)'}}
                                fontSize={"small"}/></Tooltip>
                            <Tooltip title={"średnia"}><FiberManualRecordSharpIcon
                                style={{color: 'rgba(255, 204, 0, 0.55)'}}
                                fontSize={"small"}/></Tooltip>
                            <Tooltip title={"wysoka"}><FiberManualRecordSharpIcon
                                style={{color: 'rgba(200, 240, 0, 0.55)'}}
                                fontSize={"small"}/></Tooltip>
                            <Tooltip title={"bardzo wysoka"}><FiberManualRecordSharpIcon
                                style={{color: 'rgba(0, 148, 44, 0.55)'}}
                                fontSize={"small"}/></Tooltip>
                        </Grid>
                        <Grid item style={{paddingTop: "1px", paddingLeft: "2px"}}>Atrakcyjność obszaru</Grid>
                    </Grid>
                </Paper>
                {/*</div>*/}
                {/*</Slide>*/}
            </Grow>

        </div>
    );
}

export default MapToolbar
