/*jshint -W069 */
/**
 * Platforma Atrakcyjności Inwestycyjnej
 * @class Api
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
var Api = (function() {
    'use strict';

    var Q = require('q');
    var settingsInstance = require('./SettingsInstance.js').default;
    var apiKeyInstance = require('./ApiKeyInstance.js').default;
    var customRequest = require('./requestHelper.js').customRequest;
    var mergeQueryParams = require('./requestHelper.js').mergeQueryParams;
    var PAI_TOKEN = require("./constants").PAI_TOKEN;

    function Api(options) {
        var domain = (typeof options === 'object') ? options.domain : options;
        this.domain = domain ? domain : settingsInstance.baseApiUrl;
        if (this.domain.length === 0) {
            throw new Error('Domain parameter must be specified as a string.');
        }
        this.apiKey = (typeof options === 'object') ? (options.apiKey ? options.apiKey : {}) : {};
    }

    /**
     * HTTP Request
     * @method
     * @name Api#request
     * @param {string} method - http method
     * @param {string} path - path
     * @param {string} url
     * @param {object} body - body parameters / object
     * @param {object} headers - header parameters
     * @param {object} queryParameters - querystring parameters
     * @param {object} form - form data object
     * @param {object} deferred - promise object
     */
    Api.prototype.request = customRequest;

    /**
     * Set Api Key
     * @method
     * @name Api#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    Api.prototype.setApiKey = function(value, headerOrQueryName, isQuery) {
        apiKeyInstance.setApiKey(value, headerOrQueryName, isQuery)
    };
    /**
     * Set Auth headers
     * @method
     * @name Api#setAuthHeaders
     * @param {object} headerParams - headers object
     */
    Api.prototype.setAuthHeaders = function(headerParams) {
        var headers = headerParams ? headerParams : {};
        if (localStorage.getItem(PAI_TOKEN)) {
            headers["Authorization"] = localStorage.getItem(PAI_TOKEN);
        }
        return headers;
    };

    /**
     * Pobieranie listy AOI
     * @method
     * @name Api#getAoiList
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAoiList = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/aoi/list',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie listy AOI
     * @method
     * @name Api#getAoiPropertyList
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAoiPropertyList = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/aoi/property/list',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie właściwości AOI o zadanym ID.
     * @method
     * @name Api#getAoiPropertyByAoiPropertyId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.aoi_property_id - ID właściwości AOI
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAoiPropertyByAoiPropertyId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/aoi/property/{aoi_property_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{aoi_property_id}', parameters['aoi_property_id']);

        if (parameters['aoi_property_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: aoi_property_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie AOI o zadanym ID.
     * @method
     * @name Api#getAoiByAoiId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.aoi_id - ID AOI
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAoiByAoiId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/aoi/{aoi_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{aoi_id}', parameters['aoi_id']);

        if (parameters['aoi_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: aoi_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };

    return Api;
})();

exports.Api = Api;