import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import AnalysisIndocators from "../../../AnalysisIndicators";
import * as PropTypes from "prop-types";
import {Grid} from "@material-ui/core";
import {AnalysisContext} from "../../../../../context/AnalysisContext";
import {apiAnalysis} from "../../../../../service";

AnalysisParams.propTypes = {
    analysisId: PropTypes.number.isRequired,
    handleReset: PropTypes.func.isRequired,
};

function AnalysisParams(props) {
    const {analysisId, handleReset} = props
    const [expanded, setExpanded] = React.useState(false);

    let {radius, transportType, time, analysisAoi} = useContext(AnalysisContext)

    const [aoi, setAoi] = useState()

    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        apiAnalysis.getAnalysisByAnalysisIdAoi({analysis_id: analysisId})
            .then(body => {
                setAoi(body)
            })
            .catch(reason => {
                console.log(reason)
            })
    }, [])


    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography style={{fontSize: '1rem'}}>Parametry analizy</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{maxHeight: '10vh', overflowX: 'hidden', overflowY: 'auto', padding: '8px'}}>
                <Grid container>
                    <Grid style={{marginBottom: 16}}
                          container
                          direction="column"
                          justify="space-around"
                          alignItems="stretch">
                        {
                            aoi &&
                            <>
                                <Typography>Określony obszar: {aoi.name}</Typography>
                                {
                                    aoi.properties.map((item, index) => {
                                        return (
                                            <Typography>{item.name}: {item.title}</Typography>
                                        )
                                    })
                                }
                            </>
                        }
                    </Grid>
                    <AnalysisIndocators analysisId={analysisId}/>
                </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default AnalysisParams;
