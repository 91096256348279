import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AnalysisProvider} from "../../context/AnalysisContext";
import {ErrorProvider} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import UpDownButton from "../../utils/UpDownButton";
import MapPoiAnalysis from "../PoiAnalysis/MapPoiAnalysis";
import PoiAnalysisResult from "../PoiAnalysis/PoiAnalysisResult";
import PoiAnalysisAoi from "../PoiAnalysis/PoiAnalysisAoi";
import {PoiAnalysisProvider} from "../../context/PoiAnalysisContext";
import {ToolProvider} from "../../context/ToolContext";

const useStyles = makeStyles((theme) => ({
    gridCustomeight: {
        maxHeight: 'calc(100%-64px)',
        overflow: 'auto',
        padding: theme.spacing(1),
    },
    mapContainer: {
        width: "auto",
        height: "90vh"
    }
}));

export default function MyLocalAnalysisById({match}) {
    // window.document.title = "Platforma Atrakcyjności Inwestycyjnej - panel generacji nowych analiz przestrzennych"
    const classes = useStyles();

    return (
        <>
            <ToolProvider>
                <AnalysisProvider>
                    <PoiAnalysisProvider>
                        {/*<PoiAnalysis*/}
                        <ErrorProvider>
                            <Grid container spacing={0}>
                                <Grid  item xs={12} sm={6} md={4} lg={4} xl={3}
                                      className={classes.gridCustomeight}
                                >
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        {/*<Grid item>*/}
                                        {/*    /!*{match.params.id}*!/*/}
                                        {/*    /!*<PoiAnalysisAoi/>*!/*/}
                                        {/*</Grid>*/}

                                        <Grid item>

                                            <PoiAnalysisResult/>

                                        </Grid>

                                    </Grid>


                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
                                    <MapPoiAnalysis id={"_map" + String(Math.random()).substr(5, 5)}
                                        data={match.params.id}
                                    />
                                </Grid>
                                <UpDownButton/>
                            </Grid>
                            <InfoSnackbar/>
                        </ErrorProvider>
                        <UpDownButton/>
                    </PoiAnalysisProvider>
                </AnalysisProvider>
            </ToolProvider>
        </>
    );
}
