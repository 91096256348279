import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import AddressAutocomplete from "../../utils/AddressAutocomplete";
import {Button, IconButton} from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import IsochroneToolbar from "../maptools/isochonesToolbar/IsochronesToolbar";
import IsochroneToggle from "./IsochroneToggle";

function PoiAnalysisAoi() {

    let {
        aoiPoint, setAoiPoint,
        result, setResult,
        aoiDrawingActive, setAoiDrawingActive,
        setPoiRouteOrigin
    } = useContext(PoiAnalysisContext)

    const [expanded, setExpanded] = React.useState(true);

    const addressHandler = (e) => {
        setAoiPoint(e.reverse())
        console.log(e)
        // setAoiPoint([e.latlng.lat, e.latlng.lng])
        setPoiRouteOrigin(e)
    }

    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                aria-controls="areaAtrr-content"
                id="areaAtrr-header"
            >
                <Typography style={{fontSize: '1rem'}}>Znajdź miejsce</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <React.Fragment>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            // justifyContent="space-between"
                        >
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <AddressAutocomplete variant="outlined" addressHandler={addressHandler}/>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <ToggleButton
                                        // value="check"
                                        variant="outlined"
                                        size={"small"}
                                        selected={aoiDrawingActive}
                                        onChange={() => {
                                            setAoiDrawingActive(!aoiDrawingActive)
                                            // setSelected(!true);
                                        }}
                                    ><RoomIcon/></ToggleButton>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <IsochroneToggle/>
                            </Grid>
                        </Grid>
                </React.Fragment>
            </AccordionDetails>
        </Accordion>


    );
}

export default PoiAnalysisAoi;
