/*jshint -W069 */
/**
 * Platforma Atrakcyjności Inwestycyjnej
 * @class Api
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
var Api = (function () {
    'use strict';

    var Q = require('q');
    var settingsInstance = require('./SettingsInstance.js').default;
    var customRequest = require('./requestHelper.js').customRequest;
    var mergeQueryParams = require('./requestHelper.js').mergeQueryParams;

    function Api(options) {
        var domain = (typeof options === 'object') ? options.domain : options;
        this.domain = domain ? domain : settingsInstance.baseApiUrl;
        if (this.domain.length === 0) {
            throw new Error('Domain parameter must be specified as a string.');
        }
    }

    /**
     * HTTP Request
     * @method
     * @name Api#request
     * @param {string} method - http method
     * @param {string} path - path
     * @param {string} url
     * @param {object} body - body parameters / object
     * @param {object} headers - header parameters
     * @param {object} queryParameters - querystring parameters
     * @param {object} form - form data object
     * @param {object} deferred - promise object
     */
    Api.prototype.request = customRequest;

    /**
     * Pobieranie jst na podstawie inputu.
     * @method
     * @name Api#getGeomRullete
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.search - Wyszukiwana fraza
     */
    Api.prototype.getGeomRullete = function (parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/geom-rullete',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers['Accept'] = ['application/json'];

        /** set default value **/
        queryParameters['search'] = 'rasnyst';

        if (parameters['search'] !== undefined) {
            queryParameters['search'] = parameters['search'];
        }

        if (parameters['search'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: search'));
            return deferred.promise;
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };

    return Api;
})();

exports.Api = Api;
