import {hereApiKey, osr, osrApiKey} from './api.js'
import {Api as ApiAnalysis} from '../api/api-analizy'
import {Api as ApiAdresses} from '../api/api-adresy'
import {Api as ApiCategories} from '../api/api-kategorie'
import {Api as ApiIndicators} from '../api/api-wskazniki'
import {Api as ApiAoi} from '../api/api-aoi'
import {Api as ApiAuth} from '../api/api-auth'
import {Api as ApiExternal} from '../api/api-apis'
import {Api as ApiUser} from '../api/api-user'
import {Api as ApiGeocoder} from '../api/api-geocoder'
import {Api as ApiMapcolor} from '../api/api-mapcolor'
import {Api as ApiLocalAnalysis} from '../api/api-analiza-lokalna'

export const apiAnalysis = new ApiAnalysis()
export const apiAdresses = new ApiAdresses()
export const apiCategories = new ApiCategories()
export const apiIndicators = new ApiIndicators()
export const apiAoi = new ApiAoi()
export const apiAuth = new ApiAuth()
export const apiExternal = new ApiExternal()
export const apiUser = new ApiUser()
export const apiMapcolor = new ApiMapcolor()
export const apiGeocoder = new ApiGeocoder()
export const apiLocalAnalysis = new ApiLocalAnalysis()

export function getOSRisochrone(params, data) {
    return fetch(osr + params, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
            'Authorization': osrApiKey
        },
        body: JSON.stringify(data)
    })
}

export function getHEREisochrone(localization) {
    return fetch('https://isoline.router.hereapi.com/v8/isolines?' +
        'transportMode=car' +
        '&origin=' + localization[1] + ',' + localization[0] +
        '&range[type]=time' +
        '&range[values]=300' +
        '&apiKey=' + hereApiKey)
}




