import * as React from 'react';
import {DataGrid, GridToolbar} from '@material-ui/data-grid';
import {Button} from "@material-ui/core";
import {useEffect, useState} from "react";
import {apiUser} from "../../service";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import {TextFields} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Rating from "@material-ui/lab/Rating";

const rows = [
    {
        "id": 1,
        "email": "ppisera@e-point.pl",
        "deleted": false,
        // "role": {
        //     "name": "USER"
        // },
        "role": "USER",
        "active": false,
        "nickname": null
    },
    {
        "id": 2,
        "email": "nsuchojad@e-point.pl",
        "deleted": false,
        // "role": {
        //     "name": "ADMIN"
        // },
        "role": "ADMIN",
        "active": false,
        "nickname": "asdasd"
    },
    {
        "id": 3,
        "email": "a@a.pl",
        "deleted": true,
        // "role": {
        //     "name": "USER"
        // },
        "role": "USER",
        "active": true,
        "nickname": "asd"
    },
];

function DataGridDemo() {

    const [userData, setUserData] = useState([]);


    function renderRating1(params) {
        console.log(params.value)
        return <Typography readOnly >{params.value}</Typography> ;
    }

    function renderRating2(params) {
        console.log(params)
        return <Select
            value={params.value}
            // onChange={(e) => {
            //     params.value = e.target.value
            //     console.log(params.value);
            //
            // }}
        >
            <MenuItem value={"a"}>ADMIN</MenuItem>
            <MenuItem  value={"u"}>USER</MenuItem>
        </Select> ;
    }


    function renderRating(params) {
        console.log(params.value)
        return <Select
            value={params.value}
            // onChange={(e) => {
            //     params.value = e.target.value
            //     console.log(params.value);
            //
            // }}
        >
            <MenuItem value={"a"}>ADMIN</MenuItem>
            <MenuItem  value={"u"}>USER</MenuItem>
        </Select>;
    }

    function RatingEditInputCell(props) {
        const { id, value, api, field } = props;
        // const classes = useStyles();

        const handleChange = React.useCallback(
            (event) => {
                const editProps = {
                    value: String(event.target.value),
                };

               console.log(event.target.value)

                api.commitCellChange({ id, field, props: editProps });
                api.setCellMode(id, field, 'view');
            },
            [api, field, id],
        );

        return (
            <div >
                {/*<Rating value={Number(value)} onChange={handleChange} />*/}
                <Select
                    // disabled={!user.edit}

                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={value.name}
                    // onChange={handleChange}
                    // onChange={(e) => {
                    //     user.role.name = e.target.value;
                    //     setUserData([...userData]);
                    // }}
                >
                    <MenuItem value={"USER"}>USER</MenuItem>
                    <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                </Select>
            </div>
        );
    }


    RatingEditInputCell.propTypes = {
        /**
         * GridApi that let you manipulate the grid.
         */
        api: PropTypes.any.isRequired,
        /**
         * The column field of the cell that triggered the event
         */
        field: PropTypes.string.isRequired,
        /**
         * The grid row id.
         */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        /**
         * The cell value, but if the column has valueGetter, use getValue.
         */
        value: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.number,
            PropTypes.object,
            PropTypes.string,
            PropTypes.bool,
        ]),
    };

    function renderRatingEditInputCell(params) {
        return <RatingEditInputCell {...params} />;
    }

    const columns = [
        // {field: 'id', headerName: 'ID',flex: 1,},
        {field: 'email', editable: true, headerName: 'email', flex: 1,},
        {
            field: 'deleted',
            headerName: 'deleted',
            type: 'boolean',
            flex: 1,
            // editable: true
        },
        {
            field: 'role',
            headerName: 'role',
            flex: 1,
            editable: true,
            sortable: true,
            renderCell: renderRating1,
            // renderEditCell: renderRating2,
            renderEditCell: renderRatingEditInputCell,
            // valueFormatter: ({value}) => value.name
        },
        {
            field: 'active',
            headerName: 'active',
            flex: 1,
            type: 'boolean',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            editable: true
            // valueGetter: (params) =>
            //     `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
            // renderCell: () => (<Button>test</Button>)
        },
        {field: 'nickname', headerName: 'nickname', flex: 1,},
        {
            field: '',
            headerName: 'action',
            flex: 1,
            renderCell: (params) => (<Button
                onClick={(e) => {
                    console.log(params.row);
                    getUsers()
                }}
            >aktualizuj</Button>)
        },
    ];


    function getUsers() {
        apiUser.getUserList()
            .then(resp => {
                setUserData(resp)
            })
            .catch(reason => {
                console.log(reason.message)
            })
    }

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div style={{height: "calc(100vh - 64px)", width: '100%'}}>
            {/*<Select*/}
            {/*    // disabled={!user.edit}*/}

            {/*    // labelId="demo-simple-select-label"*/}
            {/*    // id="demo-simple-select"*/}
            {/*    value={"User"}*/}
            {/*    // onChange={handleChange}*/}
            {/*    // onChange={(e) => {*/}
            {/*    //     user.role.name = e.target.value;*/}
            {/*    //     setUserData([...userData]);*/}
            {/*    // }}*/}
            {/*>*/}
            {/*    <MenuItem value={"USER"}>USER</MenuItem>*/}
            {/*    <MenuItem value={"ADMIN"}>ADMIN</MenuItem>*/}
            {/*</Select>*/}
            <DataGrid
                localeText={{
                    toolbarDensity: 'Rozmiar',
                    toolbarDensityLabel: 'Rozmiar',
                    toolbarDensityCompact: 'Kompaktowy',
                    toolbarDensityStandard: 'Standardowy',
                    toolbarDensityComfortable: 'Wygodny',
                }}
                rows={rows} columns={columns} pageSize={20} components={{
                Toolbar: GridToolbar,
            }}/>
        </div>
    );
}

export default DataGridDemo