import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import {AnalysisContext} from "../../../context/AnalysisContext";
import {ToolContext} from "../../../context/ToolContext";

const useStyles = makeStyles((theme) => ({}));

export default function OverLayerElement(props) {
    const classes = useStyles();
    let {setOverLayer, mapZoomLvl} = useContext(ToolContext)
    const [state, setState] = React.useState(false);
    let {} = useContext(AnalysisContext)

    const overLayerChange = (event) => {
        setState((prev) => !prev);
        setOverLayer({
            visibleOnMap: event.target.checked,
            value: props.value
        })
    };

    function compare(){
        // console.log(mapZoomLvl, parseInt(props.minZlevel))
        // console.log((parseInt(mapZoomLvl) < parseInt(props.minZlevel)) )
        return (parseInt(mapZoomLvl) < parseInt(props.minZlevel))
    }
    return (
        <>
            <Switch
                disabled={compare()}
                checked={state}
                onChange={overLayerChange}
                name="orto"
                size={"small"}
                color={"primary"}
                label={"test"}
                aria-label={"google Orto"}
                inputProps={{'aria-label': 'secondary checkbox'}}
            />
        </>
    );
}


