import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as PropTypes from "prop-types";
import CategoryList from "../category/CategoryList";
import {apiAnalysis, apiCategories} from "../../../../service";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {AnalysisContext} from "../../../../context/AnalysisContext";
import {ErrorContext} from "../../../../context/ErrorContext";

AnalysisCustomization.propTypes = {
    handleBack: PropTypes.func.isRequired,
    handleLastStep: PropTypes.func.isRequired,
    analysisId: PropTypes.number.isRequired
};

function AnalysisCustomization(props) {
    const {handleBack, handleLastStep} = props
    const [categories, setCategories] = React.useState()
    const [loaded, setLoaded] = React.useState(false)
    const [validating, setValidating] = React.useState(false)

    let {analysisId, indicatorsList} = useContext(AnalysisContext)
    let {setInfo} = useContext(ErrorContext)

    const validate = () => {
        setValidating(true)
        if (indicatorsList && indicatorsList.length > 0) {
            const data = {
                "indicator_list": indicatorsList
            }
            apiAnalysis.postAnalysisByAnalysisId({analysis_id: analysisId, body: data})
                .then(body => {
                        if (body) {
                            setInfo({message: body.message ? body.message : body.toString(), type: 'success'})
                            setValidating(false)
                            handleLastStep()
                        }
                    },
                    body => {
                        setValidating(false)
                        setInfo({
                            message: "Wystąpił błąd: ".concat(body.message ? body.message : body.toString()),
                            type: 'error'
                        })
                    }
                )
                .catch(reason => {
                    setInfo({message: "Wystąpił błąd: " + reason, type: 'error'})
                })
        } else {
            setValidating(false)
            setInfo({message: 'Najpierw określ swoje preferencje', type: 'warning'})
        }
    }

    useEffect(() => {
        apiCategories.getCategories()
            .then(body => {
                setLoaded(true)
                if (body) {
                    setCategories(body)
                }
            })
            .catch(reason => {
                setLoaded(false)
            })
    }, [])


    return (
        <>
            {
                !loaded &&
                <CircularProgress/>
            }
            {
                loaded &&
                <div style={{maxHeight: '65vh', overflowX: 'hidden', overflowY: 'auto',
                    padding: '8px'
                }}>
                    {
                        categories &&
                        <CategoryList categories={categories}/>
                    }
                    {
                        !categories &&
                        <Typography color={"error"}>Błąd pobierania danych</Typography>
                    }
                </div>
            }

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={handleBack}>
                        Wstecz
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained" color={!validating ? "primary" : "secondary"}
                        onClick={validate} disabled={validating}>
                        {!validating && 'Wykonaj'}
                        {validating && <CircularProgress size={24}/>}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default AnalysisCustomization;
