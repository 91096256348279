import React from 'react';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Radio from "@material-ui/core/Radio";

export default function PowerRadio(props) {
    return (
        <Radio
            checkedIcon={<PowerSettingsNewIcon/>}
            icon={<PowerSettingsNewIcon/>}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

