import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AnalysisProvider} from "../../context/AnalysisContext";
import AnalysisStepper from "./new/AnalysisStepper";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import {ErrorProvider} from "../../context/ErrorContext";
import UpDownButton from "../../utils/UpDownButton";
import Map from "./Map";
import {ToolProvider} from "../../context/ToolContext";


const useStyles = makeStyles((theme) => ({
    gridCustomeight: {
        maxHeight: 'calc(100%-64px)',
        overflow: 'auto'
    },
    mapContainer: {
        width: "auto",
        height: "90vh"
    }
}));

export default function NewAnalysisPage() {
    window.document.title = "Platforma Atrakcyjności Inwestycyjnej - panel generacji nowych analiz przestrzennych"
    const classes = useStyles();

    return (
        <ToolProvider>
            <AnalysisProvider>
                <ErrorProvider>
                    <Grid container spacing={0}>
                        <Grid  item xs={12} sm={6} md={4} lg={4} xl={3}
                               // spacing={1}
                               className={classes.gridCustomeight}>
                            <AnalysisStepper/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={8} xl={9} className={classes.gridCustomeight}>
                            <Map id={"_map" + String(Math.random()).substr(5, 5)}/>
                        </Grid>
                    </Grid>
                    <InfoSnackbar/>
                </ErrorProvider>
                <UpDownButton/>
            </AnalysisProvider>
        </ToolProvider>
    );
}
