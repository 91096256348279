import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import PoiCategory from "./PoiCategory";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import GetReport from "./GetReport";
import SavePoiAnalysis from "./SavePoiAnalysis";
import SaveButton from "./SaveButton";

function PoiAnalysisResult() {
    let {result, blockSaving} = useContext(PoiAnalysisContext)

    const [expanded, setExpanded] = React.useState(true);

    const handleChange = (event) => {
        setExpanded(!expanded);
    };
    return (
        <>
            {result &&
            <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                    aria-controls="areaAtrr-content"
                    id="areaAtrr-header"
                >
                    <Typography style={{fontSize: '1rem'}}>Atrakcyjność obszaru</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <React.Fragment>
                        <div style={{maxHeight: '50vh', overflowX: 'hidden', overflowY: 'auto'}}>
                            <Grid container>
                                {result &&
                                result.result.map((element, index) => (
                                    <PoiCategory key={index} data={element} id={index}/>
                                ))}
                            </Grid>
                        </div>
                    </React.Fragment>
                </AccordionDetails>
            </Accordion>
            }
            <>
                {result &&
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <GetReport/>
                    </Grid>
                    <Grid item>
                        {blockSaving &&
                        <SaveButton/>
                        }
                    </Grid>
                </Grid>}
            </>

        </>
    );
}

export default PoiAnalysisResult;
