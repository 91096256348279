import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useContext, useEffect} from "react";
import {Button} from "@material-ui/core";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import {apiLocalAnalysis} from "../../service";


const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        width: 500,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
}));

function SavePoiAnalysis() {
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles()
    const [checked, setChecked] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);
    const [id, setId] = React.useState(false);
    let {result} = useContext(PoiAnalysisContext)

    const saveAnalysis = () => {
        // setExpanded(!expanded);
        // setChecked((prev) => !prev);
        // console.log(id)
        // apiLocalAnalysis.getLocalanalysisReportByReportId({report_id : id})
        // apiLocalAnalysis.postLocalanalysisByAnalysisIdReport({analysis_id: id})
        //     .then()
    };

    useEffect(() => {
        if(result){
            console.log(result.id)
            setId(result.id)
        }
    }, [result])



    return (
        <>
            <Button
                onClick={saveAnalysis}
                variant="contained" color="primary"
            >Zapisz</Button>
        </>

    )

}

export default SavePoiAnalysis
