import React, {useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";

import LayersIcon from '@material-ui/icons/Layers';
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import FeatureInfo from "./FeatureInfo";
import {AnalysisContext} from "../../../context/AnalysisContext";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({

    switcher: {
        position: "absolute",
        // position: "relative",
        zIndex: "999",

        right: "0px",
        top: "40px",
    },
    paper: {
        // height: 570,
        width: 350,
        display: "block",
        position: "absolute",

        right: "1%",
        top: "80px",
        // zIndex: "-1",
        zIndex: "999",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        // padding: 8,
        maxHeight: '65vh',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function FeatureInfoSidebar() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    let {analysisId, featureInfo} = useContext(AnalysisContext)

    return (
        <>
            <div className={classes.switcher}>
                {/*<p>{analysisId}</p>*/}
                {/*{analysisId === null &&*/}
                <Tooltip title="Identyfikacja obiektów">
                <IconButton onClick={handleChange} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <InfoIcon/>
                </IconButton>
                </Tooltip>
                {/*}*/}

            </div>
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                <div
                    className={classes.paper}>
                    <Box>

                        {analysisId !== null && featureInfo !== null &&
                        <>
                            <FeatureInfo analysisId={analysisId}/>
                        </>
                        }

                        {featureInfo === null &&
                        <Paper>
                            <Typography>
                                {/*<Box>{JSON.stringify(featureInfo)}</Box>*/}
                                <Box>Aby poznać szczegóły, kliknij w wybrany obiekt na mapie</Box>
                            </Typography>
                        </Paper>
                        }
                    </Box>
                </div>
            </Grow>
        </>
    );
}

export default FeatureInfoSidebar
