import MuiAlert from "@material-ui/lab/Alert";
import React, {useContext, useEffect, useState} from "react";
import {Snackbar} from "@material-ui/core";
import PropTypes from "prop-types";
import {ErrorContext} from "../../context/ErrorContext";

InfoSnackbar.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string
};


function InfoSnackbar(props) {

    const [open, setOpen] = useState(false)

    let {info} = useContext(ErrorContext)

    function handleClose() {
        setOpen(false)
    }

    useEffect(() => {
        if (info.message !== null) {
            setOpen(true)
        }
        return () => setOpen(false)
    }, [info])

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            // autoHideDuration={info.duration}
            autoHideDuration={2500}
            onClick={handleClose}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity={info.type}>
                {info.message}
            </Alert>
        </Snackbar>
    )
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default InfoSnackbar;
