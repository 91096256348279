import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider, Typography} from "@material-ui/core";
import {RadioButtonChecked, RadioButtonCheckedTwoTone} from "@material-ui/icons";
import AnalysisSumUp from "../analysis/new/steps/AnalysisSumUp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AreaAttractiveness from "../analysis/new/steps/sumup/AreaAttractiveness";
import LocalAttractiveness from "../analysis/new/steps/sumup/LocalAttractiveness";
import Slide from "@material-ui/core/Slide";
import AnalysisParams from "../analysis/new/steps/sumup/AnalysisParams";
import Button from "@material-ui/core/Button";
import LayersIcon from '@material-ui/icons/Layers';
import IconButton from "@material-ui/core/IconButton";
// import DesignServicesIcon from '@material-ui/icons/DesignServices';
import AddIcon from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import {AnalysisContext} from "../../context/AnalysisContext";
import OverLayerElement from "./layerSwitcher/OverLayerElement";
import BaseLayerRadioGroup from "./layerSwitcher/BaseLayerRadioGroup";
import OverLayerGroup from "./layerSwitcher/OverLayerGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Spinner from "./Spinner";
import AddressAutocomplete from "../../utils/AddressAutocomplete";
import Box from "@material-ui/core/Box";
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from "@material-ui/core/List";
// import CircleIcon from '@material-ui/icons/Circle';

import ShowChartIcon from '@material-ui/icons/ShowChart';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import AdjustIcon from '@material-ui/icons/Adjust';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import RoomIcon from "@material-ui/icons/Room";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PoiManager from "./poiManager/PoiManager";
import MeasureTools from "./MeasureTools";
import IsochroneToolbar from "./isochonesToolbar/IsochronesToolbar";
import RouteToolbar from "./RouteToolbar/RouteToolbar";
import MeasureTools2 from "./MeasureTools2";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({

    switcher: {
        float: "left",
        position: "flex",
        zIndex: "1299",
        // left: "80px",
        // top: "80px",
        pointerEvents: "all",

    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "relative",
        // position: "fixed",
        float: "left",
        // right: "2px",
        // top: "50px",
        zIndex: "1299",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4,
        pointerEvents: "all",
        alignSelf:"flex-end"
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function SpatialTools() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);
    const [checked4, setChecked4] = React.useState(false);


    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleChange2 = () => {
        setChecked2((prev) => !prev);
    };

    const handleChange3 = () => {
        setChecked3((prev) => !prev);
    };
    const handleChange4 = () => {
        setChecked4((prev) => !prev);
    };
    let {} = useContext(AnalysisContext)

    return (
        <>
            <div className={classes.switcher}>
                <Tooltip title="Izochrony">
                <IconButton
                    onClick={handleChange2} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <DirectionsCarIcon/>
                </IconButton>
                </Tooltip>
                <Tooltip title="Trasowanie">
                <IconButton onClick={handleChange4} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <TrendingUpIcon/>
                </IconButton>
                </Tooltip>
                <Tooltip title="POI użytkownika">
                <IconButton onClick={handleChange3} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <RoomIcon/>
                </IconButton>
                </Tooltip>
                <MeasureTools2/>
            </div>

            <div>
                {checked &&
                <Grow
                    in={checked}
                    style={{transformOrigin: "999 999 0"}}
                    {...(checked ? {timeout: 1000} : {})}
                >
                    <div elevation={4} className={classes.paper}>
                    </div>
                </Grow>
                }

                 {checked2 &&
                <Grow
                    in={checked2}
                    style={{transformOrigin: "999 999 0"}}
                    {...(checked2 ? {timeout: 1000} : {})}
                >
                    <div elevation={4} className={classes.paper}>
                        {/*<Paper>*/}
                            <IsochroneToolbar/>
                        {/*</Paper>*/}
                    </div>
                </Grow>
                }
                {checked4 &&
                <Grow
                    in={checked4}
                    style={{transformOrigin: "999 999 0"}}
                    {...(checked4 ? {timeout: 1000} : {})}
                >
                    <div elevation={4} className={classes.paper}>
                        <RouteToolbar/>
                        {/*</Paper>*/}
                    </div>
                </Grow>
                }
                {checked3 &&
                <Grow
                    in={checked3}
                    style={{transformOrigin: "999 999 0"}}
                    {...(checked3 ? {timeout: 1000} : {})}
                >
                    <div elevation={4} className={classes.paper}>
                        <PoiManager/>
                    </div>
                </Grow>
                }


            </div>
        </>
    );
}

export default SpatialTools
