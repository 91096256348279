import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import {AnalysisContext} from "../../context/AnalysisContext";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    ratingSelected: {
        color: theme.palette.primary.main
    }
}));

export default function HoverRating(props) {
    const [value, setValue] = React.useState(props.value ? props.value : null);
    const [hover, setHover] = React.useState(-1);
    const [readOnly, setReadOnly] = React.useState(props.readOnly);
    const classes = useStyles();

    const labels = {
        1: 'mało ważne',
        2: 'ważne',
        3: 'bardzo ważne',
    };

    const {
        indicatorsList, setNewIndicatorsList, updateIndicatorsList, removeFromIndicatorsList
    } = useContext(AnalysisContext)

    function handleRemoveIndicator(idx) {
        removeFromIndicatorsList(idx)
        props.setDisable()
    }

    function handleUpdateIndicator(idx, item) {
        updateIndicatorsList(idx, item)
    }

    function handleNewIndicatorsList(values) {
        setNewIndicatorsList(values)
        props.setActive()
    }

    return (
        <>
            <Tooltip title={labels[hover !== -1 ? hover : value]}>
                <Rating
                    size="small"
                    readOnly={readOnly}
                    name={props.name}
                    value={value}
                    precision={1}
                    max={3}
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            setValue(newValue);
                            indicatorsList.forEach((element, index) => {
                                if (element.id === props.indicatorId) {
                                    handleRemoveIndicator(index)
                                }
                            })
                        } else {
                            setValue(newValue);
                            let indicatorValues = {"id": props.indicatorId, "importance": newValue}
                            handleNewIndicatorsList(indicatorValues)
                            indicatorsList.forEach((element, index) => {
                                if (element.id === props.indicatorId && newValue === element.importance) {
                                } else if (element.id === props.indicatorId && newValue !== element.importance) {
                                    handleUpdateIndicator(index, newValue)
                                }
                            })

                        }
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    emptyIcon={<FiberManualRecordOutlinedIcon fontSize="inherit"/>}
                    icon={<FiberManualRecordSharpIcon className={classes.ratingSelected} fontSize={"inherit"}/>}
                />
            </Tooltip>
        </>
    );
}
