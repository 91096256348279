import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {AnalysisContext} from "../../context/AnalysisContext";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

function Spinner(props) {

    let {spinnerMsg} = useContext(AnalysisContext)

    useEffect(()=>{
        console.log([...new Set(spinnerMsg)])
    },[spinnerMsg])

    return (
        <>
            {spinnerMsg.length !== 0  &&
                <Typography>
                    {spinnerMsg.length !== 0  &&
                    <CircularProgress />}
                    {  [...new Set(spinnerMsg)].toString()}

                </Typography>
            }
        </>
    );
}

export default Spinner


