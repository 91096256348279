/*jshint -W069 */
/**
 * Platforma Atrakcyjności Inwestycyjnej
 * @class Api
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
var Api = (function() {
    'use strict';

    var Q = require('q');
    var settingsInstance = require('./SettingsInstance.js').default;
    var apiKeyInstance = require('./ApiKeyInstance.js').default;
    var customRequest = require('./requestHelper.js').customRequest;
    var mergeQueryParams = require('./requestHelper.js').mergeQueryParams;
    var PAI_TOKEN = require("./constants").PAI_TOKEN;

    function Api(options) {
        var domain = (typeof options === 'object') ? options.domain : options;
        this.domain = domain ? domain : settingsInstance.baseApiUrl;
        if (this.domain.length === 0) {
            throw new Error('Domain parameter must be specified as a string.');
        }
        this.apiKey = (typeof options === 'object') ? (options.apiKey ? options.apiKey : {}) : {};
    }

    /**
     * HTTP Request
     * @method
     * @name Api#request
     * @param {string} method - http method
     * @param {string} path - path
     * @param {string} url
     * @param {object} body - body parameters / object
     * @param {object} headers - header parameters
     * @param {object} queryParameters - querystring parameters
     * @param {object} form - form data object
     * @param {object} deferred - promise object
     */
    Api.prototype.request = customRequest;

    /**
     * Set Api Key
     * @method
     * @name Api#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    Api.prototype.setApiKey = function(value, headerOrQueryName, isQuery) {
        apiKeyInstance.setApiKey(value, headerOrQueryName, isQuery)
    };
    /**
     * Set Auth headers
     * @method
     * @name Api#setAuthHeaders
     * @param {object} headerParams - headers object
     */
    Api.prototype.setAuthHeaders = function(headerParams) {
        var headers = headerParams ? headerParams : {};
        if (localStorage.getItem(PAI_TOKEN)) {
            headers["Authorization"] = localStorage.getItem(PAI_TOKEN);
        }
        return headers;
    };

    /**
     * Utworzenie i wykonanie nowej analizy lokalnej
     * @method
     * @name Api#postLocalanalysis
     * @param {object} parameters - method options and parameters
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postLocalanalysis = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        if (parameters['body'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: body'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Lista analiz lokalnych dla użytkownika
     * @method
     * @name Api#getLocalanalysis
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getLocalanalysis = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie analizy lokalnej o zadanym ID
     * @method
     * @name Api#getLocalanalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getLocalanalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Usuwanie analizy lokalnej o zadanym ID
     * @method
     * @name Api#deleteLocalanalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.deleteLocalanalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('DELETE', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Aktualizacja atrybutów analizy lokalnej o zadanym ID
     * @method
     * @name Api#patchLocalanalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.patchLocalanalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        if (parameters['body'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: body'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('PATCH', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Zapisywanie analizy lokalnej
     * @method
     * @name Api#postLocalanalysisByAnalysisIdSave
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postLocalanalysisByAnalysisIdSave = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}/save',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Generowanie raportu dla analizy lokalnej
     * @method
     * @name Api#postLocalanalysisByAnalysisIdReport
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postLocalanalysisByAnalysisIdReport = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}/report',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['file'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Lista raportów dla analizy lokalnej
     * @method
     * @name Api#getLocalanalysisByAnalysisIdReport
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy lokalnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getLocalanalysisByAnalysisIdReport = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/{analysis_id}/report',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie wygenerowanego wcześniej raportu
     * @method
     * @name Api#getLocalanalysisReportByReportId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.report_id - ID raportu
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getLocalanalysisReportByReportId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/localanalysis/report/{report_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['file'];

        parameterPath = parameterPath.replace('{report_id}', parameters['report_id']);

        if (parameters['report_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: report_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };

    return Api;
})();

exports.Api = Api;