import React, {useState, useEffect, useRef, useContext} from 'react';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import {AnalysisContext} from "../../../../../context/AnalysisContext";
import {apiIndicators} from "../../../../../service";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const PercentilesChart = (props) => {
    let {hexPercentilesInfo} = useContext(AnalysisContext)

    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const [indicatorsData, setIndicatorsData] = useState(null);
    const [chartConfig, setChartConfig] = useState({
        type: "bar",
        data: {
            // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
            // labels: (hexPercentilesInfo) ? Object.keys(hexPercentilesInfo) : [],
            labels: props.indicators.map(el => {return el.indicator.legend_label}),
            datasets: [
                {
                    // label: "percentyle",
                    display: false,
                    // data: [12, 19, 3, 5, 2, 3],
                    // data: (hexPercentilesInfo)?Object.values(hexPercentilesInfo):[],
                    data: (hexPercentilesInfo) ? Object.values(hexPercentilesInfo) : [],
                    backgroundColor: [
                        "rgb(62,131,234)",
                    ],
                    borderColor: [
                        "rgb(26,115,231)",
                    ],
                    borderWidth: 1
                }
            ]
        },
        options: {
            legend: {
                display: false,
                position: 'bottom'
            },
            label:{
                display: false
            },
            labels: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        }
    });

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    useEffect(()=>{
        apiIndicators.getIndicators()
            .then(body=>{
                setIndicatorsData(body)
                // console.log(body)
            })
    },[])

    useEffect(() => {
        if (hexPercentilesInfo) {
            delete hexPercentilesInfo.id
            let percentilesWithLabels ={}
            for (const [key, value] of Object.entries(hexPercentilesInfo)) {
                const data = indicatorsData.filter(el => el.id == key)
                const label = data[0].legend_label
                console.log(`${label}: ${value}`);
                percentilesWithLabels[label] = value *100
            }
            updateDataset(0, hexPercentilesInfo);
            // updateDataset(0, percentilesWithLabels);
        }
    }, [hexPercentilesInfo, indicatorsData]);



    const updateDataset = (datasetIndex, newData) => {
        console.log(chartInstance.data.labels)
        console.log(newData)
        chartInstance.data.datasets[datasetIndex].data = newData;
        chartInstance.update();
    };



    return (
        <>
            <div>
                {/*{props.indicators && JSON.stringify(props.indicators.map(el => {*/}
                {/*    return el.indicator.legend_label*/}
                {/*}))}*/}
                {/*{hexPercentilesInfo && JSON.stringify(Object.values(hexPercentilesInfo))}*/}
                {/*{hexPercentilesInfo && JSON.stringify(Object.keys(hexPercentilesInfo))}*/}
            </div>
            <div>
                <canvas ref={chartContainer}/>
            </div>
        </>
    );
};

export default PercentilesChart;
