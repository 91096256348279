import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";

import {getColor} from "../../../../../utils/ExtService";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "../../../../../theme";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles({
    result: {
        fontSize: 12,
        fontStyle: "italic"
    },
});


function WaiResultChart(props) {

    // const StyledLinearProgress = withStyles({
    //     colorPrimary: {
    //         backgroundColor: "white"
    //     },
    //     barColorPrimary: {
    //         backgroundColor: getColor(props.value)
    //     }
    // })(LinearProgress);

    function LightenDarkenColor(col, amt) {
        var usePound = false;
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }


    const useStyles = makeStyles({
        root: {
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            height: 10,
            borderRadius: 15,
            // padding:8
        },
        colorPrimary: {
            backgroundColor: '#f0efef'
            // backgroundColor: LightenDarkenColor(getColor(props.value),80)
        },
        bar: {
            // borderRadius: 5,
            backgroundColor: getColor(props.value)
        }
    });


    const classes = useStyles();


    return (
        <React.Fragment>
            {/*<Container fixed>*/}

            {/*<Grid item*/}
            {/*      // rowSpacing={2}*/}
            {/*      // spacing={0}*/}
            {/*      xs={6} sm={6} md={6} lg={6} xl={6}*/}
            {/*>*/}
            {/*    <div*/}
            {/*        style={{*/}
            {/*            // padding: 1,*/}
            {/*            // padding: 5,*/}
            {/*            paddingBottom: 10*/}
            {/*        }*/}
            {/*        }*/}
            {/*    >*/}
            {/*        /!*    <div>*!/*/}
            {/*        /!*    <Typography>*!/*/}
            {/*        /!*    {"WAI"}*!/*/}
            {/*        <b> {"Wskaźnik Atrakcyjności Inwestycyjnej"}</b>*/}
            {/*        /!*</Typography>*!/*/}
            {/*    </div>*/}

            {/*</Grid>*/}
            {/*/!*<Grid item >*!/*/}
            {/*<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>*/}
            {/*    <div style={{*/}
            {/*        paddingTop: 10*/}
            {/*        // padding: 10*/}
            {/*    }}>*/}
                    <LinearProgress
                        classes={{
                            root: classes.root,
                            colorPrimary: classes.colorPrimary,
                            bar: classes.bar
                        }}

                        // color={theme.palette.primary}
                        variant="determinate"
                        // value={props.value * 100}/>
                        value={props.value * 100}/>

                    {/*{getColor(props.value)}*/}
                {/*</div>*/}
            {/*</Grid>*/}

            {/*<Grid*/}
            {/*    item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}*/}

            {/*>*/}
                {/*<Typography>{Math.round(props.value * 100)}</Typography>*/}
                {/*<center>{Math.round(props.value)}%</center>*/}
                {/*<center>{Math.round(props.value * 100)}%</center>*/}
                {/*    <center> {element.min_value} <b>{featureInfo[element.indicator.id]}</b> {element.max_value} {element.indicator.indicatorIsPositive}</center>*/}
            {/*</Grid>*/}
            {/*</Container>*/}
        </React.Fragment>
    )
}

export default WaiResultChart
