import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import HoverRating from "../../SimpleRating";
import Grid from "@material-ui/core/Grid";
import {Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
        gridDisabled: {
            color: 'darkgray'
        }
    }))
;
const DEFAULT_TOOLTIP = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent euismod velit est, nec aliquet mi sagittis et. Cras in massa sed dolor pulvinar facilisis ut eget tellus. Quisque ex justo, eleifend quis accumsan vel, semper in nulla. '

export default function IndicatorItem(props) {
    const classes = useStyles();
    const [indicator, setIndicator] = React.useState(props.indicator)
    const [disabled, setDisabled] = React.useState(true);
    const [readOnly, setReadOnly] = React.useState(props.readOnly);

    function handleSetActive() {
        if (disabled) {
            setDisabled(false)
        }
    }

    function handleSetDisable() {
        if (!disabled) {
            setDisabled(true)
        }
    }

    return (
        <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={1}>
            <Grid item xl={10} lg={10} md={8} sm={8} xs={12}
                  classes={{item: (disabled && !readOnly) ? classes.gridDisabled : ''}}>
                <Tooltip title={indicator.description ? indicator.description : DEFAULT_TOOLTIP}
                         placement={'top'}>
                    <Typography
                        style={{fontSize:"13px"}}
                    >{indicator.name}</Typography>
                </Tooltip>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                <HoverRating indicatorId={indicator.id}
                             name={indicator.name}
                             setActive={handleSetActive}
                             setDisable={handleSetDisable}
                             readOnly={readOnly}
                             value={indicator.weight}/>
            </Grid>
        </Grid>
    );
}
