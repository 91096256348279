import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AnalysisContext} from "../context/AnalysisContext";
import * as PropTypes from "prop-types";
import {ErrorContext, ErrorProvider} from "../context/ErrorContext";
import InfoSnackbar from "../components/snackbar/InfoSnackbar";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorFallback} from "./ErrorFallback";

AddressAutocomplete.propTypes = {
    addressHandler: PropTypes.func.isRequired
};

function AddressAutocomplete(props) {
    let {addressPoint, setAddressPoint} = useContext(AnalysisContext);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [value, setValue] = React.useState();
    let {setInfo} = useContext(ErrorContext);


    function handleInput(e) {
        const name = e.target.name;
        const newValue = e.target.value;
        let active = true;
        setValue(newValue)
        // setFormInput({[name]: newValue})
        if (newValue?.length >= 2) {
            (async () => {
                console.log(newValue)
                // const response = await fetch('https://restcountries.eu/rest/v2/all');
                const response = await fetch('https://capap.gugik.gov.pl/api/fts/ref/ac?b=' + newValue.replace(/ /g, '+') + '&a=&cnt=10&idx=pkt%2Culc%2Cdze%2Cjpa%2Cprngof%2Cprngjo&bbox=12.352898050592607%2C47.92398449401291%2C26.877016125075844%2C55.62092862180286&bboxm=soft');

                // https://capap.gugik.gov.pl/api/fts/ref/qn?f=geojson&q=Płock Wyszogrodzka 120&offs=0&cnt=50&idx=pkt,dze&bbox=12.463687823377185,48.168660258540704,26.623218868250124,55.875648878072425&bboxm=soft&tag=&top=or&useExtSvc=true
                console.log(response)
                const hint = await response.json();
                setOpen(true)
                // console.log(hint, "hint", hint.length)
                if (active) {
                    // console.log(countries.map((option) => option))
                    if (hint.length > 0) {
                        setOptions(hint.map((option) => option))
                    }
                    // }
                    // setOptions(countries)
                    // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
                }

                if (hint.length === 0) {
                    setOpen(false)
                }

            })();

            return () => {
                active = false;
            };
        }

    }

    // React.useEffect(() => {
    //     let active = true;
    //
    //     if (!loading) {
    //         return undefined;
    //     }
    //
    //     (async () => {
    //         const response = await fetch('https://restcountries.eu/rest/v2/all');
    //         await sleep(1e3); // For demo purposes.
    //         const countries = await response.json();
    //
    //         if (active) {
    //             console.log()
    //               setOptions(Object.keys(countries).map((key) => countries[key]))
    //             // setOptions(Object.keys(countries).map((key) => countries[key].name));
    //         }
    //     })();
    //
    //     return () => {
    //         active = false;
    //     };
    // }, [loading]);

    React.useEffect(() => {
        // if (!open) {
        //     // setOptions([]);
        // }
    }, [open]);

    function handleKey(e) {
        if (e.key === 'Enter') {
            console.log('Enter key pressed', e.target.value);
            // https://capap.gugik.gov.pl/api/fts/ref/qn?f=geojson&q=Zgierz+Konstantynowska+1&offs=0&cnt=50&idx=pkt%2Cdze&bbox=12.463687823377185%2C48.168660258540704%2C26.623218868250124%2C55.875648878072425&bboxm=soft&tag=&top=or&useExtSvc=true
            // write your functionality here
            (async () => {
                const response = await fetch('https://capap.gugik.gov.pl/api/fts/ref/qn?f=geojson&q=' + e.target.value.replace(/ /g, '+') + '&offs=0&cnt=50&idx=pkt&bbox=12.463687823377185%2C48.168660258540704%2C26.623218868250124%2C55.875648878072425&bboxm=soft&tag=&top=or&useExtSvc=true');
                const address = await response.json();
                setOptions([e.target.value])
                if (address.features[0] !== undefined) {
                    console.log(address.features[0].length, address.features[0]?.geometry.coordinates)
                    props.addressHandler(address.features[0]?.geometry.coordinates)
                    setOpen(false)
                } else {
                    setInfo({message: "Błędny adres", type: 'error'})
                }

            })();
        }
    }


    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <ErrorProvider>
                <Autocomplete
                    handleHomeEndKeys
                    freeSolo
                    id="asynchronous-demo"
                    // style={{width: 300}}
                    open={open}
                    // onOpen={() => {
                    //     setOpen(true);
                    // }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onInputChange={handleInput}
                    onKeyPress={handleKey}

                    // getOptionSelected={(option, value) => option.name === value.name}
                    // getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option}

                    options={options}
                    loading={loading}
                    noOptionsText='Brak podpowiedzi...'
                    loadingText='Szukam...'
                    openText='Wprowadź adres'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={e => {
                                if (e.target.value !== "" || e.target.value !== null) {
                                    handleInput(e)
                                }
                            }}
                            label="Wyszukaj Adres"
                            variant={props.variant}
                            size={"small"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <InfoSnackbar/>
            </ErrorProvider>
        </ErrorBoundary>
    );
}

export default AddressAutocomplete
