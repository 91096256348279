import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IndicatorList from "../indicator/IndicatorList";


export default function CategoryItem(props) {
    const [category, setCategory] = React.useState(props.category)
    const [expanded, setExpanded] = React.useState(true);

    // console.log(category)

    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    return (
        // <Accordion
        //     expanded={expanded}
        //     onChange={handleChange}
        //     elevation={1}>
        //     <AccordionSummary
        //         expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
        //         aria-controls="panel1bh-content"
        //         id="panel1bh-header"
        //     >
        //         <Typography variant={"h4"}>{category.name}</Typography>
        //     </AccordionSummary>
        //     <AccordionDetails>
                <IndicatorList indicators={category.indicators}/>
        //     </AccordionDetails>
        // </Accordion>
    );
}
