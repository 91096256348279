class SettingsInstance {
    /**
     * @type string
     * @description Url for base frontend app
     */
    baseUrl;
    /**
     * @type string
     * @description Url for core api app
     */
    baseApiUrl;
    /**
     * @type string
     * @description Url for api-external app
     */
    baseExternalApiUrl;

    /**
     * REACT_APP_BUILD_VERSION on of [dev, master, stage]
     */

    geoserverUrl

    constructor() {
        if (!SettingsInstance.instance) {
            SettingsInstance.instance = this;
        }
        if (process.env.NODE_ENV !== 'production') {
            this.baseUrl = "http://localhost";
            this.baseApiUrl = "https://pai.epp-services.pl/api/v1";
            // this.baseApiUrl = "http://localhost/api/v1";
            this.geoserverUrl = "http://pai.epp-services.pl/api/v1/apis/geoserver";
        } else {
            if (process.env.REACT_APP_BUILD_VERSION === 'master') {
                this.baseUrl = "https://pai.epp-services.pl";
            } else if (process.env.REACT_APP_BUILD_VERSION === 'dev') {
                this.baseUrl = "https://dev.pai.epp-services.pl";
            } else if (process.env.REACT_APP_BUILD_VERSION === 'development') {
                this.baseUrl = "http://localhost";
            } else {
                this.baseUrl = "https://dev.pai.epp-services.pl";
            }
            this.baseApiUrl = this.baseUrl + "/api/v1";
            this.baseExternalApiUrl = this.baseUrl + "/api/v1/apis";
            this.geoserverUrl = this.baseExternalApiUrl + "/geoserver";
        }
        this.dateTimeFormat = "DD-MM-YYYY HH:mm";
        this.dateFormat = "DD-MM-YYYY";
        this.timeFormat = "HH:mm";
    }
}


const instance = new SettingsInstance();
export default instance;
