import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function IconLegend(props) {
    const classes = useStyles();

    return (<>
         <Avatar
             style={{backgroundColor: props.color}}
             className={classes.small}>
         {/*    {String(props.icon).replace('md-10', 'md-12')}*/}
         {/*    {String(props.icon)}*/}
            {Parser(String(props.icon).replace('md-10', 'md-12'))}
        </Avatar>
        </>
    );
}
