import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import "./../_assets/css/ManualPage.css";
import {getVersionInfo} from "../utils/version";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: '75%',
        right: '20%',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        width:200,
        padding: '0 30px',
    },
    loginButton: {
        position: 'absolute',
        top: '85%',
        right: '20%',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        width:200,
        padding: '0 30px',
    }
}));

export default function ManualPage() {
    const classes = useStyles();

    return (
        <div className={'manualPage'}>
            <Link to={`/analyze/`}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.closeButton}>
                    <b>Wypróbuj analizę</b>
                </Button>
            </Link>
            <Link to={`/auth/`}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.loginButton}>
                    <b>Zaloguj się</b>
                </Button>
            </Link>
            <Typography align={"right"}> {getVersionInfo()}</Typography>
        </div>
    );
}
