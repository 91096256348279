import React from 'react';
import CategoryItem from "./CategoryItem";
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);
export default function CategoryList(props) {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            {props.categories.map(category =>
                <Accordion
                    square
                    expanded={expanded === category.label}
                    onChange={handleChange(category.label)}
                    // onMouseMove={handleChange(category.label)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                        aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{category.label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CategoryItem category={category}/>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
}

// export default function CategoryList(props) {
//     return (
//         <>
//             {props.categories.map(category =>
//                 <CategoryItem category={category}/>
//             )}
//         </>
//     );
// }
