import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import {AnalysisContext} from "../../../context/AnalysisContext";
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import {Slider, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Grid from "@material-ui/core/Grid";
import AddLocationIcon from '@material-ui/icons/AddLocation';
import CancelIcon from '@material-ui/icons/Cancel';
import {ToolContext} from "../../../context/ToolContext";

const useStyles = makeStyles((theme) => ({

    // container: {
    //     height: 180,
    //     width: 400,
    //     display: "block",
    //     position: "absolute",
    //     zIndex: "990",
    //     right: "0px",
    //     top: "160px",
    // },
    switcher: {
        float: "left",
        position: "flex",
        zIndex: "1299",
        left: "0px",
        pointerEvents: "all",
        // top: "120px",
    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "relative",
        right: "2px",
        // top: "260px",
        zIndex: "990",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4,
        pointerEvents: "all",

    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function IsochroneToolbar() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);
    const [view, setView] = React.useState(null);
    const [selected, setSelected] = React.useState(null)

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    let {
        isochroneStart, setIsochroneStart,
        isoTime, setIsoTime,
        isoTransportType, setIsoTransportType,
        addIsoPoint, setAddIsoPoint,
        generateIso, setGenerateIso,
        removeIso, setRemoveIso,
    } = useContext(ToolContext)


    const handleChangeTransportType = (event, nextView) => {
        setView(nextView);
        setSelected(nextView)
        console.log(nextView)
        setIsoTransportType(nextView)
    };


    const handleChangeTime = (event, newValue) => {
        let minutes = newValue * 60
        setIsoTime(minutes)
    };

    // const clear = () => {
    //     handleChange()
    // }

    const addNewIsoPoint = () => {
        setAddIsoPoint(true)
    }

    const genere = () => {
        setAddIsoPoint(true)
    }


    return (
        <>
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                {/*<div elevation={4} className={classes.paper}>*/}
                {/*<Box*/}
                {/*    width={"200px"}*/}
                {/*    position="absolute"*/}
                {/*    // top="21%"*/}
                {/*    left="1%"*/}
                {/*    zIndex="999"*/}

                {/*>*/}
                    <Paper
                        elevation={3}
                        style={{padding: "15px"}}
                    >
                        <Typography

                            // variant={"h1"}
                        >Strefy dojazdu</Typography>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Button
                                size={"small"}
                                onClick={addNewIsoPoint}
                            ><AddLocationIcon/>Dodaj Punkt</Button>
                        </div>

                        {isochroneStart !== null &&
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <ToggleButtonGroup
                                    // orientation="vertical"
                                    // justifyContent="center"
                                    value={view}
                                    size={"small"}
                                    exclusive
                                    onChange={handleChangeTransportType}
                                >
                                    <ToggleButton
                                        value={"pedestrian"} aria-label={"pieszy"}>
                                        {"pieszy"}
                                    </ToggleButton>
                                    <ToggleButton
                                        value={"car"} aria-label={"auto"}>
                                        {"auto"}
                                    </ToggleButton>

                                </ToggleButtonGroup>
                            </div>
                            {view !== null &&
                            <>
                                <div style={{
                                    paddingTop: "45px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px"
                                }}>
                                    <Slider

                                        defaultValue={() => {
                                            setIsoTime(10 * 60)
                                            return 10
                                        }}
                                        // getAriaValue=>Text={`${value} minut`}
                                        // aria-labelledby="discrete-slider"
                                        onChange={handleChangeTime}
                                        valueLabelDisplay="on"
                                        // valueLabelFormat={(value)=>(`${value}`)}
                                        step={10}
                                        marks={[
                                            {
                                                value: 10,
                                                label: "10 min"
                                            },
                                            {
                                                value: 60,
                                                label: "60 min"
                                            }
                                        ]}
                                        min={10}
                                        max={60}
                                    />
                                </div>
                            </>
                            }

                        </>
                        }
                        <div
                            style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end"
                            }}
                        >
                            {view !== null &&
                            <>
                                <Button
                                    size={"small"}
                                    onClick={() => {
                                        setGenerateIso(true)
                                    }}
                                >Generuj</Button>
                                <Button
                                    onClick={() => {
                                        setIsochroneStart(null)
                                        // setIsoTime(10*60)
                                        setView(null)
                                        setRemoveIso(true)
                                    }}
                                    size={"small"}
                                >Usuń</Button>
                            </>}
                            {/*<IconButton*/}
                            {/*    size={"small"}*/}
                            {/*    onClick={handleChange}*/}
                            {/*><CancelIcon/>*/}
                            {/*</IconButton>*/}
                        </div>
                    </Paper>
                {/*</Box>*/}
                {/*</div>*/}
            </Grow>
        </>
    );
}

export default IsochroneToolbar
