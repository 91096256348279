// import React, {useContext} from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import MyAnalyses from "./myanalyses/MyAnalysesListMUIT";
// import MyLocalAnalyses from "./mylocalanalysis/MyLocalAnalysis";
// import {ErrorContext} from "../context/ErrorContext";
// import InfoSnackbar from "./snackbar/InfoSnackbar";
//
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`scrollable-auto-tabpanel-${index}`}
//             aria-labelledby={`scrollable-auto-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 // <>
//                 // <Box p={3}>
//                 <>{children}</>
//                     // <Typography>{children}</Typography>
//                 // </Box>
//             )}
//         </div>
//     );
// }
//
// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };
//
// function a11yProps(index) {
//     return {
//         id: `scrollable-auto-tab-${index}`,
//         'aria-controls': `scrollable-auto-tabpanel-${index}`,
//     };
// }
//
// // const useStyles = makeStyles((theme) => ({
// //     root: {
// //         flexGrow: 1,
// //         width: '100%',
// //         backgroundColor: theme.palette.background.paper,
// //     },
// // }));
//
// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//     },
//     tab: {
//         '& .MuiBox-root': {
//             padding: '0px',
//         },
//     },
//
// }));
// export default function Archive() {
//     const classes = useStyles();
//     const [value, setValue] = React.useState(0);
//     let {setInfo} = useContext(ErrorContext);
//
//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//         // setInfo(newValue)
//         console.log(newValue)
//     };
//
//     return (
//         <div className={classes.tab}>
//             <AppBar position="static" color="default">
//                 <Tabs
//                     value={value}
//                     onChange={handleChange}
//                     indicatorColor="primary"
//                     textColor="primary"
//                     variant="scrollable"
//                     scrollButtons="auto"
//                     aria-label="scrollable auto tabs example"
//                     centered
//                 >
//                     <Tab label="Analizy obszarowe (WAI)" {...a11yProps(0)} />
//                     <Tab label="Analizy Lokalne" {...a11yProps(1)} />
//                 </Tabs>
//             </AppBar>
//             <TabPanel value={value} index={0}>
//                 <MyAnalyses/>
//             </TabPanel>
//             <TabPanel value={value} index={1}>
//                 <MyLocalAnalyses/>
//             </TabPanel>
//             <InfoSnackbar/>
//         </div>
//     );
// }


import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {apiAnalysis} from "../service";
import MyAnalysisList from "./myanalyses/MyAnalysesList";
import MyAnalyses from "./myanalyses/MyAnalysesListMUIT";
import MyLocalAnalyses from "./mylocalanalysis/MyLocalAnalysis";
import InfoSnackbar from "./snackbar/InfoSnackbar";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },

}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [analysisList, setAnalysisList] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     apiAnalysis.getAnalysisList({elements:500})
    //         .then(value => {
    //             setAnalysisList(value)
    //         })
    //         .catch(reason => {
    //             console.log(reason)
    //         })
    // }, [])

    return (
        <div className={classes.tab}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="scrollable"
                    // scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    // textColor="primary"
                    centered

                >
                    <Tab label="Analizy Obszarowe (WAI)" {...a11yProps(0)} />
                    <Tab label="Analizy Lokalne" {...a11yProps(1)} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <MyAnalyses/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyLocalAnalyses/>
            </TabPanel>
            <InfoSnackbar/>
        </div>
    );
}
