import React, {createContext, useState} from 'react';

export const ErrorContext = createContext();

export const initialErrorContext = {message: null, type: null, duration: 4000}

export const ErrorProvider = props => {
    const [info, setInfo] = useState(initialErrorContext);

    return (
        <ErrorContext.Provider
            value={{info, setInfo}}>
            {props.children}
        </ErrorContext.Provider>
    )
};
