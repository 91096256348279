/*jshint -W069 */
/**
 * Platforma Atrakcyjności Inwestycyjnej
 * @class Api
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
var Api = (function() {
    'use strict';

    var Q = require('q');
    var settingsInstance = require('./SettingsInstance.js').default;
    var apiKeyInstance = require('./ApiKeyInstance.js').default;
    var customRequest = require('./requestHelper.js').customRequest;
    var mergeQueryParams = require('./requestHelper.js').mergeQueryParams;
    var PAI_TOKEN = require("./constants").PAI_TOKEN;

    function Api(options) {
        var domain = (typeof options === 'object') ? options.domain : options;
        this.domain = domain ? domain : settingsInstance.baseApiUrl;
        if (this.domain.length === 0) {
            throw new Error('Domain parameter must be specified as a string.');
        }
        this.apiKey = (typeof options === 'object') ? (options.apiKey ? options.apiKey : {}) : {};
    }

    /**
     * HTTP Request
     * @method
     * @name Api#request
     * @param {string} method - http method
     * @param {string} path - path
     * @param {string} url
     * @param {object} body - body parameters / object
     * @param {object} headers - header parameters
     * @param {object} queryParameters - querystring parameters
     * @param {object} form - form data object
     * @param {object} deferred - promise object
     */
    Api.prototype.request = customRequest;

    /**
     * Set Api Key
     * @method
     * @name Api#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    Api.prototype.setApiKey = function(value, headerOrQueryName, isQuery) {
        apiKeyInstance.setApiKey(value, headerOrQueryName, isQuery)
    };
    /**
     * Set Auth headers
     * @method
     * @name Api#setAuthHeaders
     * @param {object} headerParams - headers object
     */
    Api.prototype.setAuthHeaders = function(headerParams) {
        var headers = headerParams ? headerParams : {};
        if (localStorage.getItem(PAI_TOKEN)) {
            headers["Authorization"] = localStorage.getItem(PAI_TOKEN);
        }
        return headers;
    };

    /**
     * Utworzenie nowej analizy dla użytkownika.
     * @method
     * @name Api#putAnalysis
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.putAnalysis = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('PUT', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie listy analiz
     * @method
     * @name Api#getAnalysisList
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.from - Pierwsza strona z zakresu do pobrania (domyślne filtrowanie wg najnowszych elementów)
     * @param {integer} parameters.elements - Liczba rekordów na stronę
     * @param {integer} parameters.ids - Lista id użytkowników: wymagana lista oddzielona przecinkami
     * @param {string} parameters.user_ids - Lista id analiz: wymagana lista liczb oddzielona przecinkami
     * @param {string} parameters.name - Nazwa analiz
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisList = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/list',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        /** set default value **/
        queryParameters['from'] = '1';

        if (parameters['from'] !== undefined) {
            queryParameters['from'] = parameters['from'];
        }

        /** set default value **/
        queryParameters['elements'] = '10';

        if (parameters['elements'] !== undefined) {
            queryParameters['elements'] = parameters['elements'];
        }

        if (parameters['ids'] !== undefined) {
            queryParameters['ids'] = parameters['ids'];
        }

        if (parameters['user_ids'] !== undefined) {
            queryParameters['user_ids'] = parameters['user_ids'];
        }

        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Aktualizowanie analizy o zadanym ID.
     * @method
     * @name Api#deleteAnalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.deleteAnalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('DELETE', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie obiektu analizy o zadanym ID.
     * @method
     * @name Api#getAnalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Aktualizowanie analizy o zadanym ID.
     * @method
     * @name Api#postAnalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postAnalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        if (parameters['body'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: body'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Wykonanie analizy o zadanym ID.
     * @method
     * @name Api#putAnalysisByAnalysisId
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.putAnalysisByAnalysisId = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/pbf'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('PUT', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Zapisywanie wyniku analizy o danym ID.
     * @method
     * @name Api#postAnalysisByAnalysisIdSave
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postAnalysisByAnalysisIdSave = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/save',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Edycja analizy o danym ID.
     * @method
     * @name Api#postAnalysisByAnalysisIdEdit
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     * @param {} parameters.body - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.postAnalysisByAnalysisIdEdit = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/edit',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        if (parameters['body'] !== undefined) {
            body = parameters['body'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('POST', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie AOI dla analizy o ID.
     * @method
     * @name Api#getAnalysisByAnalysisIdAoi
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdAoi = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/aoi',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Zwraca linie otaczające najlepsze n% heksagonów o najwyższym WAI dla danej analizy
     * @method
     * @name Api#getAnalysisByAnalysisIdBest
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {integer} parameters.percentage - Określa jaki procent obszarów o najwyższym WAI zwrócić (np. top 5% wai)
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdBest = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/best',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        queryParameters['percentage'] = '5';

        if (parameters['percentage'] !== undefined) {
            queryParameters['percentage'] = parameters['percentage'];
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie listy kategorii wskaźników wybranych do analizy o zadanym ID
     * @method
     * @name Api#getAnalysisByAnalysisIdCategories
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdCategories = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/categories',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie wartości składowych wykorzystanych w danej analizie, dla danego oczka siatki
     * @method
     * @name Api#getAnalysisByAnalysisIdComponentvalues
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {integer} parameters.hexId - Id oczka siatki, dla którego mają być pobrane wartości składowych
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdComponentvalues = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/componentvalues',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        if (parameters['hexId'] !== undefined) {
            queryParameters['hexId'] = parameters['hexId'];
        }

        if (parameters['hexId'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: hexId'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie listy kategorii, wskaźników i składowych dla analizy o danym ID
     * @method
     * @name Api#getAnalysisByAnalysisIdFeatureinfo
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdFeatureinfo = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/featureinfo',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie geometrii analizy o zadanym ID
     * @method
     * @name Api#getAnalysisByAnalysisIdGeom
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {integer} parameters.crs_id - CRS ID układu współrzędnych dla zwracanej geometrii
     * @param {string} parameters.format - Format zwracanej geometrii. Opcje to wkt i json.
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdGeom = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/geom',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        queryParameters['crs_id'] = '4326';

        if (parameters['crs_id'] !== undefined) {
            queryParameters['crs_id'] = parameters['crs_id'];
        }

        /** set default value **/
        queryParameters['format'] = 'wkt';

        if (parameters['format'] !== undefined) {
            queryParameters['format'] = parameters['format'];
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie wskaźników wykorzystanych w analizie o wskazanym ID
     * @method
     * @name Api#getAnalysisByAnalysisIdIndicators
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdIndicators = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/indicators',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie wartości centylowych wskaźników wykorzystanych w danej analizie, dla danego oczka siatki
     * @method
     * @name Api#getAnalysisByAnalysisIdPercentiles
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {integer} parameters.hexId - Id oczka siatki, dla którego mają być pobrane centyle dla wskaźników
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdPercentiles = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/percentiles',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        if (parameters['hexId'] !== undefined) {
            queryParameters['hexId'] = parameters['hexId'];
        }

        if (parameters['hexId'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: hexId'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };
    /**
     * Pobieranie wyniku analizy o zadanym ID.
     * @method
     * @name Api#getAnalysisByAnalysisIdResult
     * @param {object} parameters - method options and parameters
     * @param {integer} parameters.analysis_id - ID analizy
     * @param {string} parameters.credentials - Platforma Atrakcyjności Inwestycyjnej
     */
    Api.prototype.getAnalysisByAnalysisIdResult = function(parameters, options) {
        if (parameters === undefined) {
            parameters = {};
        }
        var deferred = Q.defer();
        var domain = this.domain,
            path = '/analysis/{analysis_id}/result',
            parameterPath = path;
        var body = {},
            queryParameters = {},
            headers = {},
            form = {},
            files = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/pbf'];

        parameterPath = parameterPath.replace('{analysis_id}', parameters['analysis_id']);

        if (parameters['analysis_id'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: analysis_id'));
            return deferred.promise;
        }

        /** set default value **/
        parameters['credentials'] = 'include';
        if (parameters['credentials'] !== undefined) {
            headers['credentials'] = parameters['credentials'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request('GET', path, domain + parameterPath, body, headers, queryParameters, form, deferred, files, options);

        return deferred.promise;
    };

    return Api;
})();

exports.Api = Api;