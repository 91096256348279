import {createMuiTheme} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import {plPL} from "@material-ui/core/locale";


const theme = createMuiTheme(
    {
    overrides: {
        // Style sheet name ⚛️
        MuiLink: {
            // Name of the rule
            underline: 'none'
        },
        MuiStepLabel: {
            root: {
                fontWeight: 900,
            }
        },
        MuiCircularProgress: {
            root: {
                color: "#1a73e8",
            },
        },
        MuiTooltip: {
            tooltip: {
                maxWidth: 300,
                fontSize: '14px'
            }
        },
        MuiFormControl: {
            root: {
                // margin: '8px',
                minWidth: 200,
            },
        },
        MuiGrid: {
            item: {
                textAlign: 'left'
            },
            root: {
                textAlign: 'left'
            },
        },
        MuiTypography: {
            root: {
                fontSize: '0.9rem',
                textAlign: 'left',
                minWidth: '110px',
            },
            h1: {
                fontSize: '1.5rem',
                textAlign: 'left',
            },
            h2: {
                fontSize: '1.3rem',
                textAlign: 'left',
            },
            h3: {
                fontSize: '1.1rem',
                fontWeight: 'bold',
                textAlign: 'left',
            },
            h4: {
                fontSize: '1.05rem',
                textAlign: 'left',
            },
            body1: {
                fontSize: '1rem',
            },
        },
        MuiButton: {
            root: {
                color: "#1a73e8",
            }
        }
    },
    palette: {
        primary: {
            // main: green[600],
            // main: "#1a73e8",
            main: "#1a73e8",
        },
        secondary: {
            main: green[500],
        },
    },
    // mixins:{
    //     toolbar:{
    //
    //     }
    // },
    typography: {
        fontFamily: ['"Titillium Web"']

    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },

},plPL);

export default theme;
