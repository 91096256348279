import React, {useContext, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AdjustIcon from "@material-ui/icons/Adjust";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import {AnalysisContext} from "../../context/AnalysisContext";
import {ToolContext} from "../../context/ToolContext";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({

    // container: {
    //     height: 180,
    //     width: 400,
    //     display: "block",
    //     position: "absolute",
    //     // zIndex: "990",
    //     zIndex: "1299",
    //     right: "0px",
    //     top: "160px",
    // },
    switcher: {
        // float: "left",
        // position: "flex",
        zIndex: "1299",
        // left: "80px",
        // top: "80px",
        pointerEvents: "all",

    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "relative",
        right: "2px",
        // top: "260px",
        zIndex: "1299",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4,
        pointerEvents: "all",
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));


export default function LongMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let {
        activeMeasureTool, setActiveMeasureTool,
        measureLine, setMeasureLine,
        measurePolyline, setMeasurePolygon,
        measurePoint, setMeasurePoint,
        clearMeasures, setClearMeasures
    } = useContext(ToolContext)


    const startMeasureLine = () => {
        setMeasureLine(true)
        setActiveMeasureTool(true)
        handleClose()
    }

    const startMeasurePolygon = () => {
        setMeasurePolygon(true)
        setActiveMeasureTool(true)
        handleClose()
    }
    const startMeasurePoint = () => {
        setMeasurePoint(true)
        setActiveMeasureTool(true)
        handleClose()
    }

    const clear = () => {
        setClearMeasures(true)
        setActiveMeasureTool(true)
        handleClose()
    }

    return (
        <>
            <Tooltip title="Pomiary na mapie">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    disabled={activeMeasureTool}
                    // disabled={true}
                    onClick={handleClick}
                    variant="contained" size="small" color="primary"
                    className={classes.button}
                >
                    <MultilineChartIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                // PaperProps={{
                //     style: {
                //         maxHeight: ITEM_HEIGHT * 4.5,
                //         width: '20ch',
                //     },
                // }}
            >
                <MenuItem onClick={startMeasureLine}>
                    <ListItemIcon>
                        <ShowChartIcon/>
                    </ListItemIcon>
                    <ListItemText>Pomiar odległości</ListItemText>
                </MenuItem>
                <MenuItem onClick={startMeasurePolygon}>
                    <ListItemIcon>
                        <CropLandscapeIcon/>
                    </ListItemIcon>
                    <ListItemText>Pomiar powierzchni</ListItemText>
                </MenuItem>
                <MenuItem onClick={startMeasurePoint}>
                    <ListItemIcon>
                        <AdjustIcon/>
                    </ListItemIcon>
                    <ListItemText>Pomiar wysokości</ListItemText>
                </MenuItem>
                <MenuItem onClick={clear}>
                    <ListItemIcon>
                        <DeleteForeverIcon/>
                    </ListItemIcon>
                    <ListItemText>Wyczyść pomiary</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
