import React, {useContext, useEffect} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
// import {AnalysisContext} from "../../context/AnalysisContext";
import {ToolContext} from "../../../context/ToolContext";
import {makeStyles} from "@material-ui/core/styles";
import {apiIndicators} from "../../../service";

const useStyles = makeStyles((theme) => ({

    labelActive: {
        // fontWeight: 'Bold'
        fontSize: 12,
        color: 'Black'
    },
    labelInactive: {
        fontSize: 12,
        fontWeight: 'Regular',
        color: 'darkgray'
    },
}));

function BaseLayerRadioGroup() {

    const classes = useStyles();


    // const [layerList, setLayerList] = React.useState([
    //     // {
    //     //     "type": "tms",
    //     //     "url": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //     //     "label": "OSM",
    //     //     "options": {
    //     //         "layerName": "osm_hot",
    //     //         "attribution": "test",
    //     //         "maxZom": 21,
    //     //         "baseLayer": true
    //     //     }
    //     // },
    //
    //
    //     {
    //         "type": "tmsFilter",
    //         "url": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //         "label": "OSM",
    //         "options": {
    //             "layerName": "osm",
    //             "attribution": "test",
    //             "maxZom": 21,
    //             "filter": ['bright:102%', 'contrast:110%', 'hue:29deg', 'saturate:29%'],
    //             "baseLayer": true,
    //         }
    //     },
    //     {
    //         "type": "tmsFilter",
    //         "url": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //         "label": "OSM czarno-biały",
    //         "options": {
    //             "layerName": "osm_bw",
    //             "attribution": "test",
    //             "maxZom": 21,
    //             "filter": ['grayscale:100%', 'contrast:118%'],
    //             "baseLayer": true,
    //         }
    //     },
    //
    //     {
    //         "type": "tmsFilter",
    //         "url": "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png",
    //         "label": "CartoDB-Positron-nolabels",
    //         "options": {
    //             "subdomains": 'abcd',
    //             // "maxZoom": 19,
    //             "layerName": "CartoDB",
    //             "attribution": "test",
    //             "maxZom": 21,
    //             "filter": ['grayscale:100%'],
    //             "baseLayer": true,
    //         }
    //     },
    //
    //     // {
    //     //     "type": "tmsFilter",
    //     //     "url": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //     //     "label": "OSM czarno-biały",
    //     //     "options": {
    //     //         "layerName": "osm_bw",
    //     //         "attribution": "test",
    //     //         "maxZom": 21,
    //     //         "filter": ['grayscale:100%', 'contrast:118%'],
    //     //         baseLayer: false
    //     //     }
    //     // },
    //
    //     // {
    //     //     "type": "wms",
    //     //     "label": "KIUT WMS",
    //     //     "url": "https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaUzbrojeniaTerenu",
    //     //     "options": {
    //     //         "layers": "przewod_wodociagowy,przewod_kanalizacyjny,przewod_gazowy,przewod_elektroenergetyczny,przewod_telekomunikacyjny,przewod_cieplowniczy",
    //     //         "format": "image/png",
    //     //         "transparent": true,
    //     //         "attribution": "test",
    //     //         "layerName": "KIUT",
    //     //         baseLayer: true
    //     //     }
    //     // },
    //     // {
    //     //     "type": "wms",
    //     //     "label": "Ortogotomapa WMS",
    //     //     "url": "https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMS/StandardResolution",
    //     //     "options": {
    //     //         layers: 'Raster',
    //     //         format: 'image/jpeg',
    //     //         transparent: true,
    //     //         maxZoom: 21,
    //     //         layerName: "orto_wms",
    //     //         baseLayer: true
    //     //     }
    //     // },
    //     {
    //         "type": "wmts",
    //         "label": "Ortofotomapa WMTS",
    //         "url": 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/StandardResolution',
    //         "options": {
    //             // crs: crs,
    //             "maxZoom": 21,
    //             "format": 'image/png',
    //             "tileSize": 512,
    //             "version": '1.0.0',
    //             "transparent": true,
    //             "origin": [850000.0, 100000.0],
    //             "scales": [30238155.714285716, 15119077.857142858, 7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
    //             "tilematrixSet": 'EPSG:2180',
    //             "opacity": 1,
    //             "crossOrigin": true,
    //             "minZoom": 5,
    //             "layerName": "orto_wmts",
    //             "baseLayer": true
    //         }
    //     },
    //     // {
    //     //     "type": "wmts",
    //     //     "label": "BDOT 500",
    //     //     url: 'https://mapy.geoportal.gov.pl/wss/service/WMTS/guest/wmts/G2_MOBILE_500',
    //     //     "options": {
    //     //         format: 'image/png',
    //     //         tileSize: 512,
    //     //         version: '1.0.0',
    //     //         transparent: true,
    //     //         origin: [850000.0, 100000.0],
    //     //         scales: [30238155.714285716, 15119077.857142858, 7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
    //     //         tilematrixSet: 'EPSG:2180',
    //     //         opacity: 1.0,
    //     //         crossOrigin: true,
    //     //         minZoom: 5,
    //     //         layerName: "bdot_500",
    //     //         baseLayer: true
    //     //     }
    //     // }
    // ]);


    const [layerList, setLayerList] = React.useState([]);
    const [value, setValue] = React.useState(false);
    // const [value, setValue] = React.useState(layerList[0].options.layerName);
    // const [value, setValue] = React.useState();
    let {setBaseLayer, mapZoomLvl} = useContext(ToolContext)


    useEffect(() => {
        apiIndicators.getSystemLayersListByLayerType({layer_type: "baselayer"})
            .then(body => {
                setLayerList(body)
                setValue(body[0].options.layerName)
                setBaseLayer(body[0])
            })
    }, [])

    // useEffect(() => {
    //     if (mapZoomLvl >= 18) {
    //         setValue("orto_wmts")
    //         let lowestLvllayer = layerList.filter((layer) => {
    //             return layer.options.layerName === "orto_wmts"
    //         })
    //         console.log(lowestLvllayer)
    //         setBaseLayer(lowestLvllayer[0])
    //     }
    // }, [mapZoomLvl])

    const handleChange = (event) => {
        setValue(event.target.value);
        let selectedLayer = layerList.filter((layer) => {
            return layer.options.layerName === event.target.value

        })
        setBaseLayer(selectedLayer[0]);
        console.log(selectedLayer[0])
    };

    return (
        <FormControl component="fieldset"
                     style={{padding: "10px"}}>
            <FormLabel component="legend">Warstwy podkładowe</FormLabel>
            {(value) &&
            <RadioGroup aria-label="baseLayer" name="baseLayer" value={value} onChange={handleChange}>
                {layerList.map((el, idx) => {
                    return <FormControlLabel key={`${el.options.layerName}-${idx}`} value={el.options.layerName}
                                             classes={(value === value) ? {label: classes.labelActive} : {label: classes.labelInactive}}
                                             control={<Radio size={"small"} color={"primary"}/>} label={el.label}/>
                })
                }
            </RadioGroup>
            }
        </FormControl>
    );
}

export default BaseLayerRadioGroup
