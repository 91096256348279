import React, {useContext, useEffect, useState} from 'react'
import L from "leaflet";
import instance from "../../api/SettingsInstance";
import {ToolContext} from "../../context/ToolContext";
import {apiExternal} from "../../service";
import moment from "moment";
import {HighlightablePolyline} from "leaflet-highlightable-layers";
import {gugikHeights} from "../../utils/ExtService";
import {decode} from '@here/flexpolyline'

export const useTemplateHook = (removeIso, map) => {
    let {} = useContext(ToolContext)
    useEffect(() => {
        if (map) {

        }
    }, [removeIso])
}
//Measure Tools

var drawControlMeasure = new L.Control.Draw({
    draw: {
        // circlemarker: false,
        polyline: {
            fillColor: '#000000',
            shapeOptions: {
                // stroke: true,
                dashArray: '2, 10',
                color: '#000000',
                weight: 3,
                opacity: 0.5,
                fill: false,
                clickable: true
            },
        },
        polygon: {
            metric: true,
            showArea: true,
            shapeOptions: {
                fill: false,
                weight: 3,
                dashArray: '2, 10',
                color: '#000000',
                fillColor: '#000000',
                fillOpacity: 0.5

            }
        },
        // circle: false, // Turns off this drawing tool
        // rectangle: false,
        marker: {
            icon: L.IconMaterial.icon({
                icon: 'fiber_manual_record',
                // Name of Material icon
                // iconColor: '#622a88',              // Material icon color (could be rgba, hex, html name...)
                markerColor: '#622a88',  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,
                iconSize: [21, 31]                 // Width and height of the icon
            })
        },
    }
});

export const useMeasureTools = (measureLine, measurePolygon, measurePoint, clearMeasures, map) => {
    let {setMeasurePoint, setMeasurePolygon, setMeasureLine, setActiveMeasureTool, setClearMeasures} = useContext(ToolContext)
    useEffect(() => {
        if (map) {
            let measureItems = new L.FeatureGroup(null, {measureType: "poly"});
            map.addLayer(measureItems);
            map.addLayer(measureItems);

            let polylineDrawHandler;
            let polygonMeasureHandler;
            let pointDrawHandler;

            map.on('draw:canceled', () => {
                setMeasureLine(false)
                setActiveMeasureTool(false)
                setMeasurePolygon(false)
                setMeasurePoint(false)
            });

            if (measureLine) {
                L.drawLocal.draw.handlers.polyline.tooltip = {
                    start: 'Kliknij aby zacząć pomiar odległości',
                    cont: 'Kliknij aby kontynuować pomiar odległości',
                    end: 'Kliknij dwa razy aby zakończyć pomiar.'
                };

                polylineDrawHandler = new L.Draw.Polyline(map, drawControlMeasure.options.draw.polyline);
                polylineDrawHandler.enable();

                map.on('draw:created', e => {
                    const layer = e.layer;
                    measureItems.addLayer(layer, {measureType: "poly"});
                    setMeasureLine(false)
                    setActiveMeasureTool(false)
                    console.log(measureItems)

                    if (e.layerType === 'polyline') {
                        console.log(layer.getLatLngs())
                        layer
                            // .bindPopup(L.GeometryUtil.length(layer.getLatLngs()) + " m")
                            // L.GeometryUtil.length()
                            // .openPopup()
                            .showMeasurements()
                    }
                });
            }

            if (measurePolygon) {
                L.drawLocal.draw.handlers.polygon.tooltip = {
                    start: 'Kliknij aby zacząć pomiar powierzchni obszaru',
                    cont: 'Kliknij aby kontynuować pomiar powierzchni',
                    end: 'Kliknij w punkt początkowy aby zakończyć pomiar.'
                };

                console.log(drawControlMeasure.options)
                polygonMeasureHandler = new L.Draw.Polygon(map, drawControlMeasure.options.draw.polygon);
                polygonMeasureHandler.enable();
                map.on('draw:created', e => {
                    const layer = e.layer;
                    measureItems.addLayer(layer, {measureType: "poly"});
                    if (e.layerType === 'polygon') {
                        layer.showMeasurements()
                    }
                    setMeasurePolygon(false)
                    setActiveMeasureTool(false)
                });
            }

            if (measurePoint) {
                L.drawLocal.draw.handlers.marker.tooltip = {
                    start: 'Kliknij aby sprawdzić wysokość terenu',
                };
                pointDrawHandler = new L.Draw.Marker(map, drawControlMeasure.options.draw.marker);
                pointDrawHandler.enable();
                map.on('draw:created', e => {
                    const layer = e.layer;
                    measureItems.addLayer(layer, {measureType: "poly"});
                    setMeasurePoint(false)
                    setActiveMeasureTool(false)
                    console.log(measureItems)
                    if (e.layerType === 'marker') {
                        gugikHeights(layer.getLatLng().lng, layer.getLatLng().lat)
                            .then((response) => {
                                    console.log(response)
                                    layer
                                        .bindTooltip(response + " m.n.p.m").openTooltip()
                                        .bindPopup(response + " m.n.p.m")
                                    // .openPopup()
                                }
                            )
                    }
                });
            }


            if (clearMeasures) {
                console.log("clear 2", measureItems)
                measureItems.clearLayers();
                map.eachLayer((layer) => {
                    if (layer.options.measureType === "poly") {
                        console.log(1)
                        layer.removeFrom(map)
                    } else {
                        console.log(0)
                    }
                })
                //
                setActiveMeasureTool(false)
                setClearMeasures(false)
            }

            return () => {
                drawControlMeasure.remove()
            }
        }
    }, [measureLine, measurePolygon, measurePoint, clearMeasures])
}
//User Pois hooks

export const useHideUserPoints = (hideUserPoint, map) => {
    // a little bit dummy and tricky
    useEffect(() => {
        if (map) {
            if (hideUserPoint) {
                map.eachLayer((layer) => {
                    if (layer.options.name === "userPoint") {
                        layer.setOpacity(0)
                            .unbindTooltip()
                    }
                })
            } else {
                map.eachLayer((layer) => {
                    if (layer.options.name === "userPoint") {
                        const tooltip = layer.options.tooltip
                        layer
                            .bindTooltip(tooltip)
                            .setOpacity(1)

                    }
                })
            }
        }
    }, [hideUserPoint])
}

export const useUserPoint = (addUserPoint, userPointLabel, userPointColor, map) => {
    let {setAddUserPoint} = useContext(ToolContext)

    function userPoint(e) {
        L.marker(e.latlng, {
            name: "userPoint",
            draggable: true,
            tooltip: userPointLabel,
            icon: L.IconMaterial.icon({
                icon: 'fiber_manual_record',
                // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: userPointColor,  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,
                iconSize: [21, 31]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(map)
        }).bindTooltip(userPointLabel)
            .addTo(map)
        setAddUserPoint(false)
    }

    useEffect(() => {
        if (map) {
            if (addUserPoint) {
                map.on("click", userPoint)
            }
            return () => {
                map.off("click", userPoint)
            }
        }
    }, [addUserPoint, userPointLabel])
}

//Routing Tool hooks
export const useRemoveRoutes = (removeRoutes, map) => {
    let {setRemoveRoutes} = useContext(ToolContext)
    useEffect(() => {
        if (map) {
            if (removeRoutes) {
                map.eachLayer((layer) => {
                    if (layer.options.name === "userRoute") {
                        layer.removeFrom(map)
                    }
                })
            }
            setRemoveRoutes(false)
        }
    }, [removeRoutes])
}

export const useGenerateRoute = (generateRoute, routeOrigin, routeDestination, routeTransportType, map) => {
    let {setGenerateRoute} = useContext(ToolContext)
    useEffect(() => {
        if (map) {
            if (generateRoute) {
                if (routeDestination !== null && routeOrigin !== null && routeTransportType !== null) {
                    apiExternal.getApisRouting({
                        transportMode: routeTransportType,
                        origin: routeOrigin,
                        destination: routeDestination
                    })
                        .then(body => {
                            const route = body.routes[0].sections[0].polyline
                            const duration = body.routes[0].sections[0].spans[0].duration
                            const duration_time = moment.utc(duration * 1000).format('HH:mm:ss');
                            const popup = `${duration_time} </br>        
                                   ${body.routes[0].sections[0].spans[0].length / 1000} km </br> ${(routeTransportType === "car") ? "autem" : "pieszo"}`

                            new HighlightablePolyline(route, {
                                generateStyles: (options, renderer) => ({
                                    main: {
                                        opacity: 0, weight: 20, pane: 'lhl-almost-over',
                                        name: "userRoute",
                                        snakingSpeed: 800,
                                        // raised: false
                                    },
                                    line1: {
                                        ...options,
                                        color: '#ffffff', weight: 4,
                                        raised: false,
                                        renderer
                                    },
                                    line2: {
                                        ...options,
                                        dashArray: (routeTransportType === "car") ? '10,0' : '8,5',
                                        color: '#3355DD', weight: 2,
                                        renderer
                                    }
                                })
                            }).on({
                                mouseover: (e) => {
                                    e.target.setStyle({weight: 4});
                                },
                                mouseout: (e) => {
                                    e.target.setStyle({weight: 4})
                                },
                                click: (e) => {
                                },
                                dblclick: (e) => {
                                    e.target.removeFrom(map)
                                },
                            })
                                .bindPopup(popup)
                                .addTo(map)
                                .snakeIn()
                        })
                } else {
                    console.log("route error")
                }

            }
            setGenerateRoute(false)
        }

    }, [generateRoute, routeOrigin, routeDestination, routeTransportType])
}

export const useRouteDestination = (addRouteDestination, map) => {
    let {setRouteDestination, setAddRouteDestination} = useContext(ToolContext)

    function setRouteDestinationEvent(e) {
        new L.marker(e.latlng, {
            name: "userRoute",
            icon: L.IconMaterial.icon({
                icon: 'flag',            // Name of Material icon
                // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                markerColor: '#f40d0d',  // Marker fill color
                // outlineColor: 'yellow',            // Marker outline color
                outlineWidth: 1,                   // Marker outline width
                iconSize: [21, 32]                 // Width and height of the icon
            })
        }).on("dblclick", (e) => {
            e.target.removeFrom(map)
        }).addTo(map)

        setRouteDestination([e.latlng.lat, e.latlng.lng])
        setAddRouteDestination(false)
    }

    useEffect(() => {
        if (map) {
            if (addRouteDestination) {
                map.on("click", setRouteDestinationEvent)
            }
            return () => {
                map.off("click", setRouteDestinationEvent)
            }
        }
    }, [addRouteDestination])
}

export const useRouteOrigin = (addRouteOrigin, map) => {
    let {setRouteOrigin, setAddRouteOrigin} = useContext(ToolContext)
    useEffect(() => {
        function setRouteOriginEvent(e) {
            new L.marker(e.latlng, {
                name: "userRoute",
                icon: L.IconMaterial.icon({
                    icon: 'flag',            // Name of Material icon
                    // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                    markerColor: '#3bab06',  // Marker fill color
                    // outlineColor: 'yellow',            // Marker outline color
                    outlineWidth: 1,                   // Marker outline width
                    iconSize: [21, 32]                 // Width and height of the icon
                })
            }).on("dblclick", (e) => {
                e.target.removeFrom(map)
            }).addTo(map)
            setRouteOrigin([e.latlng.lat, e.latlng.lng])
            setAddRouteOrigin(false)
        }

        if (map) {
            if (addRouteOrigin) {
                map.on("click", setRouteOriginEvent)
            }
            return () => {
                map.off("click", setRouteOriginEvent)
            }
        }
    }, [addRouteOrigin])
}

// Isochrone Tool hooks
export const useIsochroneTool = (addIsoPoint, map) => {
    let {setAddIsoPoint, setIsochroneStart} = useContext(ToolContext)
    useEffect(() => {
        function addStartPoint(e) {
            new L.marker(e.latlng, {
                name: "userIsochrone",
                icon: L.IconMaterial.icon({
                    icon: 'schedule',         // Name of Material icon
                    // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                    markerColor: '#1273de',  // Marker fill color
                    // outlineColor: 'yellow',            // Marker outline color
                    outlineWidth: 1,
                    iconSize: [21, 31]                 // Width and height of the icon
                })
            }).on("dblclick", (e) => {
                e.target.removeFrom(map)
            }).addTo(map)
            setIsochroneStart([e.latlng.lat, e.latlng.lng])
            setAddIsoPoint(false)
        }

        if (map) {
            if (addIsoPoint) {
                map.on("click", addStartPoint)
            }
            return () => {
                map.off("click", addStartPoint)
            }
        }
    }, [addIsoPoint])
}

export const useRemoveIsochrones = (removeIso, map) => {
    let {setRemoveIso} = useContext(ToolContext)
    useEffect(() => {
        if (map) {
            if (removeIso) {
                map.eachLayer((layer) => {
                    if (layer.options.name === "userIsochrone") {
                        layer.removeFrom(map)
                    }
                })
            }
            setRemoveIso(false)
        }
    }, [removeIso])
}

export const useGenerateIsochrone = (generateIso, isochroneStart, isoTransportType, isoTime, map) => {
    let {setGenerateIso} = useContext(ToolContext)
    useEffect(() => {
        if (map) {
            if (generateIso) {
                if (isoTime >= 1 && isoTransportType) {
                    apiExternal.getApisIsochrone({
                        localization: isochroneStart,
                        time: isoTime,
                        transportType: isoTransportType
                    })
                        .then((isochroneGeojson) => {
                            const encodedString = isochroneGeojson.isolines[0].polygons[0].outer
                            const decoded = decode(encodedString);
                            let array = decoded.polyline
                            if (array[0] !== array[array.length - 1]) {
                                array.push(array[0])
                            }

                            map.fitBounds(L.polyline(array).getBounds())

                            let popup = L.popup()
                                .setContent(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`)

                            new L.polyline(array, {
                                fill: false,
                                weight: 4,
                                color: 'white',
                                // dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                                // fillOpacity: 0.5,
                                opacity: 0.8,
                                name: "userIsochrone"
                            }).addTo(map)


                            new L.polyline(array, {
                                fill: false,
                                weight: 2,
                                // color: '#3a3838',
                                color: '#3355DD',
                                dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                                fillOpacity: 0.05,
                                opacity: 0.8,
                                name: "userIsochrone"
                            })
                                // .setText(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`,
                                //     {
                                //         // repeat: true,
                                //         offset: 8,
                                //         orientation: 0,
                                //         attributes: {
                                //             'font-weight': 'bold',
                                //             'font-size': '24',
                                //             'text-stroke': '2px red',
                                //         }
                                //     })
                                .bindPopup(popup)
                                .bindTooltip(`${isoTime / 60} minut ${(isoTransportType === "car") ? "autem" : "pieszo"}`, {sticky: true})
                                .on({
                                    // mouseover: (e) => {
                                    //     // var popup = e.target.getPopup();
                                    //     // popup.setLatLng(e.latlng).openOn(map);
                                    //     e.target.setStyle({weight: 5});
                                    // },
                                    // mouseout: (e) => {
                                    //     e.target.setStyle({weight: 1})
                                    // //     e.target.closePopup();
                                    // },
                                    // mousemove: (e) => {
                                    //     popup.setLatLng(e.latlng).openOn(map);
                                    // }
                                    click: (e) => {
                                        var popup = e.target.getPopup();
                                        popup.setLatLng(e.latlng).openOn(map);
                                        e.target.setStyle({weight: 4});
                                    },
                                    dblclick: (e) => {
                                        e.target.setStyle({weight: 2})
                                        e.target.closePopup();
                                        // e.target.removeFrom(map)
                                    },
                                })
                                .addTo(map)
                                .bringToFront()
                        })
                        .catch(reason => {
                            console.log(reason)
                        })
                } else {
                    console.log('isochrone error')
                }
            }
            setGenerateIso(false)
        }
    }, [generateIso, isochroneStart, isoTransportType, isoTime])
}
//Layer Hooks
export const useBaseLayerSwitcher = (baseLayer, map) => {
    useEffect(
        () => {
            if (map) {
                let crs2180 = new L.Proj.CRS(
                    "EPSG:2180",
                    "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 units=m +no_defs",
                    {}
                );
                let base;

                if (baseLayer.service_type === "tms") {
                    // console.log(baseLayer, "baseLayer")
                    base = L.tileLayer(baseLayer.uri, baseLayer.options)
                    // .on("loading", (e) => {
                    //     addSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("load", (e) => {
                    //     removeSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("tileerror", (e) => {
                    //
                    // })
                } else if (baseLayer.service_type === "wms") {
                    // console.log(baseLayer)
                    base = L.tileLayer.wms(baseLayer.uri, baseLayer.options)
                    // .on("loading", (e) => {
                    //     addSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("load", (e) => {
                    //     removeSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("tileerror", (e) => {
                    //
                    // })
                } else if (baseLayer.service_type === "wmts") {
                    // console.log(baseLayer)
                    baseLayer.options.crs = crs2180
                    base = L.tileLayer.projwmts(baseLayer.uri, baseLayer.options)
                    // .on("loading", (e) => {
                    //     addSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("load", (e) => {
                    //     removeSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("tileerror", (e) => {
                    //
                    // })
                } else if (baseLayer.service_type === "tmsFilter") {
                    // console.log("tmsF", baseLayer.uri, baseLayer.options)
                    base = L.tileLayer.colorFilter(baseLayer.uri, baseLayer.options)

                    // .on("loading", (e) => {
                    //     addSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("load", (e) => {
                    //     removeSpinnerMsg(e.target.options.layerName)
                    // })
                    // .on("tileerror", (e) => {
                    //
                    // })
                }

                map.eachLayer((layer) => {
                    if (layer.options.baseLayer === true) {
                        layer.removeFrom(map)
                        // removeSpinnerMsg(layer.options.layerName)
                    }
                })

                base.bringToBack()
                    .addTo(map)
            }

        }, [baseLayer])
}

export const useOverLayerSwitcher = (overLayer, map) => {
    useEffect(() => {
        if (map) {
            let crs2180 = new L.Proj.CRS(
                "EPSG:2180",
                "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 units=m +no_defs",
                {}
            );

            if (overLayer.value.service_type === "wmts") {
                overLayer.value.options.crs = crs2180
            }

            let layerUrl = (overLayer.value.layer_source === "internal") ? instance.geoserverUrl + "/pai/wms" : overLayer.value.uri

            let layerTypes = {
                "tms": L.tileLayer(layerUrl, overLayer.value.options),
                "wms": L.tileLayer.wms(layerUrl, overLayer.value.options),
                "wmts": L.tileLayer.projwmts(layerUrl, overLayer.value.options),
                "nonTiled": L.nonTiledLayer.wms(layerUrl, overLayer.value.options)
            }

            if (overLayer.visibleOnMap) {
                (overLayer.value.layer_source === "internal") ?
                    layerTypes[overLayer.value.service_type]
                        .setParams({jwt: JSON.parse(localStorage.pai_tokens).access_token})
                        .setZIndex(100)
                    : layerTypes[overLayer.value.service_type]
                        .setZIndex(100)


                // layerTypes[overLayer.value.service_type]
                //     .setParams({jwt: JSON.parse(localStorage.pai_tokens).access_token})
                //     .setZIndex(100)

                // instance.geoserverUrl
                // .on("tileloadstart", (e) => {
                //     setInfo({message: "ładuję kafle " + e.target.options.layerName, type: 'information'})
                //     // console.log("pobieram",e.target.options.layerName)
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("loading", (e) => {
                //     setInfo({message: "ładuję  " + e.target.options.layerName, type: 'information'})
                //     // console.log("trwa",e.target.options.layerName)
                //     addSpinnerMsg(e.target.options.layerName)
                // })
                // .on("load", (e) => {
                //     setInfo({message: "załadowałem " + e.target.options.layerName, type: 'success'})
                //     removeSpinnerMsg(e.target.options.layerName)
                // })
                // .on("tileerror", (e) => {
                //     setInfo({message: "błąd " + e.target.options.layerName, type: 'error'})
                //     removeSpinnerMsg(e.target.options.layerName)
                // })

                layerTypes[overLayer.value.service_type].addTo(map)
            } else {
                map.eachLayer((layer) => {
                    // console.log(overLayer.value.options.layerName)
                    if (layer.options.layerName === overLayer.value.options.layerName) {
                        layer.removeFrom(map)
                        // removeSpinnerMsg(layer.options.layerName)
                    }
                })
            }
        }
    }, [overLayer])
}
