import React, {useContext} from 'react';
import {AnalysisContext} from "../../../../../context/AnalysisContext";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';

RadiusAOI.propTypes = {
    timeRange: PropTypes.array.isRequired
};

function RadiusAOI(props) {
    let {radius, setRadius} = useContext(AnalysisContext);

    let {timeRange} = props

    function setCircleRadius(e) {
        setRadius(e.target.value)
    }

    function sortFunc(a, b) {
        let aa = Number.parseInt(a.value)
        let bb = Number.parseInt(b.value)
        if (aa < bb) {
            return -1
        } else {
            return 1
        }
    }

    return (
        <Grid item>
            <FormControl>
                <InputLabel id="demo-radius-select-label">Wartość (km)</InputLabel>
                <Select
                    id="demo-radius-select"
                    labelId="demo-radius-select-label"
                    // defaultValue={2}
                    value={(radius && radius >= 1) ? radius : null}
                    onChange={setCircleRadius}
                >
                    <MenuItem
                        key={'radius-item-empty'}
                        value={null}/>
                    {timeRange.sort(sortFunc).map((item, index) => {
                        return (
                            <MenuItem key={item.name + '-' + item.value + '-' + index}
                                      value={Number.parseInt(item.value)}>{item.title}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Grid>
    );
}

export default RadiusAOI;
