import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {Button, Container, Paper} from "@material-ui/core";
import LoginForm from "./LoginForm";
import SignInForm from "./SignInForm";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import {ErrorProvider} from "../../context/ErrorContext";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(10),
        paddingLeft: theme.spacing(10),
    },
}));

function UserPage() {
    const [state, setState] = useState("login")

    function login() {
        setState("login")
    }

    function signin() {
        setState("signin")
    }

    const classes = useStyles();
    return (
        <>
            <ErrorProvider>
                <div className={'manualPage'}>
                    <Container
                        className={classes.container}
                        p={10}
                        maxWidth="xs">
                        <Paper>
                            <Button onClick={login}><PersonIcon/>Logowanie</Button>
                            {/*<Button onClick={signin}><PersonAddIcon/>Rejestracja</Button>*/}
                            {state === "login" &&
                            <LoginForm/>
                            }
                            {state === "signin" &&
                            <SignInForm/>
                            }
                        </Paper>
                    </Container>
                </div>
            </ErrorProvider>
        </>
    );
}

export default UserPage
