import React, {useContext, useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import {apiLocalAnalysis} from "../../service";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {ErrorContext} from "../../context/ErrorContext";

function SaveButton() {
    const [id, setId] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [newName, setNewName] = useState('');

    let {info, setInfo} = useContext(ErrorContext)

    let {result} = useContext(PoiAnalysisContext)

    const handleEditClose = () => {
        setOpenEdit(false);
    };

    const handleNewName = (e) => {
        setNewName(e.target.value)
    };

    const handleEdit = () => {
        console.log(newName, id)
        setOpenEdit(false);
        // handleSaveAnalysis()
        saveAnalysis()
    };



    const saveAnalysis = () => {
        setIsLoading(true)
        apiLocalAnalysis.postLocalanalysisByAnalysisIdSave({body:{"name" : newName},analysis_id: id})
            .then(response => {
                console.log(response);
                setIsLoading(false)
            }).catch(reason => {
            console.log(reason)
        })
    };

    useEffect(() => {
        if (result) {
            setId(result.id)
        }
    }, [result])


    return (
        <>
            {/*{result && result.count > 0 &&*/}
            <Button
                // onClick={saveAnalysis}
                onClick={(e) => {
                    e.preventDefault()
                    setOpenEdit(true)
                }}


                variant={"contained"}
                color="primary"
            >
                {isLoading ? "Zapisuję..." : "Zapisz"}
            </Button>
            {/*}*/}

            <Dialog
                open={openEdit}
                onClose={handleEditClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Podaj nową nazwę analizy "} {id}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nazwa analizy"
                            type="text"
                            fullWidth
                            onChange={handleNewName}
                        />
                        {/*Let Google help apps determine location. This means sending anonymous location data to*/}
                        {/*Google, even when no apps are running.*/}

                        {/*{analysisId}*/}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary"
                            autoFocus>
                        Anuluj
                    </Button>
                    <Button onClick={handleEdit} color="primary"
                            variant="contained">
                        Tak
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SaveButton

