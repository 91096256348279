import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import PropTypes from "prop-types";
import {apiUser} from "../../service";
import LinearProgress from "@material-ui/core/LinearProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
});


// MyAnalysisList.propTypes = {
//     analysisList: PropTypes.array.isRequired,
// }

function AccountManager(props) {
    const [userData, setUserData] = useState([]);

    const classes = useStyles();

    function getUsers() {
        console.log("list update")
        apiUser.getUserList()
            .then(resp => {
                setUserData(resp)
            })
            .catch(reason => {
                    console.log(reason.message)
            })
    }

    useEffect(() => {
        getUsers()
    }, []);


    function updateAccount(user) {
        delete user.edit
        console.log(user.email, user.id, user.active, user.deleted, user.role.name)
        apiUser.postUserAdminByUserId({
            user_id: user.id,
            body: {
                deleted: user.deleted,
                active: user.active,
                role: {name: user.role.name}
            }
        })
            .then(resp => {
                console.log(resp)
                getUsers()
            })
            .catch(reason => {
                console.log(reason)
            })
    }

    return (


        <TableContainer>
            {!userData.length &&
            <LinearProgress/>
            }
            {/*TODO sprawdź responsywność*/}
            {/*<TableContainer component={Paper}>*/}
            <Table
                size="small" aria-label="a dense table"
                // className={classes.table}

            >
                <TableHead>
                    <TableRow>
                        <TableCell><b>id</b></TableCell>
                        <TableCell><b>Email</b></TableCell>
                        <TableCell><b>Rola</b></TableCell>
                        <TableCell><b>Aktywny</b></TableCell>
                        <TableCell><b>Usunięty</b></TableCell>
                        <TableCell><b>Akcje</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {userData &&
                    userData.map(user => (
                            <TableRow hover>
                                <TableCell
                                    style={{
                                        // whiteSpace: "nowrap",
                                        // padding: "0px 8px"
                                    }}>
                                    {user.id}
                                    {/*<TextField*/}
                                    {/*    disabled={!user.edit}*/}
                                    {/*    id="standard-basic"*/}
                                    {/*    value={user.nickname}*/}
                                    {/*    onChange={(e) => {*/}
                                    {/*        user.nickname = e.target.value;*/}
                                    {/*        setUserData([...userData]);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </TableCell>
                                <TableCell
                                    style={{
                                        // whiteSpace: "nowrap",
                                        // padding: "0px 8px"
                                    }}>
                                    <TextField
                                        variant={"standard"}
                                        size={"small"}
                                        disabled={!user.edit}
                                        id="standard-basic"
                                        value={user.email}
                                        onChange={(e) => {
                                            user.email = e.target.value;
                                            setUserData([...userData]);
                                        }}
                                    />
                                </TableCell>

                                <TableCell style={{
                                    // whiteSpace: "nowrap",
                                    // textAlign: "left",
                                    // padding: "0px 8px"
                                }}>
                                    <Select
                                        disabled={!user.edit}

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={user.role.name}
                                        onChange={(e) => {
                                            user.role.name = e.target.value;
                                            setUserData([...userData]);
                                        }}
                                    >
                                        <MenuItem value={"USER"}>USER</MenuItem>
                                        <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                                    </Select>
                                </TableCell>

                                <TableCell style={{
                                    // whiteSpace: "nowrap",
                                    // textAlign: "left",
                                    // padding: "0px 8px"
                                }}>
                                    <Checkbox
                                        checked={user.active}
                                        disabled={!(user.edit)}
                                        onChange={(e) => {
                                            user.active = !user.active;
                                            setUserData([...userData]);
                                        }}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                </TableCell>
                                <TableCell style={{
                                    whiteSpace: "nowrap",
                                    textAlign: "left",
                                    // padding: "0px 8px"
                                }}>
                                    <Checkbox
                                        checked={user.deleted}
                                        disabled={!(user.edit)}
                                        onChange={(e) => {
                                            user.deleted = !user.deleted;
                                            setUserData([...userData]);
                                        }}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                </TableCell>
                                <TableCell>
                                    {/*{!user.edit &&*/}
                                    <Button
                                        value={user.edit}
                                        onClick={(e) => {
                                            user.edit = !user.edit;
                                            setUserData([...userData]);
                                        }}
                                    >{(user.edit) ? "anuluj" : "Edytuj"}</Button>
                                    {/*}*/}
                                    {user.edit &&

                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        // value={user.edit}
                                        disabled={!user.edit}
                                        onClick={(e) => {
                                            user.edit = !user.edit;
                                            setUserData([...userData]);
                                            updateAccount(user)
                                        }}
                                    >Zapisz</Button>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AccountManager
