import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Tooltip} from "@material-ui/core";
import theme from "../../../../../theme";
import {getColor} from "../../../../../utils/ExtService";
import LinearProgress from "@material-ui/core/LinearProgress";

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <Tooltip
                placement={"top"}
                title={"Obszar spełnia Twoje oczekiwania w " + props.value + " %"}
                aria-label="add">
                <CircularProgress
                    style={{'color': getColor(parseFloat(props.value)/100)}}
                    // style={props.disabled ? {'color': 'lightgrey'} : {'color': theme.palette.primary.main}}
                    variant="determinate"
                    {...props} />

            </Tooltip>
            <Box
                top={0}
                left={20}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                color="red"
                // justifyContent="center"
            >
                <Tooltip
                    placement={"top"}
                    title={"Obszar spełnia Twoje oczekiwania w " + Math.round(props.value) + " %"}
                    aria-label="add">
                    <Typography variant="caption"
                        // style={props.disabled ? {'color': 'lightgrey'}: {'color': theme.palette.primary.main}}
                                style={{'color': theme.palette.primary.main}}
                                component="div">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Tooltip>
            </Box>
        </Box>

    );
}


// export default function CircularStatic() {
//     const [progress, setProgress] = React.useState(10);
//
//     React.useEffect(() => {
//         const timer = setInterval(() => {
//             setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
//         }, 800);
//         return () => {
//             clearInterval(timer);
//         };
//     }, []);
//
//     return <CircularProgressWithLabel value={progress} />;
// }

export default CircularProgressWithLabel
