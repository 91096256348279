import React, {useContext} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";

function IsochroneToggle() {
    let {setAoiIsochrone} = useContext(PoiAnalysisContext)

    const [alignment, setAlignment] = React.useState(null);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        setAoiIsochrone(newAlignment)
    };

    return (
        <ToggleButtonGroup
            size="medium"
            value={alignment}
            exclusive
            onChange={handleAlignment}>
            <ToggleButton
                value={JSON.stringify({type: "pedestrian", time: "300"})}
                >5 min
                <DirectionsWalkIcon fontSize="small"/>
            </ToggleButton>
            <ToggleButton
                value={JSON.stringify({type: "pedestrian", time: "600"})}
            >10 min
                <DirectionsWalkIcon fontSize="small"/>
            </ToggleButton>
            <ToggleButton
                value={JSON.stringify({type: "car", time: "300"})}
            >5 min
                <DirectionsCarIcon fontSize="small"/>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default IsochroneToggle
