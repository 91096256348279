import React, {useContext, useEffect} from "react";
import {Button} from "@material-ui/core";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import {apiLocalAnalysis} from "../../service";

function GetReport() {
    const [id, setId] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    let {result} = useContext(PoiAnalysisContext)

    const getReport = () => {
        setIsLoading(true)
        apiLocalAnalysis.postLocalanalysisByAnalysisIdReport({analysis_id: id})
            .then(response => {
                console.log(response);
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', `raport_${id}`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                setIsLoading(false)
            })

    };

    useEffect(() => {
        if (result) {
            setId(result.id)
        }
    }, [result])


    return (
        <>
            {/*{result && result.count > 0 &&*/}
        <Button
            onClick={getReport}
            variant={"outlined"} color="primary"
        >
            {isLoading ? "generuję raport..." : "Raport PDF"}
        </Button>
            {/*}*/}
        </>

    )

}

export default GetReport

