import React, {createContext, useContext, useEffect, useState} from 'react';
import {apiAuth} from "../service";
import {ErrorContext} from "./ErrorContext";
import {useHistory} from "react-router-dom";

export const AuthContext = createContext();
export const initialAuthContext = (localStorage.getItem('pai_tokens')) ? JSON.parse(localStorage.getItem('pai_tokens')) : {}
export const initialIsLogged = !!(localStorage.getItem("pai_isLogged"))
export const initialUserLogin = localStorage.getItem('pai_login')


export const AuthProvider = props => {
    let {setInfo} = useContext(ErrorContext);
    const [auth, setAuth] = useState(initialAuthContext);
    const [isLogged, setIsLogged] = useState(initialIsLogged);
    const [userLogin, setUserLogin] = useState(initialUserLogin);
    const [userRole, setUserRole] = useState('');

    let history = useHistory();

    function clearAuthContext() {
        localStorage.clear()
        setAuth(initialAuthContext)
        setIsLogged(initialIsLogged)
        setUserLogin(initialUserLogin)
        setUserRole('')
        history.push('/auth');
    }

    useEffect(() => {
        checkRole()
    }, [])

    function checkRole() {
        //if has token
        if (initialIsLogged) {
            apiAuth.postUserTokenValidate()
                .then(resp => {
                    //logged and token valid
                    if (userRole === '') {
                        apiAuth.getUserMe()
                            .then(resp => {
                                setUserRole(resp.role.name)
                            })
                            .catch(reason => {
                                console.log(reason)
                            });
                    }
                }, rej => {
                    //logged but token invalid -> clear storage, optional: redirect to /auth
                    clearAuthContext()
                })
                .catch(err => {
                    // do nothing
                })
        } else {
            // not logged so why to get /user/me
        }
    }


    return (
        <AuthContext.Provider
            value={{
                auth, setAuth,
                userLogin, setUserLogin,
                isLogged, setIsLogged,
                userRole, setUserRole
            }}>
            {props.children}
        </AuthContext.Provider>
    )
};
