import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import {element} from "prop-types";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles({
    result: {
        fontSize: 12,
        fontStyle: "italic"
    },
});

function Progress(props) {
    const classes = useStyles();
    let MIN = props.min
    let MAX = props.max

    let conditions = props.conditions

    function normalise(value) {
        value = (value === "f" && !props.indicatorIsPositive) ? 0 : value
        value = (value === "t" && !props.indicatorIsPositive) ? 1 : value

        value = (value === "f" && props.indicatorIsPositive) ? 1 : value
        value = (value === "t" && props.indicatorIsPositive) ? 0 : value
        return (props.indicatorIsPositive) ?  (value - MIN) * 100 / (MAX - MIN) : (MAX - value) * 100 / (MAX - MIN)
    }

    function conditionHandler(value, conditions) {
        let label = props.unit.label === null ? " " : props.unit.label
        let unit = props.unit.name === null ? " " : props.unit.name
        let condition = conditions.filter((cond) => {
            return cond.input === value
        })
        let result = (condition.length >= 1) ?  condition[0].output :  label + ' ' + value + ' ' + unit;
        return result
    }

    return (
        <React.Fragment>
            <LinearProgress variant="determinate" value={normalise(props.value)}/>
            {/*<center> {props.value}*/}
            {/*</center>*/}
            <center>
                <Typography align="center"
                            className={classes.result}>{conditionHandler(props.value, conditions)}</Typography>
            </center>
            <center>
                {/*<span>{props.value}  {typeof  props.value}</span>*/}

                {/*<span>{  JSON.stringify(props.indicatorIsPositive)}</span>*/}
                {/*<span> {MIN} {MAX} </span>*/}

                {/*<span>{props.indicatorIsPositive} </span>*/}
                {/*<span>{JSON.stringify(props.min_value)} {JSON.stringify(props.max_value)} </span>*/}
            </center>
        </React.Fragment>
    )
}

export default Progress