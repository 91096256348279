import React, {useContext, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import Parser from 'html-react-parser';
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconLegend from "./IconLegend";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
        gridDisabled: {
            color: 'darkgray'
        }
    }))
;

function PoiCategory(props) {
    const [checked, setChecked] = React.useState(true);
    const [value, setValue] = React.useState("null");
    let {
        categoriesVisibility, setCategoriesVisibility,
        highlights, setHighlights,
        subCategoriesVisibilty, setSubCategoriesVisibility
    } = useContext(PoiAnalysisContext)

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setCategoriesVisibility({[props.id]: event.target.checked});
    };

    useEffect(() => {
        setCategoriesVisibility({[props.id]: checked});
    }, [])

    return (
        <>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={3} sm={1} md={1} lg={1} xl={1}>
                    <Switch
                        size={"small"}
                        color={"primary"}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />
                </Grid>
                <Grid item xs={11} sm={10} md={10} lg={10} xl={10}>
                    <Box style={{paddingLeft: "15px"}}>
                        <Typography>
                            <b>{props.data.category_label}</b>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {props.data.objects.map((el, idx) => (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    key={`${el.layer_name}-${idx}`}
                >
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <IconLegend color={el.layer_style.backgroundColor} icon={el.layer_style.html}/>
                    </Grid>
                    <Grid
                        item spacing={0} xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Box style={{padding: "2px"}}>
                            <Typography
                                variant={"body1"}
                                style={
                                    (subCategoriesVisibilty === el.layer_name && el.count !== 0) ?
                                        {cursor: (el.count !== 0) ? "pointer" : "default"} :
                                        {cursor: (el.count !== 0) ? "pointer" : "default", color: "darkgrey"}
                                }
                                // onMouseLeave={() => setHighlights(null)}
                                onClick={() => {
                                    if (subCategoriesVisibilty !== el.layer_name && checked && el.count !== 0) {
                                        setHighlights(el.layer_name)
                                        setSubCategoriesVisibility(el.layer_name)
                                    } else {
                                        setSubCategoriesVisibility(null)
                                        setHighlights(null)
                                    }
                                }}
                                // onClick={(e) => console.log(e.target.value)}
                                // onMouseMove={() => setHighlights(el.layer_name)}
                            >

                                {el.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        {el.count}
                    </Grid>
                </Grid>
            ))}
        </>
    );
}

export default PoiCategory;
