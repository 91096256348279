import './App.css';
import React, {useContext} from "react";
import MiniDrawer from "./components/MiniDrawer";
import {BrowserRouter as Router} from 'react-router-dom'
import {AuthProvider} from "./context/AuthContext";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorFallback} from "./utils/ErrorFallback";
import {ErrorProvider} from "./context/ErrorContext";


function App() {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <ErrorProvider>
                <AuthProvider>
                    <Router>
                        <div className="App">
                            <MiniDrawer/>
                        </div>
                    </Router>
                </AuthProvider>
            </ErrorProvider>
        </ErrorBoundary>
    )
}

export default App;

