import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
// import AnalysisIndocators from "../../AnalysisIndicators";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import {AnalysisContext} from "../../../context/AnalysisContext";
import {apiAnalysis} from "../../../service";
import CircularStatic from "../../analysis/new/steps/sumup/WaiResult";
import CircularProgressWithLabel from "../../analysis/new/steps/sumup/WaiResult";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Box, Radio, Slider, Tooltip} from "@material-ui/core";

import FeatureInfo from "./FeatureInfo";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    labelActive: {
        // fontWeight: 'Bold'
        fontSize: 12,
        color: 'Black'
    },
    labelInactive: {
        fontSize: 12,
        fontWeight: 'Regular',
        color: 'darkgray'
    },
}));


FeatureInfoAccordion.propTypes = {
    analysisId: PropTypes.number.isRequired,
    // handleReset: PropTypes.func.isRequired,
};

function FeatureInfoAccordion(props) {

    let {featureInfo, setFeatureInfo, style, setStyle, paiOpacity, setPaiOpacity, setAnalysisId, setIndicatorLayers} = useContext(AnalysisContext);
    const [expanded, setExpanded] = React.useState(true);
    const classes = useStyles();
    const {analysisId, handleReset} = props
    const [indicators, setIndicators] = useState(null)
    const [categories, setCategories] = useState(null)
    const [disabled, setDisabled] = React.useState(false);
    const [readOnly, setReadOnly] = React.useState(props.readOnly);
    const [value, setValue] = React.useState("");
    const [analysisFeatures, setAnalysisFeatures] = useState(null)


    //
    // useEffect(() => {
    //     apiAnalysis.getAnalysisByAnalysisIdIndicators({analysis_id: props.analysisId})
    //         .then(body => {
    //             setIndicators(body)
    //             console.log(body)
    //         })
    //         .catch(reason => {
    //             console.log(reason)
    //         })
    //     console.log(props.analysisId, 'id')
    //     setAnalysisId(props.analysisId)
    //
    // }, [])
    //
    // useEffect(() => {
    //     apiAnalysis.getAnalysisByAnalysisIdCategories({analysis_id: props.analysisId})
    //         .then(body => {
    //             console.log(body, "kategorie")
    //             setCategories(body)
    //         })
    //         .catch(reason => {
    //             console.log(reason)
    //         })
    //     // console.log(props.analysisId, 'id')
    //     // setAnalysisId(props.analysisId)
    // }, [])


    useEffect(() => {
        const obj = [
            {
                "label": "Parki i lasy bla bla",
                "name": "Tereny zielone",
                "indicators": [
                    {
                        "layers": [
                            {
                                "geoserver_layer_name": "pai:parks",
                                "name": "Parki"
                            }
                        ],
                        "name": "Blisko parku",
                        "description": "Wskaźnik uwzględnia odległość do najbliższego parku",
                        "unit": {
                            "name": "km",
                            "label": "Odległość:",
                            "conditions": [
                                {
                                    "output": "W analizowanym obszarze",
                                    "input": 0
                                }
                            ]
                        },
                        "components": [
                            {
                                "id": 8,
                                "name": "Najbliższy park",
                                "label": "Najbliższy:",
                                "unit": {
                                    "name": "km",
                                    "label": "Odległość:",
                                    "conditions": [
                                        {
                                            "output": "W analizowanym obszarze",
                                            "input": 0
                                        }
                                    ]
                                }
                            },
                            {
                                "id": 14,
                                "name": "Nazwa najbliższego parku",
                                "label": "Nazwa:",
                                "unit": null
                            }
                        ],
                        "id": 40,
                        "indicatorIsPositive": false
                    },
                    {
                        "layers": [
                            {
                                "geoserver_layer_name": "pai:forests",
                                "name": "Lasy"
                            }
                        ],
                        "name": "Blisko lasu",
                        "description": "Wskaźnik uwzględnia odległość do najbliższego lasu",
                        "unit": {
                            "name": "km",
                            "label": "Odległość:",
                            "conditions": [
                                {
                                    "output": "W analizowanym obszarze",
                                    "input": 0
                                }
                            ]
                        },
                        "components": [],
                        "id": 41,
                        "indicatorIsPositive": false
                    },
                    {
                        "layers": [],
                        "name": "Stopień pokrycia terenami zielonymi",
                        "description": "Wskaźnik uwzględnia procentowy udział powierzchni terenów zielonych (lasy, parki i inne tereny zadrzewione i trawiaste) na analizowanym obszarze",
                        "unit": {
                            "name": "%",
                            "label": "Pokrycie:",
                            "conditions": []
                        },
                        "components": [],
                        "id": 42,
                        "indicatorIsPositive": true
                    }
                ]
            },
            {
                "label": "Labelka dla poziomu hałasu",
                "name": "Poziom hałasu",
                "indicators": [
                    {
                        "layers": [],
                        "name": "Zagrożenie hałasem drogowym",
                        "description": "Wskaźnik uwzględnia tereny zagrożone hałasem spowodowanym sąsiedztwem dróg ekspresowych i autostrad",
                        "unit": {
                            "name": null,
                            "label": null,
                            "conditions": [
                                {
                                    "output": "Występuje",
                                    "input": 1
                                },
                                {
                                    "output": "Nie występuje",
                                    "input": 0
                                }
                            ]
                        },
                        "components": [],
                        "id": 43,
                        "indicatorIsPositive": false
                    },
                    {
                        "layers": [],
                        "name": "Sąsiedztwo obiektu przemysłowego",
                        "description": "Wskaźnik uwzględnia tereny zagrożone hałasem spowodowanym sąsiedztwem obiektów przemysłowych",
                        "unit": {
                            "name": null,
                            "label": null,
                            "conditions": [
                                {
                                    "output": "Występuje",
                                    "input": 1
                                },
                                {
                                    "output": "Nie występuje",
                                    "input": 0
                                }
                            ]
                        },
                        "components": [],
                        "id": 44,
                        "indicatorIsPositive": false
                    }
                ]
            }
        ]
        setAnalysisFeatures(obj)
    }, [])


    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography style={{fontSize: '1rem'}}>Atrakcyjność Lokalna
                    {/*{props.analysisId}*/}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <React.Fragment>
                    {featureInfo == null &&
                    <Typography>
                        <Box fontStyle="italic">Aby poznać szczegóły, kliknij w wybrany obiekt na mapie</Box>
                    </Typography>
                    }
                    {/*{featureInfo !== null &&*/}
                    {/*<FeatureInfo analysisId={props.analysisId}/>*/}
                    {/*}*/}
                </React.Fragment>
            </AccordionDetails>
        </Accordion>


    );
}

export default FeatureInfoAccordion;
