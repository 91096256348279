import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";


function AreaAttractiveness() {
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                aria-controls="areaAtrr-content"
                id="areaAtrr-header"
            >
                <Typography style={{fontSize: '1rem'}}>Atrakcyjność obszaru</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
        </Accordion>


    );
}

export default AreaAttractiveness;
