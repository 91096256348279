import React, {useContext, useEffect, useState} from "react";
import {AnalysisContext} from "../../../context/AnalysisContext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Typography} from "@material-ui/core";
import Progress from "../../analysis/new/steps/sumup/IndicatorLinearProgress";
import FeatureInfoItemValue from "./FeatureInfoItemValue";

FeatureInfoItem.propTypes = {
    indicator: PropTypes.array.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    listItem: {
        fontSize: '3rem'

    }
}));

function FeatureInfoItem(props) {
    const classes = useStyles();
    let {featureInfoComponents, featureInfo} = useContext(AnalysisContext);
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            {(props.indicator.components.length === 0) ?
                <ListItem>
                    <ListItemText>
                        <Typography

                            style={{fontSize: "12px", color: "white"}}
                        >
                            {props.indicator.name}
                            {/*{featureInfo[props.indicator.id]}*/}
                            {/*{JSON.stringify(props.indicator)}*/}
                        </Typography>

                    </ListItemText>
                </ListItem> :
                <>
                    <ListItem
                        onClick={handleClick}>
                        <ListItemText>
                            <Typography
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "bold"

                                }}

                            >
                                {/*{props.indicator.name}*/}
                                {props.indicator.legend_label}
                                {/*{JSON.stringify(props.indicator)}*/}
                            </Typography>

                            {/*{props.indicator.id}*/}
                            {/*= {featureInfo[props.indicator.id]}*/}
                        </ListItemText>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {props.indicator.components.map(el => (
                            <ListItem>
                                {/*<FiberManualRecordIcon*/}
                                {/*    fontSize={"small"}*/}
                                {/*    style={{color: 'rgba(208,203,203,0.55)'}}*/}
                                {/*/>*/}
                                <ListItemText>
                                    <Typography
                                        style={{fontSize: "12px", paddingLeft: "12px"}}
                                    >{el.name}</Typography>
                                </ListItemText>

                                {/*{featureInfoComponents[el.id] !== null &&*/}
                                <FeatureInfoItemValue
                                    unit={el.unit}
                                    componentId={el.id}
                                    // value={"lol"}
                                />

                                {/*}*/}

                            </ListItem>
                        ))}
                    </Collapse>
                </>
            }
        </>
    );
}

export default FeatureInfoItem
