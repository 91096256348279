function getApi() {
    if (process.env.NODE_ENV !== 'production') {
        return "http://localhost:8080";
    } else {
        return "http://stage.pai.eppsandbox02.hosting.ep";
    }
}

export const api = getApi();

export const osr = 'https://api.openrouteservice.org';
export const osrApiKey = '5b3ce3597851110001cf6248d6797329f90140c48d9f90bebda36e54';
export const hereApiKey = 'nXpP3L1yqQB7BXy9SLSb_n0yIu_ba6gg_bhoqwN-UTk';
export const hereApi = 'https://isoline.router.hereapi.com';

// export const geoserver = 'http://193.201.34.28/geoserver';
export const geoserver = 'http://geoserver.eppsandbox02.hosting.ep';
export const corsAnywhere = 'https://cors-anywhere.herokuapp.com/';


