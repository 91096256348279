import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from "@material-ui/core/List";
import {AnalysisContext} from "../../../context/AnalysisContext";
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import FeatureInfoItem from "./FetureInfoItem";
import {apiAnalysis} from "../../../service";
import theme from "../../../theme";


const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'rgb(47,132,240)',
        // backgroundColor: 'rgb(249,249,249)',
        backgroundColor: theme.palette.primary.main,
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        minHeight: "25px",
        height: "20px",

        '&$expanded': {
            minHeight: 25,
        },
    },
    content: {
        '&$expanded': {
            margin: '5px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        // fontSize: theme.typography.pxToRem(10),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function FeatureInfo(props) {
    const classes = useStyles();
    let {featureInfo, setFeatureInfo, style, setStyle, paiOpacity, setPaiOpacity, setAnalysisId, setIndicatorLayers} = useContext(AnalysisContext);
    const [analysisFeatures, setAnalysisFeatures] = useState(null)
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };


    useEffect(() => {
        apiAnalysis.getAnalysisByAnalysisIdFeatureinfo({analysis_id: props.analysisId})
            .then(body => {
                console.log(body)
                setAnalysisFeatures(body)
            }).catch(reason => {
            console.log(reason)
        })
        console.log(analysisFeatures)

    }, [])

    return (
        <div className={classes.root}>
            {analysisFeatures !== null &&
            analysisFeatures.map((element, index) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon
                            style={{color: "white"}}
                        />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {/*<Typography className={classes.heading}>{element.label}</Typography>*/}

                        {/*<Typography>{element.name}</Typography>*/}
                        <Typography style={{color: "white"}}>{element.name}</Typography>
                        {/*<b>{element.name}</b>*/}
                        {/*<Typography>{element.label}</Typography>*/}
                    </AccordionSummary>
                    <AccordionDetails>
                        <List
                            className={classes.root}
                        >
                            {element.indicators.map(indicator => (
                                <FeatureInfoItem indicator={indicator}/>
                            ))
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))
            }

        </div>
    );
}
