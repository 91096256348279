import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import * as PropTypes from "prop-types";
import AnalysisParams from "./sumup/AnalysisParams";
import LocalAttractiveness from "./sumup/LocalAttractiveness";
import AreaAttractiveness from "./sumup/AreaAttractiveness";
import {ErrorContext} from "../../../../context/ErrorContext";
import {apiAnalysis, apiCategories, apiGeocoder} from "../../../../service";
import FeatureInfoAccordion from "../../../maptools/featureInfo/FeatureInfoAccordion";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import HexCharts from "./sumup/HexCharts";
import Box from "@material-ui/core/Box";
import Results from "./sumup/Results";
import RadioGroup from "@material-ui/core/RadioGroup";

AnalysisSumUp.propTypes = {
    analysisId: PropTypes.number.isRequired,
    handleReset: PropTypes.func.isRequired,
};

function AnalysisSumUp(props) {
    const {analysisId, handleReset} = props

    const [openEdit, setOpenEdit] = useState(false);
    const [newName, setNewName] = useState('');

    let {info, setInfo} = useContext(ErrorContext)

    function handleSaveAnalysis() {

        const data = {
            "analysis_name": newName
        }

        console.log({analysis_id: analysisId, body: data})
        apiAnalysis.postAnalysisByAnalysisIdSave({body: data, analysis_id: analysisId})
            .then(body => {
                setInfo({message: body.message, type: 'success'})
            })
            .catch(error => {
                setInfo({message: error.message, type: 'error'})
            })
        setNewName('')
    }

    const handleEditClose = () => {
        setOpenEdit(false);
    };

    const handleNewName = (e) => {
        setNewName(e.target.value)
    };

    const handleEdit = () => {
        console.log(newName, analysisId)
        setOpenEdit(false);
        handleSaveAnalysis()
        // setAnalysisId(null)
    };


    return (
        <>
                {analysisId &&
                // <div style={{maxHeight: '80%', overflowX: 'hidden', overflowY: 'auto'}}>
                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}
                      // maxHeight='30vh'
                      // overflowX= 'hidden' overflowY= 'auto'
                >
                    <Grid item>
                        <Results analysisId={analysisId}/>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <FeatureInfoAccordion analysisId={analysisId}/>*/}
                    {/*</Grid>*/}
                </Grid>

                }

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                    style={{marginTop: 16}}
                >
                    <Grid item>
                        <Button onClick={handleReset} variant={"outlined"} color="primary">Nowa analiza</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setOpenEdit(true)
                            }}
                            variant="contained" color="primary">Zapisz</Button>
                    </Grid>
                </Grid>

            <Dialog
                open={openEdit}
                onClose={handleEditClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Podaj nową nazwę analizy "} {analysisId}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nazwa analizy"
                            type="text"
                            fullWidth
                            onChange={handleNewName}
                        />
                        {/*Let Google help apps determine location. This means sending anonymous location data to*/}
                        {/*Google, even when no apps are running.*/}

                        {/*{analysisId}*/}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary"
                            autoFocus>
                        Anuluj
                    </Button>
                    <Button onClick={handleEdit} color="primary"
                            variant="contained">
                        Tak
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AnalysisSumUp;
