import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider, Slider, Typography} from "@material-ui/core";
import {RadioButtonChecked, RadioButtonCheckedTwoTone} from "@material-ui/icons";

import Button from "@material-ui/core/Button";
import LayersIcon from '@material-ui/icons/Layers';
import IconButton from "@material-ui/core/IconButton";
// import DesignServicesIcon from '@material-ui/icons/DesignServices';
import AddIcon from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import {AnalysisContext} from "../../../context/AnalysisContext";

import Box from "@material-ui/core/Box";
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from "@material-ui/core/List";
// import CircleIcon from '@material-ui/icons/Circle';

import ShowChartIcon from '@material-ui/icons/ShowChart';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import AdjustIcon from '@material-ui/icons/Adjust';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RoomIcon from '@material-ui/icons/Room';
import AddLocationIcon from "@material-ui/icons/AddLocation";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Circle from "react-color/lib/components/circle/Circle";
import {ToolContext} from "../../../context/ToolContext";
// import CircleSwatch from "react-color/lib/components/circle/CircleSwatch";


const useStyles = makeStyles((theme) => ({

    // switcher: {
    //     float: "left",
    //     position: "flex",
    //     zIndex: "1299",
    //     // left: "40px",
    //     // top: "160px",
    //     pointerEvents: "all",
    // },
    // paper: {
    //     // height: 570,
    //     width: 200,
    //     display: "block",
    //     position: "relative",
    //     right: "2px",
    //     // top: "260px",
    //     zIndex: "990",
    //     // position: "fixed",
    //     // right: 5,
    //     // bottom: 110,
    //     // backgroundColor: "white",
    //     // margin: theme.spacing(3),
    //     padding: 4,
    //     pointerEvents: "all",
    //
    // },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function PoiManager() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);


    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    let {
        addUserPoint, setAddUserPoint,
        userPointLabel, setUserPointLabel,
        userPointColor, setUserPointColor,
        hideUserPoint, setHideUserPoint
    } = useContext(ToolContext)

    const addPoint = () => {
        setAddUserPoint(true)
        handleChange()
    }

    const handleChangeColor = (color, event) => {
        setUserPointColor(color.hex)
        console.log(color.hex)
        // this.setState({ background: color.hex });
    };

    function handleInput(e) {
        const newValue = e.target.value;
        setUserPointLabel(newValue)
        console.log(newValue)
    }


    const showHidePoint = () => {
        handleChange()
    }


    return (
        <>
            {/*<div className={classes.switcher}>*/}
            {/*    <IconButton onClick={handleChange} variant="contained" size="small" color="primary"*/}
            {/*                className={classes.button}>*/}
            {/*        <RoomIcon/>*/}
            {/*    </IconButton>*/}
            {/*</div>*/}
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                {/*<div elevation={4} className={classes.paper}>*/}
                {/*<Box*/}
                {/*    width={"200px"}*/}
                {/*    position="relative"*/}
                {/*    // top="31%"*/}
                {/*    left="1%"*/}
                {/*    zIndex="999"*/}
                {/*    pointer-eventts={"all"}*/}
                {/*>*/}
                    <Paper
                        elevation={3}
                        style={{padding: "15px"}}
                    >
                        <Typography>Punkty użytkownika</Typography>
                        <div
                            style={{maxWidth: '170px'}}
                        >
                            <TextField
                                style={{minWidth: '100px', maxidth: '120px'}}
                                // inputProps={{style: {fontSize: 10}}} // font size of input text
                                // InputLabelProps={{style: {fontSize: 10}}}
                                onChange={handleInput}
                                label="etykieta obiektu"/>
                        </div>
                        <div
                            style={{
                                // width: "165px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "8px"
                            }}
                        >
                            <Circle
                                circleSize={13}
                                onChangeComplete={handleChangeColor}
                                colors={['#b80000', '#db3e00', '#FCCB00',
                                    '#008B02', '#00cadf', '#1273DE']}
                            />
                        </div>
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Button
                                    size={"small"}
                                    onClick={() => setAddUserPoint(true)}
                                ><AddLocationIcon/>Dodaj Punkt</Button>

                            </div>
                        </>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end"
                            }}
                        >
                            <>
                                <Button
                                    size={"small"}
                                    onClick={() => setHideUserPoint(!hideUserPoint)}
                                >Pokaż/Ukryj</Button>
                            </>
                            {/*<IconButton*/}
                            {/*    size={"small"}*/}
                            {/*    onClick={handleChange}*/}
                            {/*><CancelIcon/>*/}
                            {/*</IconButton>*/}
                        </div>
                    </Paper>
                {/*</Box>*/}
                {/*</div>*/}
            </Grow>
        </>
    );
}

export default PoiManager
