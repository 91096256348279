import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from "@material-ui/core/Chip";
import {Link} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

MyAnalysisList.propTypes = {
    analysisList: PropTypes.array.isRequired,
}

function MyAnalysisList(props) {
    const classes = useStyles();
    let {analysisList} = props;

    window.document.title = "Platforma Atrakcyjności Inwestycyjnej - list wygenerowanych analiz przestrzennych użytkownika"

    return (
        <>
            {!analysisList.length &&
            <>
                <LinearProgress/>
                <Typography align={"center"}>Trwa ładowanie analiz użytkownika</Typography>
            </>
            }
            <TableContainer>
                {/*TODO sprawdź responsywność*/}
                {/*<TableContainer component={Paper}>*/}
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Id</b></TableCell>
                            <TableCell><b>Data</b></TableCell>
                            <TableCell><b>Nazwa</b></TableCell>
                            <TableCell><b>Kategorie</b></TableCell>
                            <TableCell><b>Szczegóły</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {analysisList &&
                        analysisList.map(analysis => (
                                <TableRow hover>
                                    {/*<td>{analysis.id}</td>*/}
                                    <TableCell
                                        style={{
                                            whiteSpace: "nowrap",
                                            padding: "0px 8px"
                                        }}>{analysis.id}</TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: "nowrap",
                                            padding: "0px 8px"
                                        }}>{analysis.update_time}</TableCell>
                                    {/*<td><Button><PlayCircleFilledWhiteIcon/></Button></td>*/}
                                    {/*<td>{analysis.grid}</td>*/}
                                    <TableCell style={{
                                        whiteSpace: "nowrap",
                                        textAlign: "left",
                                        padding: "0px 8px"
                                    }}>{analysis.name}</TableCell>
                                    <TableCell>
                                        {analysis.categories.map(
                                            category => (
                                                // <Tooltip title={JSON.stringify(category.description)}>
                                                <Chip size="small"
                                                      variant="outlined"
                                                      label={category.name}/>
                                                // </Tooltip>
                                            )
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <Link
                                            aria-label="Przejdź do analizy"
                                            to={`/myanalyses/${analysis.id}`}>
                                            <Button
                                                aria-label="Przejdź do analizy"
                                            ><SearchIcon/></Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default MyAnalysisList
