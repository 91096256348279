import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AnalysisProvider} from "../../context/AnalysisContext";
import {ErrorProvider} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import UpDownButton from "../../utils/UpDownButton";
import MapPoiAnalysis from "./MapPoiAnalysis";
import PoiAnalysisResult from "./PoiAnalysisResult";
import PoiAnalysisAoi from "./PoiAnalysisAoi";
import {PoiAnalysisProvider} from "../../context/PoiAnalysisContext";
import {ToolProvider} from "../../context/ToolContext";



const useStyles = makeStyles((theme) => ({
    gridCustomeight: {
        maxHeight: 'calc(100%-64px)',
        // overflow: 'auto',
        overflow: 'hidden',
        padding: theme.spacing(1),
    },
    mapContainer: {
        width: "auto",
        height: "90vh"
    }
}));

export default function PoiAnalysisPage() {
    window.document.title = "Platforma Atrakcyjności Inwestycyjnej - panel generacji nowych analiz przestrzennych"
    const classes = useStyles();
    return (
        <>
            <ToolProvider>
                <AnalysisProvider>
                    <PoiAnalysisProvider>
                        <ErrorProvider>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.gridCustomeight}>
                                    <Grid
                                        spacing={1}
                                        container direction="column" justify="center" alignItems="stretch"                                     >
                                        <Grid item >
                                            <PoiAnalysisAoi/>
                                        </Grid>
                                        <Grid item>
                                            <PoiAnalysisResult/>
                                        </Grid>
                                    </Grid>
                                    {/*</div>*/}
                                </Grid>

                                <Grid item xs={12} sm={6} md={8} lg={9} xl={9}
                                      // className={classes.gridCustomeight}
                                >
                                    <MapPoiAnalysis id={"_map" + String(Math.random()).substr(5, 5)}/>
                                </Grid>
                                <UpDownButton/>
                            </Grid>
                            <InfoSnackbar/>
                        </ErrorProvider>
                        <UpDownButton/>
                    </PoiAnalysisProvider>
                </AnalysisProvider>
            </ToolProvider>
        </>
    );
}
