import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
// import AnalysisIndocators from "../../AnalysisIndicators";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import {AnalysisContext} from "../../../../../context/AnalysisContext";
import {apiAnalysis, apiMapcolor} from "../../../../../service";
import CircularStatic from "./WaiResult";
import CircularProgressWithLabel from "./WaiResult";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Progress from "./IndicatorLinearProgress";
import {Box, Radio, Slider, Tooltip} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {element} from "prop-types";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";
import theme from "../../../../../theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    labelActive: {
        // fontWeight: 'Bold'
        fontSize: 12,
        color: 'Black'
    },
    labelInactive: {
        fontSize: 12,
        fontWeight: 'Regular',
        color: 'darkgray'
    },
}));


LocalAttractiveness.propTypes = {
    analysisId: PropTypes.number.isRequired,
    // handleReset: PropTypes.func.isRequired,
};

function LocalAttractiveness(props) {

    let {
        featureInfo, setFeatureInfo, style, setStyle, paiOpacity, setPaiOpacity, setAnalysisId, setIndicatorLayers,
        bestAreas, setBestAreas,
        bestAreasVisible, setBestAreasVisible,
        analysisGeomWKT, setAnalysisGeomWKT,
        isAnalysisVisible, setIsAnalysisVisible,
    } = useContext(AnalysisContext);
    const [expanded, setExpanded] = React.useState(true);
    const classes = useStyles();
    const {analysisId, handleReset} = props
    const [indicators, setIndicators] = useState(null)
    const [disabled, setDisabled] = React.useState(false);
    const [readOnly, setReadOnly] = React.useState(props.readOnly);
    const [value, setValue] = React.useState("wynik");
    const [analysisColorInterpolation, setAnalysisColorInterpolation] = useState()
    const [colorInterpoletionExist, setColorInterpoletionExist] = useState(false)
    const [waiColorStyle, setWaiColorStyle] = useState("standard")


    useEffect(() => {
        apiAnalysis.getAnalysisByAnalysisIdIndicators({analysis_id: props.analysisId})
            .then(body => {
                setIndicators(body)
            })
            .catch(reason => {
                console.log(reason)
            })
        console.log(props.analysisId, 'id')
        setAnalysisId(props.analysisId)
    }, [])


    function interpolateAnalysisColors() {
        apiMapcolor.putMapcolorAnalysiscolorinterpolationByAnalysisId({analysis_id: props.analysisId})
            .then(body => {
                console.log(body)
                if (body[0]["WAI"][0].hasOwnProperty('message')) {
                    console.log(body[0]["WAI"][[0]].message)
                } else {
                    setAnalysisColorInterpolation(body[0]["WAI"])
                }
            })
            .catch(reason => {
                console.log(reason)
            })
    }

    useEffect(() => {
        interpolateAnalysisColors()
    }, [colorInterpoletionExist])

    useEffect(() => {
        apiAnalysis.getAnalysisByAnalysisIdBest({analysis_id: props.analysisId, percentage: 5})
            .then(body => {
                // setBestAreas({"geom3":{"lol" : "dasd"}})
                setBestAreas(body)
                // setBestAreas(body.geom)
                // console.log(body)
            })
            .catch(reason => {
                console.log(reason)
            })
    }, [])


    useEffect(() => {
        apiAnalysis.getAnalysisByAnalysisIdGeom({analysis_id: props.analysisId, crs_id: "2180", format: "wkt"})
            .then(body => {
                // console.log(body, "geomWKT")
                setAnalysisGeomWKT(body)
            })
            .catch(reason => {
                console.log(reason)
            })
    }, [])


    const handleChange = (event) => {
        setExpanded(!expanded);
    };

    const handleIndicator = (id, style, layers) => {
        let indicator = {"id": id, "interval": style}
        console.log(indicator)
        //WAI swich feature
        // setStyle(id === value ? "standard" : indicator)
        // setValue(id === value ? null : id)
        //

        setStyle(indicator)
        setValue(id)
        setDisabled((id === value) ? false : true)
        // setIndicatorLayers(layers)
        setIndicatorLayers(id === value ? [] : layers)
    };

    const handleWai = (id, style) => {
        setStyle(style)
        setValue(id)
        setDisabled((id === value) ? false : true)
        // setIndicatorLayers(layers)
    }

    const handleWaiInterpolation = (style) => {
        let indicator = {"id": "wynik", "interval": style}
        console.log(indicator)
        //WAI swich feature
        // setStyle(id === value ? "standard" : indicator)
        // setValue(id === value ? null : id)
        //
        setStyle(indicator)
        setValue("wynikInt")
        setDisabled(("wynikInt" === value) ? false : true)
    };


    const handleChangeOpacity = (event, newValue) => {
        setPaiOpacity(newValue)
    };


    const handleMethodChange = (e) => {
        setWaiColorStyle(e.target.value)
        switch (e.target.value) {
            case "standard":
                setValue("wynik")
                setStyle("standard")
                break;
            case "interpolation":
                setValue("wynik")
                setStyle({"id": "wynik", "interval": analysisColorInterpolation})
                break;
        }
    }

    function handleWaiWithMethod(id) {
        switch (waiColorStyle) {
            case "standard":
                setValue(id)
                setStyle("standard")
                break;
            case "interpolation":
                setValue(id)
                setStyle({"id": id, "interval": analysisColorInterpolation})
                break;
        }
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize={"small"}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography style={{fontSize: '1rem'}}>
                    Szczegóły analizy
                    {/*{props.analysisId}*/}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <React.Fragment>
                    {/*{featureInfo !== null &&*/}
                    {/*<Typography>*/}
                    {/*    <Box fontStyle="italic">Aby poznać szczegóły, kliknij w wybrany obiekt na mapie</Box>*/}
                    {/*</Typography>*/}
                    {/*}*/}
                    {/*{featureInfo == null &&*/}
                    {
                        <Grid container direction="row">
                            <Grid container
                                  spacing={5}
                                  justify="center"
                                  alignItems="center"
                            >
                                {/*<Grid item>*/}
                                {/*    <div style={{paddingBottom: 20}}>*/}
                                {/*        <CircularProgressWithLabel*/}
                                {/*            disabled={disabled}*/}
                                {/*            size={60}*/}
                                {/*            thickness={7}*/}
                                {/*            value={featureInfo.wynik * 100}/>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                                {/*<Grid item>*/}
                                {/*    <div style={{paddingBottom: 20}}>*/}
                                {/*        <Typography align={"left"}*/}
                                {/*                    style={{fontWeight: "bold", 'color': theme.palette.primary.main}}>*/}
                                {/*            <Box>Wskaźnik</Box>*/}
                                {/*            <Box>Atrakcyjności</Box>*/}
                                {/*            <Box>Inewstycyjnej</Box>*/}
                                {/*        </Typography>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}
                            </Grid>


                            <Grid container
                                  spacing={1}
                                  direction="row"
                                // justify="center"
                                  justify="space-around"
                                // justify="center"
                                //   alignItems="center"
                            >
                                <div
                                    style={{maxHeight: '42vh', overflowX: 'hidden', overflowY: 'auto', padding: '8px'}}>

                                    <RadioGroup row>
                                        {/*<Grid container>*/}
                                        {/*{console.log(indicators)}*/}
                                        <>
                                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                                {/*<div style={{padding: 5}}>*/}
                                                <Typography align={"left"}

                                                >
                                                    <Box>Najbardziej atrakcyjne obszary</Box>
                                                </Typography>
                                                {/*</div>*/}
                                            </Grid>
                                            <Grid item
                                                  spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}
                                                  alignContent={"stretch"}
                                                // justifyContent="center"
                                                // alignContent={"space-around"}
                                            >
                                                {/*<div style={{align: ""}}>*/}
                                                {/*{JSON.stringify((bestAreas === null))}*/}


                                                {(bestAreas === null) ? <div
                                                    style={{
                                                        paddingLeft: "10px",
                                                    }}>
                                                    <CircularProgress size={'1.3rem'}/>
                                                </div> : <Switch
                                                    size={"small"}
                                                    color={"primary"}
                                                    checked={bestAreasVisible}
                                                    onChange={() => {
                                                        setBestAreasVisible(!bestAreasVisible)
                                                    }}
                                                />}
                                                {/*{(bestAreas === null) &&*/}

                                                {/*}*/}

                                                {/*/!*</div>*!/*/}
                                                {/*{bestAreas !== null &&*/}
                                                {/*}*/}

                                            </Grid>


                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                {/*<div style={{padding: 5}}>*/}
                                                <FormControlLabel
                                                    classes={(value === "wynik") ? {label: classes.labelActive} : {label: classes.labelInactive}}
                                                    // value={element.indicator.name}
                                                    checked={true}
                                                    control={<Radio size={"small"} color="primary"
                                                                    disabled={!isAnalysisVisible}
                                                        // disabled={(element.style === null)}
                                                        //             checked={value === value}
                                                                    checked={value === "wynik"}
                                                                    onClick={() =>
                                                                        handleWaiWithMethod("wynik")
                                                                    }
                                                        // onClick={handleMethodChange}

                                                    />}
                                                    label={"WAI"}

                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={waiColorStyle}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    style={{fontSize: "0.8rem"}}
                                                    disabled={value !== "wynik" || !isAnalysisVisible}
                                                    onChange={handleMethodChange}
                                                >
                                                    <MenuItem
                                                        style={{fontSize: "0.8rem"}}
                                                        value={"interpolation"}>Interpolacja</MenuItem>
                                                    <MenuItem
                                                        style={{fontSize: "0.8rem"}}
                                                        value={"standard"}>Równe przedziały</MenuItem>

                                                </Select>
                                                {/*</div>*/}
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                <div style={{paddingTop: 5}}>
                                                    <Switch size={"small"}
                                                            checked={isAnalysisVisible}
                                                            color="primary"
                                                            onChange={(event) => setIsAnalysisVisible(!isAnalysisVisible)}
                                                    />
                                                </div>

                                            </Grid>
                                            {/*<Button onClick={handleWaiInterpolation(analysisColorInterpolation)}></Button>*/}
                                            {/*{colorInterpoletionExist &&*/}
                                            {/*<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>*/}
                                            {/*    /!*<div style={{padding: 5}}>*!/*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        classes={(value === "wynikInt") ? {label: classes.labelActive} : {label: classes.labelInactive}}*/}
                                            {/*        // value={element.indicator.name}*/}
                                            {/*        checked={true}*/}
                                            {/*        control={<Radio size={"small"} color="primary"*/}
                                            {/*                        disabled={!isAnalysisVisible}*/}
                                            {/*            // disabled={(element.style === null)}*/}
                                            {/*                        checked={value === "wynikInt"}*/}
                                            {/*                        onClick={() => handleWaiInterpolation(analysisColorInterpolation)}*/}
                                            {/*        />}*/}
                                            {/*        label={"WAI (interpolacja)"}*/}
                                            {/*    />*/}
                                            {/*    /!*</div>*!/*/}
                                            {/*</Grid>*/}


                                            {/*}*/}
                                            {/*{!colorInterpoletionExist &&*/}
                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                <div style={{padding: 5}}>
                                                    {/*<LinearProgress/>*/}
                                                </div>
                                            </Grid>

                                            {/*}*/}

                                            {/*<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>*/}
                                            {/*    <div style={{paddingTop: 10}}>*/}
                                            {/*        <Switch/>*/}
                                            {/*    </div>*/}
                                            {/*    /!*<span>{JSON.stringify(element.indicator.layers)}</span>*!/*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                <div style={{padding: 5}}>
                                                    <Typography>Obiekty Twojej analizy</Typography>
                                                </div>

                                            </Grid>
                                        </>

                                        {(indicators !== null) &&
                                        indicators.map(element => (
                                            <>
                                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                                    {/*<div style={{padding: 5}}>*/}
                                                    <FormControlLabel
                                                        classes={(value === element.indicator.id) ? {label: classes.labelActive} : {label: classes.labelInactive}}
                                                        value={element.indicator.name}
                                                        control={<Radio size={"small"} color="primary"
                                                            // disabled={true}
                                                                        disabled={(!isAnalysisVisible || (element.style === null))}
                                                            // disabled={(element.style === null)}
                                                            // checked={value === element.indicator.id}
                                                                        onClick={() => handleIndicator(element.indicator.id, element.style, element.indicator.layers)}/>}
                                                        label={element.indicator.legend_label}
                                                        // label={element.indicator.name  '+ JSON.stringify(element.indicator.indicatorIsPositive)}
                                                    />

                                                    {/*{JSON.stringify(element.style)}*/}
                                                    {/*{JSON.stringify((element.style===null))}*/}
                                                    {/*{String(!isAnalysisVisible || (element.style!==null))}*/}

                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                    <div style={{paddingTop: 5}}>
                                                        {/*<Switch/>*/}
                                                        {/*<p>{JSON.stringify(element.indicator.layers)}</p>*/}
                                                        {/*<Progress*/}
                                                        {/*    conditions={element.indicator.unit?.conditions}*/}
                                                        {/*    unit={element.indicator.unit}*/}
                                                        {/*    min={element.min_value}*/}
                                                        {/*    max={element.max_value}*/}
                                                        {/*    indicatorIsPositive={element.indicator.indicatorIsPositive}*/}
                                                        {/*    value={featureInfo[element.indicator.id]}/>*/}


                                                        {(element.indicator.layers.length > 0) && localStorage.pai_isLogged &&
                                                        // localStorage.acc


                                                        <Switch size={"small"} color="primary"
                                                            // disabled={localStorage.pai_isLogged}
                                                                onChange={(event) => {
                                                                    setIndicatorLayers({
                                                                        visibleOnMap: event.target.checked,
                                                                        value: element.indicator.layers
                                                                    })
                                                                }}
                                                        />

                                                        }
                                                    </div>

                                                </Grid>
                                            </>
                                        ))
                                        }
                                    </RadioGroup>
                                </div>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5} spacing={0}>
                                    Transparentność analizy
                                </Grid>
                                <Grid item xs={7} spacing={0}>
                                    <Slider
                                        disabled={!isAnalysisVisible}
                                        defaultValue={paiOpacity}
                                        // getAriaValueText={valuetext}
                                        // aria-labelledby="discrete-slider"
                                        onChange={handleChangeOpacity}
                                        valueLabelDisplay="auto"
                                        step={0.1}
                                        marks
                                        min={0}
                                        max={1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }


                    {/*                        /!*                    /!*<Tooltip placement={"top"}*!/*!/*/}
                    {/*                        /!*                    /!*        // // title={element.style !== null && element.style}*!/*!/*/}
                    {/*//  title={element.style !== null &&*/}
                    {/*                        /!*                    /!*        //      <table>*!/*!/*/}
                    {/*                        /!*                    /!*        //          {element.style.map(el => (*!/*!/*/}
                    {/*                        /!*                    /!*        //                  <thttps://www.youtube.com/watch?v=ImPXMsnc2F4r>*!/*!/*/}
                    {/*                        /!*                    /!*        //                      <td> {el.low_lim} - {el.up_lim}</td>*!/*!/*/}
                    {/*                        /!*                    /!*        //                      /!*<td> {el.low_lim.toFixed(1)} - {el.up_lim.toFixed(1)}</td>*!/*!/*!/*/}
                    {/*                        /!*                    /!*        //                      <td>*!/*!/*/}
                    {/*                        /!*                    /!*        //                          <FiberManualRecordSharpIcon*!/*!/*/}
                    {/*                        /!*                    /!*        //                              style={{color: "rgb(" + el.RGB + ")"}}*!/*!/*/}
                    {/*                        /!*                    /!*        //                              fontSize={"small"}*!/*!/*/}
                    {/*                        /!*                    /!*        //                          />*!/*!/*/}
                    {/*                        /!*                    /!*        //                      </td>*!/*!/*/}
                    {/*                        /!*                    /!*        //                  </tr>*!/*!/*/}
                    {/*                        /!*                    /!*        //              ))}*!/*!/*/}
                    {/*                        /!*                    /!*        //      </table>*!/*!/*/}
                    {/*                        /!*                    /!*        //  }*!/*!/*/}
                    {/*                        /!*                    /!*        //  aria-label="add">*!/*!/*/}
                    {/*                        <FormControlLabel*/}
                    {/*                            classes={(value === element.indicator.id) ? {label: classes.labelActive} : {label: classes.labelInactive}}*/}
                    {/*                            value={element.indicator.name}*/}
                    {/*                            control={<Switch size={"small"} color="primary"*/}
                    {/*                                             checked={value === element.indicator.id}*/}
                    {/*                                             onClick={() => handleIndicator(element.indicator.id, element.style)}*/}
                    {/*                            />}*/}
                    {/*                            label={element.indicator.name}*/}
                    {/*                        />*/}

                    {/*// </Tooltip>*/}


                </React.Fragment>
            </AccordionDetails>
        </Accordion>
    );
}

export default LocalAttractiveness;
