import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider, Typography} from "@material-ui/core";
import {RadioButtonChecked, RadioButtonCheckedTwoTone} from "@material-ui/icons";
import AnalysisSumUp from "../analysis/new/steps/AnalysisSumUp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AreaAttractiveness from "../analysis/new/steps/sumup/AreaAttractiveness";
import LocalAttractiveness from "../analysis/new/steps/sumup/LocalAttractiveness";
import Slide from "@material-ui/core/Slide";
import AnalysisParams from "../analysis/new/steps/sumup/AnalysisParams";
import Button from "@material-ui/core/Button";
import LayersIcon from '@material-ui/icons/Layers';
import IconButton from "@material-ui/core/IconButton";
// import DesignServicesIcon from '@material-ui/icons/DesignServices';
import AddIcon from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import {AnalysisContext} from "../../context/AnalysisContext";
import OverLayerElement from "./layerSwitcher/OverLayerElement";
import BaseLayerRadioGroup from "./layerSwitcher/BaseLayerRadioGroup";
import OverLayerGroup from "./layerSwitcher/OverLayerGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Spinner from "./Spinner";
import AddressAutocomplete from "../../utils/AddressAutocomplete";
import Box from "@material-ui/core/Box";
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from "@material-ui/core/List";
// import CircleIcon from '@material-ui/icons/Circle';

import ShowChartIcon from '@material-ui/icons/ShowChart';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import AdjustIcon from '@material-ui/icons/Adjust';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const useStyles = makeStyles((theme) => ({

    // container: {
    //     height: 180,
    //     width: 400,
    //     display: "block",
    //     position: "absolute",
    //     // zIndex: "990",
    //     zIndex: "1299",
    //     right: "0px",
    //     top: "160px",
    // },
    switcher: {
        // float: "left",
        // position: "flex",
        zIndex: "1299",
        // left: "80px",
        // top: "80px",
        pointerEvents: "all",

    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "relative",
        right: "2px",
        // top: "260px",
        zIndex: "1299",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4,
        pointerEvents: "all",
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function MeasureTools() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);


    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    let {
        measureLine, setMeasureLine,
        measurePolyline, setMeasurePolygon,
        measurePoint, setMeasurePoint,
        clearMeasures, setClearMeasures
    } = useContext(AnalysisContext)

    const startMeasureLine = () => {
        setMeasureLine(true)
        handleChange()
    }

    const startMeasurePolygon = () => {
        setMeasurePolygon(true)
        handleChange()
    }
    const startMeasurePoint = () => {
        setMeasurePoint(true)
        handleChange()
    }

    const clear = () => {
        setClearMeasures(true)
        handleChange()
    }

    return (
        <>
            {/*<div className={classes.switcher}>*/}
                <IconButton onClick={handleChange} variant="contained" size="small" color="primary"
                            className={classes.button}>
                    <MultilineChartIcon/>
                </IconButton>
            {/*</div>*/}
            {/*<Grow*/}
            {/*    in={checked}*/}
            {/*    style={{transformOrigin: "999 999 0"}}*/}
            {/*    {...(checked ? {timeout: 1000} : {})}*/}
            {/*>*/}
                {/*<div elevation={4} className={classes.paper}>*/}
                {/*<Box*/}
                {/*    component={"div"}*/}
                {/*    width={"200px"}*/}
                {/*    position="absolute"*/}
                {/*    pointerEvents={"all"}*/}
                {/*    // top="21%"*/}
                {/*    left="1%"*/}
                {/*    zIndex="999"*/}

                {/*>*/}
                {/*    <Paper>*/}
                        <List>
                            <ListItem button onClick={startMeasureLine}>
                                <ListItemIcon>
                                    <ShowChartIcon />
                                </ListItemIcon>
                            <ListItemText>Pomiar długości</ListItemText>
                            </ListItem>
                            <ListItem button onClick={startMeasurePolygon}>
                                <ListItemIcon>
                                    <CropLandscapeIcon/>
                                </ListItemIcon>
                                <ListItemText>Pomiar powierzchni</ListItemText>
                            </ListItem>
                            <ListItem button onClick={startMeasurePoint}>
                                <ListItemIcon>
                                    <AdjustIcon/>
                                </ListItemIcon>
                                <ListItemText>Pomiar wysokości</ListItemText>
                            </ListItem>
                            <ListItem button onClick={clear}>
                                <ListItemIcon>
                                    <DeleteForeverIcon/>
                                </ListItemIcon>
                                <ListItemText>Wyczyść pomiary</ListItemText>
                            </ListItem>
                        </List>
                    {/*</Paper>*/}
                {/*</Box>*/}
                {/*</div>*/}
            {/*</Grow>*/}
        </>
    );
}

export default MeasureTools
