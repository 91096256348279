import React, {useContext, useEffect} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OverLayerElement from "./OverLayerElement";
import L from "leaflet";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import instance from "../../../api/SettingsInstance";
import {apiIndicators} from "../../../service";

const useStyles = makeStyles((theme) => ({
    labelActive: {
        // fontWeight: 'Bold'
        fontSize: 12,
        color: 'Black'
    },
    labelInactive: {
        fontSize: 12,
        fontWeight: 'Regular',
        color: 'darkgray'
    },
}));

function OverLayerGroup() {

    const classes = useStyles();

    // const [layerList, setLayerList] = React.useState([
    //     // {
    //     //     type: "tms",
    //     //     label: "Google Orto",
    //     //     url: "http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
    //     //     options: {
    //     //         layerName: "googleOrto"
    //     //     }
    //     // },
    //     {
    //         "type": "wms",
    //         label: "KIUT",
    //         "minZlevel": 16,
    //         "url": "https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaUzbrojeniaTerenu",
    //         "options": {
    //             "layers": "przewod_wodociagowy,przewod_kanalizacyjny,przewod_gazowy,przewod_elektroenergetyczny,przewod_telekomunikacyjny,przewod_cieplowniczy",
    //             "format": "image/png",
    //             "transparent": true,
    //             "attribution": "test",
    //             "layerName": "KIUT",
    //             // tileSize: 128,
    //         }
    //     },
    //     {
    //         "type": "wms",
    //         label: "EGIB",
    //         "minZlevel": 17,
    //         "url": "https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow",
    //         "options": {
    //             layers: 'kontury,dzialki,numery_dzialek',
    //             format: 'image/png',
    //             transparent: true,
    //             // tileSize: 128,
    //             attribution: "test",
    //             maxZoom: 21,
    //             layerName: "EGIB"
    //         }
    //     },
    //     // {
    //     //     "type": "wmts",
    //     //     label: "Ortofotomapa (HR)",
    //     //     "url": 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/HighResolution',
    //     //     "options": {
    //     //         format: 'image/png',
    //     //         tileSize: 512,
    //     //         version: '1.0.0',
    //     //         transparent: true,
    //     //         origin: [850000.0, 100000.0],
    //     //         scales: [30238155.714285716, 15119077.857142858, 7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
    //     //         tilematrixSet: 'EPSG:2180',
    //     //         opacity: 1,
    //     //         crossOrigin: true,
    //     //         minZoom: 5,
    //     //         layerName: "orto_wmts_hr"
    //     //     }
    //     // },
    //     {
    //         "type": "wmts",
    //         label: "Hipsometria",
    //         "url": 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/NMT/GRID1/WMTS/Hypsometry',
    //         "options": {
    //             service: "WMTS",
    //             format: 'image/png',
    //             tileSize: 512,
    //             style: 'deafault',
    //             version: '1.0.0',
    //             transparent: true,
    //             origin: [850000.0, 100000.0],
    //             scales: [7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
    //             tilematrixSet: 'EPSG:2180',
    //             opacity: 0.5,
    //             crossOrigin: true,
    //             minZoom: 5,
    //             layerName: "hipso"
    //         }
    //     },
    //     {
    //         "type": "wmts",
    //         label: "Cieniowanie",
    //         "url": 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/NMT/GRID1/WMTS/ShadedRelief',
    //         "options": {
    //             service: "WMTS",
    //             format: 'image/png',
    //             tileSize: 512,
    //             style: 'deafault',
    //             version: '1.0.0',
    //             transparent: true,
    //             origin: [850000.0, 100000.0],
    //             scales: [7559538.928571429, 3779769.4642857146, 1889884.7321428573, 944942.3660714286, 472471.1830357143, 236235.59151785716, 94494.23660714286, 47247.11830357143, 23623.559151785714, 9449.423660714287, 4724.711830357143, 1889.8847321428573, 944.9423660714286, 472.4711830357143],
    //             tilematrixSet: 'EPSG:2180',
    //             opacity: 0.3,
    //             crossOrigin: true,
    //             minZoom: 5,
    //             layerName: "relief",
    //             // jwt: localStorage.pai_token
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "Hipsometria dynamiczna",
    //         "url": 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/NMT/GRID1/WMS/Hypsometry',
    //         "options": {
    //             layers: 'ISOK_HipsoDyn',
    //             format: 'image/jpeg',
    //             transparent: true,
    //             maxZoom: 21,
    //             version: "1.3.0",
    //             layerName: "hipso_dynamic",
    //             // width: 438,
    //             // height: 689,
    //             opacity: 0.3
    //         }
    //     },
    //     {
    //         "type": "wms",
    //         label: "Demografia",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:population_density",
    //             format: 'image/png',
    //             transparent: true,
    //             tiled: false,
    //             layerName: "demo"
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "KMZB Heatmap",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:kmzb",
    //             format: 'image/png',
    //             styles: 'pai:kmzb_heatmap',
    //             transparent: true,
    //             // tiled: false,
    //             layerName: "kmzb"
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "KMZB Cluster",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:kmzb",
    //             format: 'image/png',
    //             styles: 'pai:kmzb_clustered',
    //             transparent: true,
    //
    //             // tiled: false,
    //             layerName: "kmzb_clustered"
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "Dostępność stacji kolejowych",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:railway_isochrone",
    //             format: 'image/png',
    //             transparent: true,
    //             layerName: "railway_isochrone"
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "Dostępność stacji metra",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:metro_isochrone",
    //             format: 'image/png',
    //             transparent: true,
    //             layerName: "metro_isochrone"
    //         }
    //     },
    //     {
    //         "type": "nonTiled",
    //         "label": "Dostępność przystanków tramwajowych i autobusowych",
    //         "url": instance.geoserverUrl + "/pai/wms?",
    //         "options": {
    //             jwt: JSON.parse(localStorage.pai_tokens).access_token,
    //             layers: "pai:bus_tram_isochrone",
    //             format: 'image/png',
    //             transparent: true,
    //             layerName: "bus_tram_isochrone"
    //         }
    //     },
    //
    //
    // ]);
    const [layerList, setLayerList] = React.useState([]);
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        apiIndicators.getSystemLayersListByLayerType({layer_type:"thematic"})
            .then(body => {
                setLayerList(body)
            })
    }, [])


    return (
        <FormControl component="fieldset" style={{padding: "10px"}}>
            <FormLabel component="legend">Warstwy tematyczne</FormLabel>

            {layerList.map((el, idx) => {
                return <FormControlLabel
                    key={`${el.options.layerName}-${idx}`}
                    classes={(value === value) ? {label: classes.labelActive} : {label: classes.labelInactive}}
                    control={<OverLayerElement
                        minZlevel={el.min_zlevel}
                        name={el.options.layerName}
                        value={el}/>}
                    label={el.label}
                />
            })
            }

        </FormControl>
    );
}

export default OverLayerGroup
