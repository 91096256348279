import proj4 from 'proj4';
import {apiExternal} from "../service";

export function getColor(d) {
    return d > 0.9 ? '#00962d' :
        d > 0.8 ? '#2fb11b' :
            d > 0.7 ? '#5fcc09' :
                d > 0.6 ? '#93e100' :
                    d > 0.5 ? '#c9f000' :
                        d > 0.4 ? '#ffff00' :
                            d > 0.3 ? '#ffca00' :
                                d > 0.2 ? '#fe9500' :
                                    d > 0.1 ? '#f66200' :
                                        d > 0 ? '#e73102' :
                                            d >= 0 ? '#d70003' :
                                                '#ffffff';
}

export function getIndicatorColor(hexagonValue, style) {
    let result;
    const d = (hexagonValue === 't') ? 1 :
        (hexagonValue === 'f') ? 0 :
            parseFloat(hexagonValue)

    style.forEach((element) => {
        if (d <= element.up_lim && d > element.low_lim) {
            result = `rgb(${element.RGB})`
        } else if (d === 0) {
            result = `rgb(${style[0].RGB})`
        } else if (style[0].low_lim === d) {
            result = `rgb(${style[0].RGB})`
        }
    })
    return result
}


export async function gugikHeights(lng, lat) {
    let plpoint = proj4('EPSG:4326', 'EPSG:2180', [lng, lat]);
    const cors = 'https://cors-anywhere.herokuapp.com/';
    // const cors = '';
    let data
    return apiExternal.getApisNmt({x: plpoint[1], y: plpoint[0]})
        .then((body) => {
            return data = body
        })
}


export function convertToWKT(array) {
    let array2180 = []
    array.forEach(element => {
        var el = element.split(",").map(x => parseFloat(x))
        // console.log([el[1], el[0]])
        array2180.push(proj4('EPSG:4326', 'EPSG:2180', [el[1], el[0]]))
    })

    let wkt_str2180 = 'POLYGON((';
    array2180.forEach(function (p, i) {
        // console.log(p[0].toFixed(2) + ' ' + p[1].toFixed(2))
        if (i < array2180.length - 1) wkt_str2180 = wkt_str2180 + p[0].toFixed(0) + ' ' + p[1].toFixed(0) + ', ';
        else wkt_str2180 = wkt_str2180 + p[0].toFixed(0) + ' ' + p[1].toFixed(0) + '))';
    })

    return wkt_str2180
}
