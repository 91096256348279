import React from 'react';
import IndicatorItem from "./IndicatorItem";
import Grid from "@material-ui/core/Grid";


export default function IndicatorList(props) {
    const [indicators, setIndicators] = React.useState(props.indicators)

    return (
        <Grid container>
            {indicators &&
            indicators.map(indicator =>
                <IndicatorItem indicator={indicator}/>
            )}
        </Grid>
    );
}
