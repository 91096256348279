import React, {createContext, useState} from 'react';

export const AnalysisContext = createContext();
export const defaultAddressPoint = [41.21128994521463, -8.56270226028742]
// export const defaultAddressPoint = [41.21128994521463, -8.56270226028742]
export const defaultStyle = 'standard'
export const defaultTime = 0.0000001
export const defaultIndicatorLayers = []
export const defaultParcel = null

export const defaultPolygonDrawing = false


export const AnalysisProvider = props => {
    const [inputGeometry, setInputGeometry] = useState(null);

    const [userCategories, setUserCategories] = useState();

    const [analysisId, setAnalysisId] = useState(null);

    const [analysisAoi, setAnalysisAoi] = useState(null);

    const [analysisGeometry, setAnalysisGeometry] = useState();

    const [indicatorsList, setIndicatorsList] = useState([])

    const [radius, setRadius] = useState(null);

    const [time, setTime] = useState(defaultTime);

    const [transportType, setTransportType] = useState(null);

    // const [radius2, setRadius2] = useState(null);

    const [aoiActive, setAoiActive] = useState(false);

    const [addressPoint, setAddressPoint] = useState(null);

    const [featureInfo, setFeatureInfo] = useState(null);
    const [featureInfoComponents, setFeatureInfoComponents] = useState(null)
    const [hexPercentilesInfo,setHexPercentilesInfo] = useState(null);
    const [paiOpacity, setPaiOpacity] = useState(0.5);

    const [style, setStyle] = useState(defaultStyle);
    const [bestAreas, setBestAreas] = useState(null);
    const [bestAreasVisible, setBestAreasVisible] = useState(false);

    const [polygonDrawing, setPolygonDrawing] = useState(defaultPolygonDrawing);
    const [removePolygon, setRemovePolygon] = useState(false);
    const [polyAoiValidate, setPolyAoiValidate] = useState(false);
    const [analysisGeomWKT, setAnalysisGeomWKT] = useState(null)


    const [indicatorLayers, setIndicatorLayers] = useState(defaultIndicatorLayers);
    const [parcel, setParcel] = useState(defaultParcel);


    const [spinnerMsg, setSpinnerMsg] = useState([]);
    const [spinnerErr, setSpinnerErr] = useState("");


    const [isAnalysisVisible, setIsAnalysisVisible] = useState(true);
    const [isAoiToggleGroupDisabled, setIsAoiToggleGroupDisabled] = useState(false);



    const addSpinnerMsg = (value) => {
        setSpinnerMsg( arr => [...arr, value]);
    }

    const removeSpinnerMsg = value => {
        setSpinnerMsg(spinnerMsg.filter(item => item !== value))
    }



    const setNewIndicatorsList = (values) => {
        setIndicatorsList([...indicatorsList, values]);
    }

    function resetAnalysisData() {
        setRadius(null);
        setTime(defaultTime)
        setTransportType(null)
        setInputGeometry(null)
    }

    const updateIndicatorsList = (index, value) => {
        let newArr = [...indicatorsList];
        newArr[index].importance = value;
        setIndicatorsList(newArr);
    }

    const removeFromIndicatorsList = (index) => {
        let newArr = [...indicatorsList];
        newArr.splice(index, 1);
        setIndicatorsList(newArr);
    }

        return (
        <AnalysisContext.Provider
            value={{
                inputGeometry, setInputGeometry,
                userCategories, setUserCategories,
                analysisId, setAnalysisId,
                analysisGeometry, setAnalysisGeometry,
                indicatorsList, setIndicatorsList,
                setNewIndicatorsList,
                updateIndicatorsList,
                removeFromIndicatorsList,
                radius, setRadius,
                time, setTime,
                addressPoint, setAddressPoint,
                transportType, setTransportType,
                analysisAoi, setAnalysisAoi,
                featureInfo, setFeatureInfo,
                style, setStyle,
                parcel, setParcel,
                aoiActive, setAoiActive,
                resetAnalysisData,
                paiOpacity, setPaiOpacity,
                indicatorLayers, setIndicatorLayers,
                polygonDrawing, setPolygonDrawing,
                polyAoiValidate, setPolyAoiValidate,
                removePolygon, setRemovePolygon,

                spinnerMsg, addSpinnerMsg, removeSpinnerMsg,
                spinnerErr, setSpinnerErr,
                bestAreas, setBestAreas,
                bestAreasVisible, setBestAreasVisible,
                analysisGeomWKT, setAnalysisGeomWKT,
                featureInfoComponents, setFeatureInfoComponents,
                hexPercentilesInfo,setHexPercentilesInfo,

                isAnalysisVisible, setIsAnalysisVisible,
                isAoiToggleGroupDisabled, setIsAoiToggleGroupDisabled


            }}>
            {props.children}
        </AnalysisContext.Provider>
    )
};
