import React, {createContext, useReducer, useState} from 'react';




export const ToolContext = createContext();

export const defaultBaseLayers = {
    "id": 11,
    "uri": "",
    "service_type": "tmsFilter",
    // "label": "OSM",
    "layer_source": "external",
    "options": {
        "layerName": "osm",
        "attribution": "test",
        "maxZom": 21,
        "filter": [
            "bright:102%",
            "contrast:110%",
            "hue:29deg",
            "saturate:29%"
        ],
        "baseLayer": true
    },
    "min_zlevel": 0
}
export const defaultOverLayers = {visibleOnMap: false, value: {url: "", options: {name: ""}}}


export const ToolProvider = props => {


    const [routeOrigin, setRouteOrigin] = useState(null)
    const [addRouteOrigin, setAddRouteOrigin] = useState(false)
    const [routeDestination, setRouteDestination] = useState(null)
    const [addRouteDestination, setAddRouteDestination] = useState(false)
    const [routeTransportType, setRouteTransportType] = useState(null)
    const [generateRoute, setGenerateRoute] = useState(false)
    const [removeRoutes, setRemoveRoutes] = useState(false)


    const [addUserPoint, setAddUserPoint] = useState(false);
    const [userPointLabel, setUserPointLabel] = useState('');
    const [userPointColor, setUserPointColor] = useState('#1273de');
    const [hideUserPoint, setHideUserPoint] = useState(false);

    const [activeMeasureTool, setActiveMeasureTool] = useState(false)
    const [measureLine,setMeasureLine] = useState(false);
    const [clearMeasures,setClearMeasures] = useState(false);
    const [measurePolygon,setMeasurePolygon] = useState(false);
    const [measurePoint,setMeasurePoint] = useState(false);

    const [isochroneStart,setIsochroneStart] = useState(null);
    const [addIsoPoint, setAddIsoPoint] = useState(false);
    const [generateIso, setGenerateIso] = useState(false);
    const [isoTime, setIsoTime] = useState(null);
    const [removeIso, setRemoveIso] = useState(false);
    const [isoTransportType, setIsoTransportType] = useState(null);

    const [mapZoomLvl, setMapZoomLvl] = useState();

    const [baseLayer, setBaseLayer] = useState(defaultBaseLayers);
    const [overLayer, setOverLayer] = useState(defaultOverLayers);

    return (
        <ToolContext.Provider
            value={{
                routeOrigin, setRouteOrigin,
                routeDestination, setRouteDestination,
                routeTransportType, setRouteTransportType,
                generateRoute, setGenerateRoute,
                removeRoutes, setRemoveRoutes,
                addRouteOrigin, setAddRouteOrigin,
                addRouteDestination, setAddRouteDestination,


                addUserPoint, setAddUserPoint,
                userPointLabel, setUserPointLabel,
                userPointColor, setUserPointColor,
                hideUserPoint, setHideUserPoint,

                activeMeasureTool, setActiveMeasureTool,
                measureLine, setMeasureLine,
                measurePolygon, setMeasurePolygon,
                measurePoint, setMeasurePoint,
                clearMeasures, setClearMeasures,

                isochroneStart, setIsochroneStart,
                addIsoPoint, setAddIsoPoint,
                generateIso, setGenerateIso,
                isoTime, setIsoTime,
                isoTransportType, setIsoTransportType,
                removeIso, setRemoveIso,

                mapZoomLvl, setMapZoomLvl,
                baseLayer, setBaseLayer,
                overLayer, setOverLayer,
            }}>
            {props.children}
        </ToolContext.Provider>
    )
};
