import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import {AnalysisContext} from "../../../context/AnalysisContext";
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import {Slider, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Grid from "@material-ui/core/Grid";
import AddLocationIcon from '@material-ui/icons/AddLocation';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from "@material-ui/core/TextField";
import AddressAutocomplete from "../../../utils/AddressAutocomplete";
import Box from "@material-ui/core/Box";
import {ToolContext} from "../../../context/ToolContext";

const useStyles = makeStyles((theme) => ({

    // container: {
    //     height: 180,
    //     width: 400,
    //     display: "block",
    //     position: "absolute",
    //     zIndex: "990",
    //     right: "0px",
    //     top: "160px",
    // },
    switcher: {
        float: "left",
        position: "flex",
        zIndex: "1299",
        left: "0px",
        pointerEvents: "all",
        // top: "120px",
    },
    paper: {
        // height: 570,
        width: 200,
        display: "block",
        position: "relative",
        right: "2px",
        // top: "260px",
        zIndex: "990",
        // position: "fixed",
        // right: 5,
        // bottom: 110,
        // backgroundColor: "white",
        // margin: theme.spacing(3),
        padding: 4,
        pointerEvents: "all",

    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        // borderRadius: "5em"
    },

}));

function RouteToolbar() {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);
    const [view, setView] = React.useState(null);
    const [selected, setSelected] = React.useState(null)

    const handleChange = () => {
        setChecked((prev) => !prev);
        // setIsoTime(10*60)
    };

    let {
        routeOrigin, setRouteOrigin,
        routeDestination, setRouteDestination,
        routeTransportType, setRouteTransportType,
        generateRoute, setGenerateRoute,
        removeRoutes, setRemoveRoutes,
        addRouteOrigin, setAddRouteOrigin,
        addRouteDestination, setAddRouteDestination

    } = useContext(ToolContext)


    const handleChangeTransportType = (event, nextView) => {
        setView(nextView);
        setSelected(nextView)
        console.log(nextView)
        setRouteTransportType(nextView)
    };


    const clear = () => {
        handleChange()
    }

    // const addNewIsoPoint = () => {
    //     setAddIsoPoint(true)
    // }
    //
    // const genere = () => {
    //     setAddIsoPoint(true)
    // }


    return (
        <>
            {/*<div className={classes.switcher}>*/}
            {/*    <IconButton onClick={handleChange} variant="contained" size="small" color="primary"*/}
            {/*                className={classes.button}>*/}
            {/*        <DirectionsCarIcon/>*/}
            {/*    </IconButton>*/}
            {/*</div>*/}
            <Grow
                in={checked}
                style={{transformOrigin: "999 999 0"}}
                {...(checked ? {timeout: 1000} : {})}
            >
                {/*<div elevation={4} className={classes.paper}>*/}
                {/*<Box*/}
                {/*    width={"200px"}*/}
                {/*    position="absolute"*/}
                {/*    // top="21%"*/}
                {/*    left="1%"*/}
                {/*    zIndex="999"*/}

                {/*>*/}
                <Paper
                    elevation={3}
                    style={{padding: "15px"}}
                >
                    <Typography

                        // variant={"h1"}
                    >Wyznaczanie tras</Typography>

                    {/*<div*/}
                    {/*    style={{maxWidth: '120px'}}*/}
                    {/*>*/}


                    <TextField
                        style={{minWidth: '80px', maxWidth: '110px'}}
                        // inputProps={{style: {fontSize: 10}}} // font size of input text
                        // InputLabelProps={{style: {fontSize: 10}}}
                        // onChange={handleInput}
                        // label="etykieta obiektu"
                        disabled
                        value={(routeOrigin !== null) ? `${String(routeOrigin[1]).substr(0,5)} ${String(routeOrigin[0]).substr(0,5)}` : ""}

                    />
                    <IconButton
                        // size={"small"}
                        onClick={()=>{setAddRouteOrigin(true)}}><AddLocationIcon color={"secondary"}/>
                    </IconButton>


                    <TextField
                        style={{minWidth: '80px', maxWidth: '110px'}}
                        // inputProps={{style: {fontSize: 10}}} // font size of input text
                        // InputLabelProps={{style: {fontSize: 10}}}
                        // onChange={handleInput}
                        disabled
                        value={(routeDestination !== null) ? `${String(routeDestination[1]).substr(0,5)} ${String(routeDestination[0]).substr(0,5)}` : ""}
                        // label="etykieta obiektu"
                    />
                    <IconButton

                        onClick={()=>{setAddRouteDestination(true)}}><AddLocationIcon color={"error"}/>
                    </IconButton>
                    <>
                        <div
                            style={{
                                // paddingTop:"5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <ToggleButtonGroup
                                // orientation="vertical"
                                // justifyContent="center"
                                value={view}
                                size={"small"}
                                exclusive
                                onChange={handleChangeTransportType}
                            >
                                <ToggleButton
                                    value={"pedestrian"} aria-label={"pieszy"}>
                                    {"pieszy"}
                                </ToggleButton>
                                <ToggleButton
                                    value={"car"} aria-label={"auto"}>
                                    {"auto"}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end"
                        }}
                    >
                        {view !== null &&
                        <>
                            <Button
                                size={"small"}
                                onClick={() => {
                                    setGenerateRoute(true)
                                }}
                            >Generuj</Button>
                            <Button
                                onClick={() => {
                                    // setIsochroneStart(null)
                                    // setIsoTime(10*60)
                                    setView(null)
                                    setRemoveRoutes(true)
                                    setRouteDestination(null)
                                    setRouteOrigin(null)
                                }}
                                size={"small"}
                            >Usuń Trasy</Button>
                        </>}
                        {/*<IconButton*/}
                        {/*    size={"small"}*/}
                        {/*    // onClick={handleChange}*/}
                        {/*><CancelIcon/>*/}
                        {/*</IconButton>*/}
                    </div>
                </Paper>

            </Grow>
        </>
    );
}

export default RouteToolbar
