import React, {useContext, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Container, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PersonIcon from '@material-ui/icons/Person';
import FormControl from "@material-ui/core/FormControl";
import {apiAuth} from "../../service";
import {AuthContext} from "../../context/AuthContext";
import {BrowserRouter, Redirect, useHistory} from 'react-router-dom'
import {ErrorContext} from "../../context/ErrorContext";
import InfoSnackbar from "../snackbar/InfoSnackbar";
import GoogleLogin from 'react-google-login';
import moment from "moment";
import {googleId} from "../../utils/version";

const useStyles = makeStyles((theme) => ({
    form:{minWidth:"15px"}
}));

function LoginForm() {
    const classes = useStyles();
    const [forgottenPassword, setForgottenPassword] = useState(false)
    const [authError, setAuthError] = useState("")

    let history = useHistory();

    let {setAuth, isLogged, setIsLogged, setUserLogin, setUserRole} = useContext(AuthContext)
    let {setInfo} = useContext(ErrorContext);
    const [formInput, setFormInput] = useReducer((state, newState) => ({...state, ...newState}), {login: "", password: ""});

    function handleInput(e) {
        const name = e.target.name;
        const newValue = e.target.value;
        setFormInput({[name]: newValue})
    }

    function handleChange() {
        setForgottenPassword(!forgottenPassword)
    }

    const responseGoogle = async (response) => {
        console.log(JSON.stringify(response))
        apiAuth.postUserLoginGoogleUser({
            tokens: {
                access_token: response.tokenObj.access_token,
                id_token: response.tokenObj.id_token,
            }
        })
            .then(resp => {
                // console.log(resp)
                apiAuth.setApiKey(resp.pai_tokens.access_token, "Authorization", false)
                setUserLogin(response.Ft.pu)
                localStorage.setItem("pai_login", response.Ft.pu)
                setAuth(resp.pai_tokens)
                setUserRole(resp.role)
                localStorage.setItem("pai_tokens", JSON.stringify(resp.pai_tokens))
                setIsLogged(true)
                localStorage.setItem("pai_isLogged", "true")
                setInfo({message: 'Pomyślne logowanie. Witaj w PAIu', type: 'success'})
                let expiration_time = moment(moment()).add(resp.pai_tokens.valid_time, 'seconds');
                localStorage.setItem('expiration_time', expiration_time.format('k:mm:ss '))
                setTimeout(() => {
                    history.goBack()
                }, 1000)
            })
            .catch(reason => {
                setAuthError(reason.message)
                setInfo({message: reason.message, type: 'error'})
                console.log(reason.message)
            })
    }

    const responseGoogleError = (response) => {
        console.log(response);
        setInfo({message: "Coś poszło nie tak", type: 'error'})
    }

    function logIn() {
        apiAuth.postUserLogin({body: formInput})
            .then(resp => {
                apiAuth.setApiKey(resp.access_token, "Authorization", false)
                setUserLogin(formInput.login)
                localStorage.setItem("pai_login", formInput.login)
                setAuth(resp)
                setUserRole(resp.role)
                localStorage.setItem("pai_tokens", JSON.stringify(resp))
                setIsLogged(true)
                localStorage.setItem("pai_isLogged", "true")
                let expiration_time =
                    moment(moment()).add(resp.valid_time, 'seconds');
                localStorage.setItem('expiration_time', expiration_time.format('k:mm:ss '))
                localStorage.setItem('expiration_time2', expiration_time.format())
                setInfo({message: 'Pomyślne logowanie. Witaj w PAIu', type: 'success'})
                setTimeout(() => {
                    console.log(history.location)
                    history.push('/analyze')
                }, 1000)
            })
            .then(
                // console.log(apiAuth.getAuthMe())
            )
            .catch(reason => {
                setAuthError(reason.message)
                setInfo({message: reason.message, type: 'error'})
                console.log(reason.message)
            })
        // console.log(history)
    }

    return (
        <>
            <Container>
                {!forgottenPassword &&
                <>
                    <FormControl
                        className={classes.form}
                    >
                        <TextField
                            label="e-mail"
                            name="login"
                            className={classes.form}
                            // helperText="e.g. name@gmail.com"
                            onChange={handleInput}
                            error={authError !== ""}
                        />
                        <TextField
                            label="hasło"
                            name="password"
                            type="password"
                            error={authError !== ""}
                            className={classes.form}
                            // defaultValue={formInput.email}
                            // className={classes.textField}
                            helperText={authError !== "" ? authError : "wprowadź hasło użytkownika"}

                            onChange={handleInput}
                        />
                        <Button
                            // variant="contained"
                            color="primary"
                            // className={classes.button}
                            fullWidth
                            onClick={logIn}
                        >
                            <PersonIcon/> Zaloguj
                        </Button>

                        {/*<GoogleLogin*/}
                        {/*    clientId={googleId}*/}
                        {/*    // render={renderProps => (*/}
                        {/*    //     <Button*/}
                        {/*    //         onClick={renderProps.onClick}*/}
                        {/*    //         disabled={renderProps.disabled}*/}
                        {/*    //         color="grey">*/}
                        {/*    //         Konto Google*/}
                        {/*    //     </Button>*/}
                        {/*    // )}*/}
                        {/*    buttonText="Login"*/}
                        {/*    onSuccess={responseGoogle}*/}
                        {/*    onFailure={responseGoogleError}*/}
                        {/*    cookiePolicy={'single_host_origin'}*/}
                        {/*/>*/}


                        {/*<Button*/}
                        {/*    // variant="contained"*/}
                        {/*    color="primary"*/}
                        {/*    // className={classes.button}*/}
                        {/*    onClick={handleChange}*/}
                        {/*>*/}
                        {/*    Przypomnij hasło*/}
                        {/*</Button>*/}
                    </FormControl>
                </>
                }
                {forgottenPassword &&
                <>
                    <TextField>test</TextField>
                    <Button>Przypomnij</Button>
                    <Button onClick={handleChange}>Wróć do logowania</Button>
                </>
                }
                {isLogged &&
                <>
                    <InfoSnackbar/>
                </>
                }
            </Container>

        </>
    );
}

export default LoginForm
