import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {AuthContext} from "../../context/AuthContext";
import AccountManager from "./AccountManger";
import DataGridDemo from "./AccountManagerDG";
import Editable from "./AccountManagerMT";


const useStyles = makeStyles((theme) => ({

}));

export default function AdminPage() {
    const classes = useStyles();
    let {isLogged, auth} = useContext(AuthContext)
    return (
        <>
            {/*<AccountManager/>*/}
           {/*<DataGridDemo/>*/}
           <Editable/>
        </>
    );
}
