import React, {useContext, useEffect, useRef, useState} from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet-contextmenu/dist/leaflet.contextmenu"
import "leaflet-contextmenu/dist/leaflet.contextmenu.css"
import {apiAnalysis, apiExternal} from "../../service";
import {AnalysisContext} from "../../context/AnalysisContext";
import {ErrorContext} from "../../context/ErrorContext";
import {ToolContext} from "../../context/ToolContext";
import * as turf from '@turf/turf'
import 'leaflet.nontiledlayer'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-geometryutil'
import 'leaflet-editable'
import MapLegend from "../maptools/MapLegend";
import 'leaflet.tilelayer.colorfilter';
import {polandBox} from "../../utils/Geometries";
import FeatureInfoSidebar from "../maptools/featureInfo/FeatureInfoSidebar";
import {getColor, getIndicatorColor} from "../../utils/ExtService";
import 'leaflet-measure-path'
import 'leaflet-measure-path/leaflet-measure-path.css'
import 'leaflet-textpath'
import 'leaflet-iconmaterial/dist/leaflet.icon-material.css'
import 'leaflet-iconmaterial/dist/leaflet.icon-material'

import instance from "../../api/SettingsInstance";
import 'leaflet-pegman/leaflet-pegman.css'
import 'leaflet-pegman/leaflet-pegman'
import SpatialTools from "../maptools/SpatialToolbar";

import {
    useBaseLayerSwitcher, useGenerateIsochrone, useGenerateRoute, useHideUserPoints,
    useIsochroneTool, useMeasureTools,
    useOverLayerSwitcher, useRemoveIsochrones, useRemoveRoutes, useRouteDestination, useRouteOrigin, useUserPoint,
} from "../maptools/MapToolsHooks";
import MapLayerSwitcher from "../maptools/layerSwitcher/MapLayerSwitcherSidebar";


export default function Map(props) {
    let {
        transportType, setInputGeometry, analysisId, analysisGeometry, setAnalysisGeometry,
        time, radius, addressPoint, setAddressPoint,
        setFeatureInfo, setHexPercentilesInfo, style, aoiActive, paiOpacity, setParcel, indicatorLayers, polygonDrawing, setPolyAoiValidate,
        bestAreas, bestAreasVisible, analysisGeomWKT, setFeatureInfoComponents, isAnalysisVisible, setIsAoiToggleGroupDisabled
    } = useContext(AnalysisContext)

    let {
        routeOrigin, routeDestination, routeTransportType, generateRoute, removeRoutes, addRouteOrigin, addRouteDestination, addUserPoint, userPointLabel, userPointColor, hideUserPoint,
        measureLine, measurePolygon, measurePoint, clearMeasures,
        isochroneStart, addIsoPoint, generateIso, isoTime, isoTransportType, removeIso,
        setMapZoomLvl,
        baseLayer, overLayer,
    } = useContext(ToolContext)

    let {setInfo} = useContext(ErrorContext);
    const [selectedObject, setSelectedObject] = useState(null)
    const [hexId, setHexId] = useState(null)

    function parcelHandler(e) {
        setParcel(e)
    }

    function addressHandler(e) {
        setAddressPoint(e.reverse())
    }

    const mapRef = useRef(null);
    useEffect(() => {
        mapRef.current = L.map(props.id, {
            editable: true,
            zoomControl: false,
            // crs: L.CRS.EPSG3857,
            maxBounds: [
                [49.081062364320736, 13.150390625],
                [54.85131525968606, 24.99999796875]
            ],
            contextmenu: true,
            maxZoom: 20,
            minZoom: 6,
            doubleClickZoom: false,
        }).on("zoom", () => {
            setMapZoomLvl(mapRef.current.getZoom())
        }).whenReady(() => {
            if (props.data !== undefined) {
                apiAnalysis.getAnalysisByAnalysisIdResult({analysis_id: props.data})
                    .then(body => {
                        setAnalysisGeometry(body)
                        mapRef.current.fitBounds(L.geoJSON(body).getBounds())
                    })
                    .catch(reason => {
                        console.log(reason)
                    })
            }
        })

        mapRef.current.fitBounds(L.geoJSON(polandBox).getBounds())

        L.control.scale({
            metric: true,
            imperial: false,
            position: 'bottomright'
        }).addTo(mapRef.current);

        var pegmanControl = new L.Control.Pegman({
            apiKey: 'AIzaSyBsnhMdof7HsKpqyFwK3_xCJQRTbdVF92M', // CHANGE: with your google maps api key
            position: 'bottomright', // position of control inside the map
            // theme: "leaflet-pegman-v3-small", // or "leaflet-pegman-v3-small"
            panoDiv: '#panorama'
        });

        pegmanControl.addTo(mapRef.current);
        return () => mapRef.current.remove();
    }, []);

    const selectedHex = useRef(null);
    useEffect(() => {
        if (selectedHex.current) {
            if (analysisGeometry === undefined) {
                selectedHex.current.clearLayers()
                setSelectedObject(null)
            }

            if (selectedObject) {
                selectedHex.current
                    .clearLayers()
                    .addData(selectedObject)
                    .addTo(mapRef.current)
                    .bringToFront()
            }
        } else {
            selectedHex.current = L.geoJSON(false, {color: 'red', weight: 6, fillOpacity: 0})
            // .addTo(mapRef.current)
        }
    }, [selectedObject, analysisGeometry])

    const hexes = useRef(null);

    useEffect(() => {
        if (hexes.current) {
            if (analysisGeometry && analysisId) {
                hexes.current
                    .clearLayers()
                    .addData(analysisGeometry)
                    .addTo(mapRef.current)
                    .bringToFront()
                    .setStyle((style === "standard") ? (feature) => ({
                        fillColor: getColor(feature.properties["wynik"]),
                    }) : (feature) => ({
                        fillColor: getIndicatorColor(feature.properties[style.id], style.interval),
                    }))

                selectedHex.current.bringToFront()
                bestAreasObjectRef.current.bringToFront()
            }
        } else {
            hexes.current = L.geoJSON(false, {
                style: (feature) => ({
                    weight: 0.4,
                    color: 'white',
                    fillOpacity: 0.55
                }),
                onEachFeature: (feature, layer) => {
                    layer.on({
                        click: (e, analysisId) => {
                            var layer = e.target;
                            setSelectedObject({
                                "features": [
                                    {
                                        "type": "Feature",
                                        "geometry": layer.feature.geometry
                                    }
                                ]
                            })
                            setFeatureInfo(layer.feature.properties)
                            console.log("hex", layer.feature.properties)
                            setHexId(layer.feature.properties.id)
                        },
                    });
                },
            }).addTo(mapRef.current)
        }
        return () => {
            hexes.current.removeFrom(mapRef.current)
        }
    }, [analysisId, analysisGeometry])

    useEffect(() => {
        if (hexId && analysisId) {
            console.log(hexId, analysisId)
            apiAnalysis.getAnalysisByAnalysisIdComponentvalues({
                analysis_id: analysisId,
                hexId: hexId
            }).then(body => {
                setFeatureInfoComponents(body)
            })
            apiAnalysis.getAnalysisByAnalysisIdPercentiles(
                {
                    analysis_id: analysisId,
                    hexId: hexId
                }).then(body => {
                console.log("per", body)
                setHexPercentilesInfo(body)
            })
        }
    }, [hexId, analysisId])

    useEffect(() => {
        if (hexes.current) {
            hexes.current.setStyle((style === "standard") ? (feature) => ({
                fillColor: getColor(feature.properties["wynik"]),
            }) : (feature) => ({
                fillColor: getIndicatorColor(feature.properties[style.id], style.interval),
            }))
        }
    }, [style])

    useEffect(() => {
        if (hexes.current) {
            hexes.current.setStyle({fillOpacity: paiOpacity})
        }
    }, [paiOpacity])

    useEffect(() => {
        (isAnalysisVisible) ? hexes.current.addTo(mapRef.current) : hexes.current.removeFrom(mapRef.current)
        if (selectedHex.current) {
            selectedHex.current.bringToFront()
        }
        if (bestAreasObjectRef.current) {
            bestAreasObjectRef.current.bringToFront()
        }
    }, [isAnalysisVisible])

    const bestAreasObjectRef = useRef(null);
    useEffect(() => {
        if (bestAreasObjectRef.current) {
            if (bestAreasVisible && bestAreas !== null && bestAreas.hasOwnProperty("geom")) {
                bestAreasObjectRef.current
                    .clearLayers()
                    .addData(bestAreas.geom)
                    .bringToFront()
                    .addTo(mapRef.current)
            } else {
                bestAreasObjectRef.current
                    .removeFrom(mapRef.current)
            }
        } else {
            bestAreasObjectRef.current = L.geoJSON(bestAreas, {
                color: 'blue',
                weight: 1,
                fillOpacity: 0
            })
        }
    }, [bestAreasVisible, bestAreas])

    const aoiMarker = useRef(null);
    const aoiCircle = useRef(null);
    useEffect(() => {
        if (aoiCircle.current && aoiMarker.current) {
            if (addressPoint) {
                aoiMarker.current.setLatLng(addressPoint)
                aoiMarker.current.addTo(mapRef.current)
            }
        } else {
            aoiCircle.current = L.circle([], {
                weight: 0.8,
                fillColor: '#1a73e8',
                color: '#1a73e8',
                fillOpacity: 0.05,
            })
            // aoiMarker.current = L.marker()
            aoiMarker.current = L.marker([], {
                icon: L.IconMaterial.icon({
                    icon: 'fiber_manual_record',        // Name of Material icon
                    // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                    markerColor: '#1a73e8',  // Marker fill color
                    // outlineColor: 'yellow',            // Marker outline color
                    outlineWidth: 1,                   // Marker outline width
                    iconSize: [26, 37]                 // Width and height of the icon
                })
            })
        }
    }, [addressPoint, radius, transportType, time])

    useEffect(() => {
        function onMapClick(e) {
            if (radius) {
                aoiCircle.current.setLatLng(e.latlng)
            }
            aoiMarker.current.setLatLng([e.latlng.lat, e.latlng.lng])
            setAddressPoint([e.latlng.lat, e.latlng.lng])
        }

        if (aoiActive) {
            mapRef.current.on("click", onMapClick)
        } else {
            aoiCircle.current.removeFrom(mapRef.current)
            aoiMarker.current.removeFrom(mapRef.current)
        }
        return () => {
            mapRef.current.off("click", onMapClick)
        }
    }, [radius, aoiActive, transportType, time])

    useEffect(() => {
        aoiCircle.current.removeFrom(mapRef.current)
        if (addressPoint && radius) {
            var circle = turf.circle([addressPoint[1], addressPoint[0]],
                radius,
                {steps: 100, units: 'kilometers'})
            let array = []
            circle.geometry.coordinates[0].forEach(element => {
                array.push([String(element[1]), String(element[0])])
            })
            setInputGeometry(array)

            aoiCircle.current.setLatLng(addressPoint)
            aoiCircle.current.setRadius(radius * 1000)
            mapRef.current.fitBounds(L.polygon(array).getBounds())
            aoiCircle.current.addTo(mapRef.current)
        } else if (addressPoint) {
            mapRef.current.flyTo(addressPoint, 14)
        }

    }, [addressPoint, radius, transportType, time])

    // let aoiIsoRange = L.polygon([[0, 0], [0, 1]], {
    //     fillColor: '#1a73e8',
    //     color: '#1a73e8',
    //     fillOpacity: 0.05,
    //     name: "isoRange"
    // })
    // useEffect(() => {
    //         aoiIsoRange.addTo(mapRef.current)
    //         if (time !== null && transportType !== null) {
    //             var seconds = time * 60
    //
    //             if (time >= 1 && transportType) {
    //                 apiExternal.getApisIsochrone({localization: addressPoint, time: seconds, transportType: transportType})
    //
    //                     .then((isochroneGeojson) => {
    //                         console.log(isochroneGeojson)
    //                         // L.marker(e.latlng).addTo(mapRef.current)
    //
    //                         const json = isochroneGeojson.response.isoline[0].component[0].shape;
    //                         let array = []
    //                         json.forEach(element => {
    //                             // console.log(element)
    //                             array.push(element.split(","))
    //                         })
    //
    //                         mapRef.current.eachLayer((layer) => {
    //                             if (layer.options.name === "isoRange") {
    //                                 layer.removeFrom(mapRef.current)
    //                             }
    //                         })
    //
    //                         aoiIsoRange.removeFrom(mapRef.current)
    //                         aoiIsoRange
    //                             .setLatLngs(array)
    //                             .bringToBack()
    //                         aoiIsoRange.addTo(mapRef.current)
    //
    //                         setInputGeometry(array)
    //                         console.log('----');
    //                         console.log(inputGeometry)
    //
    //                         mapRef.current.fitBounds(L.polygon(array).getBounds())
    //                     })
    //                     .catch(reason => {
    //                         console.log(reason)
    //                     })
    //             }
    //         }
    //         return () => aoiIsoRange.removeFrom(mapRef.current)
    //     }, [time, transportType]
    // )


    var drawnItems = new L.FeatureGroup();
    var drawControl = new L.Control.Draw({
        draw: {
            circlemarker: false,
            polyline: false,
            polygon: {
                showLength: true,
                metric: true,
                showArea: true,
                drawError: {
                    color: '#e10000', // Color the shape will turn when intersects
                    message: '<strong>Obszar nie może się przecinać<strong>' // Message that will show when intersect
                },
                allowIntersection: false, // Restricts shapes to simple polygons
                shapeOptions: {
                    color: '#1a73e8',
                    fillColor: '#1a73e8',
                    fillOpacity: 0.15,
                    weight: 0.9,

                }
            },
            circle: false, // Turns off this drawing tool
            rectangle: false,
            marker: false
        },
        edit: {
            featureGroup: drawnItems, //REQUIRED!!
            remove: true,
            edit: {
                selectedPathOptions: {
                    // dashArray: '1, 10',
                    fill: true,
                    // fillColor: '#8cfe57',
                    fillOpacity: 0.3,
                    // Whether to user the existing layers color
                    maintainColor: true
                }
            },

            // polygonEditing:{
            //     color: "yellow",
            //     fillColor: "yellow"
            // }
        }
    });

    useEffect(() => {
        L.drawLocal.draw.handlers.polygon.tooltip = {
            start: 'Kliknij aby zacząć rysowanie obszaru',
            cont: 'Kliknij aby kontynuować rysowanie obszaru',
            end: 'Kliknij aby pierwszy punkt aby zakończyć rysowanie obszaru'
        };

        L.drawLocal.draw.toolbar = {
            actions: {
                title: 'Anuluj rysowanie',
                text: 'Anuluj'
            },
            finish: {
                title: 'Zakończ rysowanie',
                text: 'Zakończ'
            },
            undo: {
                title: 'Usuń ostatni narysowany punkt',
                text: 'Usuń ostatni punkt'
            },
            buttons: {
                polyline: 'Draw a polyline',
                polygon: 'Narysuj obszar',
                rectangle: 'Draw a rectangle',
                circle: 'Draw a circle',
                marker: 'Draw a marker',
                circlemarker: 'Draw a circlemarker'
            }
        }
        L.drawLocal.edit = {
            toolbar: {
                actions: {
                    save: {
                        title: 'Save changes',
                        text: 'Zapisz'
                    },
                    cancel: {
                        title: 'Cancel editing, discards all changes',
                        text: 'Anuluj'
                    },
                    clearAll: {
                        title: 'Wyczyść',
                        text: 'Wyczyść'
                    }
                },
                buttons: {
                    edit: 'Edytuj',
                    editDisabled: 'Brak obszaru do edycji',
                    remove: 'Usuń obszary',
                    removeDisabled: 'Brak obszaru do usunięcia'
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: 'Przesuń wierzchołki w celu edycji obszaru',
                        subtext: 'Kliknij Anuluj aby wycofać zmiany'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Kliknij obszar do usunięcia'
                    }
                }
            }
        };

        if (polygonDrawing) {
            mapRef.current.addLayer(drawnItems);
            mapRef.current.addControl(drawControl);
            document.querySelector(".leaflet-draw-draw-polygon").click();
            let layer;
            let array

            function validate() {
                var newarea = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                const kms = (newarea / 1000000).toFixed(2)

                if (newarea > Math.PI * Math.pow(10000, 2)) {
                    layer.setStyle({color: "red", fillColor: "red"})
                        .bindPopup(`Zbyt duży obszar  ${kms} km²`)
                        .openPopup()
                        .hideMeasurements()
                    setPolyAoiValidate(false)
                    // document.querySelector(".leaflet-draw-edit-edit").click();
                    return false
                } else {
                    layer.setStyle({color: "#1a73e8", fillColor: "#1a73e8"})
                        .hideMeasurements()
                        .bindPopup(`${kms} km²`)
                        .closePopup()
                    array = []
                    layer.getLatLngs()[0].forEach(element => {
                        array.push([String(element.lat), String(element.lng)])
                    })

                    setPolyAoiValidate(true)
                    setInputGeometry(array)
                    return true
                }

                mapRef.current.fitBounds(L.polygon(array).getBounds())
            }

            mapRef.current.on('draw:created', e => {
                layer = e.layer;

                if (drawnItems && drawnItems.getLayers().length !== 0) {
                    drawnItems.clearLayers();
                }
                drawnItems.addLayer(layer);
                validate()
                setIsAoiToggleGroupDisabled(false)
            });

            mapRef.current.on('draw:editstart', () => {
                setIsAoiToggleGroupDisabled(true)
                // validate()
            });

            mapRef.current.on('draw:editvertex', () => {
                validate()
                // deactivateAoi(true)
            });

            mapRef.current.on('draw:editstop', () => {
                setIsAoiToggleGroupDisabled(false)
                // validate()
                if (validate()) {
                    mapRef.current.fitBounds(L.polygon(array).getBounds())
                }
                // console.log(validate())
                // mapRef.current.fitBounds(L.polygon(array).getBounds())
            });

            mapRef.current.on('draw:deletestop', () => {
                validate()
            });
        }

        return () => {
            drawControl.remove()
            drawnItems.clearLayers()
            mapRef.current.off('draw:created')
        }

    }, [polygonDrawing])

    useEffect(() => {
        if (indicatorLayers.value) {
            indicatorLayers.value.map((element) => {
                switch (element.type) {
                    case "WMS":
                        let transWms = L.tileLayer.wms(instance.geoserverUrl + "/pai/wms?", {
                            layerType: "transWMS",
                            layerName: element.geoserver_layer_name,
                            jwt: JSON.parse(localStorage.pai_tokens).access_token,
                            layers: element.geoserver_layer_name,
                            viewparams: `analysis_id:${analysisId}`,
                            cql_filter: `INTERSECTS(geom,${analysisGeomWKT})`,
                            format: 'image/png',
                            transparent: true
                        })

                        if (!indicatorLayers.visibleOnMap) {
                            mapRef.current.eachLayer((layer) => {
                                if (layer.options.layerName === element.geoserver_layer_name) {
                                    layer.removeFrom(mapRef.current)
                                }
                            })
                        } else {
                            transWms.setZIndex(99999)
                            mapRef.current.addLayer(transWms);
                        }
                        break;
                    case "WFS":
                        let wfsLayer = element.geoserver_layer_name.substr(4)
                        // let icon = indicatorLayers.value[0].symbol
                        if (!indicatorLayers.visibleOnMap) {
                            mapRef.current.eachLayer((layer) => {
                                if (layer.options.typeName === wfsLayer) {
                                    layer.removeFrom(mapRef.current)
                                }
                            })
                        } else {
                            fetch(instance.geoserverUrl + "/pai/ows" +
                                "?service=WFS&version=1.0.0&request=GetFeature" +
                                "&typeName=pai%3A" + wfsLayer +
                                "&outputFormat=application%2Fjson" +
                                "&srsName=EPSG:4326" +
                                "&cql_filter=" + `INTERSECTS(geom,${analysisGeomWKT})` +
                                "&jwt=" + JSON.parse(localStorage.pai_tokens).access_token
                            )
                                .then((response) => {
                                    response.json()
                                        .then((data) => {
                                            L.geoJSON(data, {
                                                typeName: wfsLayer,
                                                layerType: "transWMS",
                                                pointToLayer: (feature, latlng) => {
                                                    var opt = {
                                                        borderColor: '#000000',
                                                        borderWidth: 0,
                                                        // html: `<span class="material-icons md-10">${indicatorLayers.value[0].symbol}</span>`,
                                                        html: `<span class="material-icons md-10">${indicatorLayers.value[0].symbol}</span>`,
                                                        // backgroundColor:indicatorLayers.value[0].style,
                                                        backgroundColor: 'rgb(255,217,102,1)',
                                                        // backgroundColor: indicatorLayers.value[0].style.replaceAll('"','\''),
                                                        textColor: "white",
                                                        // icon: 'leaf',
                                                        iconSize: [20, 20],
                                                        popupAnchor: [7, 0],
                                                        //     html: `<span style="{{font-size: 10px}}" class="material-icons md-36">${indicatorLayers.value[0].symbol}</span>`,
                                                        // html: `<span class="material-icons">indicatorLayers.value[0].symbol</span>`,
                                                        iconShape: 'circle-dot'
                                                    }

                                                    // console.log(opt)
                                                    return L.marker(latlng, {
                                                        icon: L.BeautifyIcon.icon(element.style)
                                                    })
                                                    // .on({
                                                    // mouseover: (e) => {
                                                    //     e.target.setIcon(L.divIcon({
                                                    //         className: 'location-pin',
                                                    //         // html: '<span class="material-icons">indicatorLayers.value[0].symbol</span>',
                                                    //         html: `<i class="material-icons md-18">${indicatorLayers.value[0].symbol}</i>`,
                                                    //         // html: '<span class="material-icons">tram</span><span>' + geoJsonPoint.properties.name+ '</span>',
                                                    //         iconSize: [40, 40],
                                                    //         iconAnchor: [20, 40]
                                                    //     }));
                                                    // },
                                                    //     mouseout: (e) => {
                                                    //         e.target.setIcon(L.divIcon({
                                                    //             className: 'location-pin',
                                                    //             html: `<i class="material-icons md-18">${indicatorLayers.value[0].symbol}</i>`,
                                                    //             iconSize: [30, 30],
                                                    //             iconAnchor: [15, 30]
                                                    //         }));
                                                    //     },
                                                    // });

                                                },
                                                onEachFeature: (feature, layer) => {
                                                    var popupContent = ""
                                                    for (const [key, value] of Object.entries(feature.properties)) {
                                                        popupContent += `<b>${key}</b>: ${value !== null ? value : "-"}</br>`
                                                    }
                                                    layer.bindPopup(popupContent);
                                                }
                                            }).addTo(mapRef.current)


                                        }).catch((err) => {
                                        console.log('coś poszło nie tak', err);
                                    })
                                });
                        }
                        break;
                }

            })
        }
        if (analysisGeomWKT === null) {
            mapRef.current.eachLayer((layer) => {
                if (layer.options.layerType === "transWMS") {
                    layer.removeFrom(mapRef.current)
                }
            })
            indicatorLayers.visibleOnMap = false
        }

    }, [indicatorLayers, analysisGeomWKT, analysisId])
    //Isochrones hooks
    useIsochroneTool(addIsoPoint, mapRef.current)
    useRemoveIsochrones(removeIso, mapRef.current)
    useGenerateIsochrone(generateIso, isochroneStart, isoTransportType, isoTime, mapRef.current)

    // layer switcher hooks
    useBaseLayerSwitcher(baseLayer, mapRef.current)
    useOverLayerSwitcher(overLayer, mapRef.current)
    //measuring hooks
    useMeasureTools(measureLine, measurePolygon, measurePoint, clearMeasures, mapRef.current)
    // user Pois hooks
    useUserPoint(addUserPoint, userPointLabel, userPointColor, mapRef.current)
    useHideUserPoints(hideUserPoint, mapRef.current)
    //routing hooks
    useRouteOrigin(addRouteOrigin, mapRef.current)
    useRouteDestination(addRouteDestination, mapRef.current)
    useGenerateRoute(generateRoute, routeOrigin, routeDestination, routeTransportType, mapRef.current)
    useRemoveRoutes(removeRoutes, mapRef.current)

    return (
        <>
            {/*<AddressAutocomplete addressHandler={addressHandler}/>*/}
            {/*<ParcelFinder parcelHandler={parcelHandler}/>*/}
            <div
                style={{
                    width: "100%",
                    height: "calc(100vh - 64px)",
                    position: "relative"
                }}
            >
                <div
                    ref={mapRef.current}
                    id={props.id}
                    style={{
                    position: "absolute",
                    width: "100%",
                    height: "calc(100vh - 64px)",
                }}>
                </div>

                <div id="panorama"
                     style={{
                         zIndex: 9999999,
                         position: "absolute",
                         bottom: 0,
                         left: 0,
                         width: "100%",
                         height: "50%",
                     }}>
                </div>
                <MapLayerSwitcher/>
                {analysisGeomWKT !== null &&
                <>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "200px",
                        height: "70%",
                        // backgroundColor: "white",
                        zIndex: 402,
                        pointerEvents: "none",
                        // position: "relative !important"
                    }}>
                        <SpatialTools/>
                    </div>
                    <MapLegend/>
                    <FeatureInfoSidebar/>
                    {/*<MeasureTools/>*/}
                    {/*<IsochroneToolbar/>*/}
                    {/*<PoiManager/>*/}
                </>}
            </div>
        </>
    );
}




