import Pbf from "pbf";
import {decode} from "geobuf";

var request = require('request');
var settingsInstance = require('./SettingsInstance');


export function mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters)
            .forEach(function (parameterName) {
                var parameter = parameters.$queryParameters[parameterName];
                queryParameters[parameterName] = parameter;
            });
    }
    return queryParameters;
}

function queryParamsToString(dictionary) {
    let urlParams = ''
    const dictLength = Object.keys(dictionary).length
    for (let i = 0; i < dictLength; i++) {
        urlParams = urlParams + Object.keys(dictionary)[i] + '=' + Object.values(dictionary)[i];
        if (i !== dictLength - 1) {
            urlParams += '&'
        }
    }
    return urlParams;
}

export function customRequest(method, path, url, body, headers, queryParameters, form, deferred, files, options) {
    let statusCallbacks = {
        401: _ => {
            localStorage.clear()
            window.location.replace('/auth');
        },
        // 403: _ => {
        //     // contextInstance.setGlobalErrorData(403)
        // },
        // 404: _ => {
        //     // contextInstance.setGlobalErrorData(404)
        // },
        // 500: _ => {
        //     // contextInstance.setGlobalErrorData(500)
        // }
    }

    let uri = url
    let opt = {
        method: method,
        headers: headers
    }

    // console.log(headers)
    var req = {
        method: method,
        uri: url,
        qs: queryParameters,
        headers: headers,
        body: body
    };

    if (typeof (body) === 'object' && !(body instanceof Buffer)) {
        req.json = true;
    }

    if (options && options.encoding) {
        req.encoding = options.encoding;
    }

    if (Object.keys(files).length > 0) {
        req.formData = new FormData();
        for (var formKey in form) {
            req.formData.append(formKey, form[formKey]);
        }
        for (var fileKey in files) {
            req.formData.append(fileKey, files[fileKey]);
        }
    } else if (Object.keys(form).length > 0) {
        req.form = form;
    }

    // let csrfToken = contextInstance.getCsrfToken();
    // req.headers['X-CSRFToken'] = csrfToken;

    req.withCredentials = true;

    if (options !== undefined && options.withCredentials !== undefined) {
        req.withCredentials = options.withCredentials;
    }
    if (headers !== undefined && headers.credentials === undefined) {
        delete opt.credentials;
        delete headers.credentials;
    } else {
        opt.credentials = headers.credentials;
    }
    if (options !== undefined && options.hasCsrfToken === false) {
        delete req.headers['X-CSRFToken'];
    }
    if (options !== undefined && options.hasContentType === false) {
        delete req.headers['Accept'];
    }
    if (Object.keys(queryParameters).length !== 0) {
        uri += '?' + queryParamsToString(req.qs)
    }
    if (Object.keys(body).length !== 0) {
        opt.body = JSON.stringify(body)
    }

    return fetch(uri, opt)
        .then(response => {
                if (statusCallbacks[response.status]) {
                    statusCallbacks[response.status]();
                } else if (response.status >= 200 && response.status <= 299) {
                    if (/^application\/(.*\\+)?pbf/.test(headers['Accept'])) {
                        return response.arrayBuffer()
                            .then(body => {
                                const pbf = new Pbf(body);
                                return decode(pbf);
                            })
                    } else if (/^file/.test(headers['Accept'])) {
                        return response.blob()
                    } else {
                        return response.json()
                    }
                } else if (response.status === 400) {
                    return response.json().then(body => {
                        deferred.reject(body)
                    })
                } else if (response.status === 401) {
                    return response.json().then(body => {
                        deferred.reject(body)
                    })
                } else if (response.status === 404) {
                    return response.json().then(body => {
                        deferred.reject(body)
                    })
                }
                throw Error('Błąd obsługi odpowiedzi z serwera')
            }
        )
        .then(body => {
            deferred.resolve(body);
        })
        .catch(reason => {
            deferred.reject(reason);
        })
}
