import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {apiExternal, apiLocalAnalysis} from "../../service";
import {PoiAnalysisContext} from "../../context/PoiAnalysisContext";
import instance from "../../api/SettingsInstance";
import * as turf from '@turf/turf'
import proj4 from "proj4";
import "../../../src/utils/pulseIcon/L.Icon.Pulse"
import "../../../src/utils/pulseIcon/L.Icon.Pulse.css"

import "leaflet.marker.highlight/dist/leaflet.marker.highlight-src"
import "leaflet.marker.highlight/dist/leaflet.marker.highlight-src.css"

import 'leaflet-textpath'
import "leaflet.polyline.snakeanim"

import 'leaflet-iconmaterial/dist/leaflet.icon-material.css'
import 'leaflet-iconmaterial/dist/leaflet.icon-material'

import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import {HighlightablePolyline} from 'leaflet-highlightable-layers';

import moment from "moment";
import MapLayerSwitcher from "../maptools/layerSwitcher/MapLayerSwitcherSidebar";
import {ToolContext} from "../../context/ToolContext";
import {
    useBaseLayerSwitcher, useGenerateIsochrone, useGenerateRoute, useHideUserPoints,
    useIsochroneTool, useMeasureTools,
    useOverLayerSwitcher,
    useRemoveIsochrones, useRemoveRoutes, useRouteDestination, useRouteOrigin, useUserPoint
} from "../maptools/MapToolsHooks";
import SpatialTools from "../maptools/SpatialToolbar";
import {ErrorContext} from "../../context/ErrorContext";
import { decode } from '@here/flexpolyline'

// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow,
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41]
// });
//
// L.Marker.prototype.options.icon = DefaultIcon

export default function Map(props) {
    let {
        aoiPoint, setAoiPoint,
        result, setResult,
        aoiIsochrone,
        aoiDrawingActive, setAoiDrawingActive,
        aoiWkt, setAoiWkt,
        categoriesVisibility,
        highlights,
        setBlockSaving,
        PoiRouteOrigin, setPoiRouteOrigin
    } = useContext(PoiAnalysisContext)

    let {
        routeOrigin, setRouteOrigin, routeDestination, routeTransportType, generateRoute, removeRoutes, addRouteOrigin, addRouteDestination,
        addUserPoint, userPointLabel, userPointColor, hideUserPoint, measureLine, measurePolygon, measurePoint, clearMeasures,
        isochroneStart, addIsoPoint, generateIso, isoTime, isoTransportType, removeIso,
        mapZoomLvl, setMapZoomLvl,
        baseLayer, overLayer
    } = useContext(ToolContext)

    let {setInfo} = useContext(ErrorContext);

    const [analysisId, setAnalysisID] = useState(null)
    const [aoiGeometry, setAoiGeometry] = useState([])
    const [pois, setPois] = useState([])


    // Map must be redered as first
    const mapRef = useRef(null);
    useEffect(() => {
        if (mapRef.current) {
            return
        }
        mapRef.current = L.map(props.id, {
            editable: true,
            zoomControl: false,
            maxBounds: [
                [49.081062364320736, 13.150390625],
                [54.85131525968606, 24.99999796875]
            ],
            center: [51.5, 20],
            zoom: 6,
            // // crs: L.CRS.EPSG3857,
            // maxBounds: [
            //     //     //south west
            //     [49.081062364320736, 13.150390625],
            //     //     //north east
            //     [54.85131525968606, 24.99999796875]
            // ],
            maxZoom: 20,
            minZoom: 6,
            doubleClickZoom: false,
        }).on("zoom", () => {
            setMapZoomLvl(mapRef.current.getZoom())
        }).whenReady(() => {
            console.log(props.data)

            if (props.data === undefined) {
                console.log("Nowa analiza")
            } else {
                console.log("archiwalna")
                apiLocalAnalysis.getLocalanalysisByAnalysisId({analysis_id: props.data})
                    .then((body) => {
                        let bboxArray = L.polyline(body.geom).getBounds().toBBoxString().split(",").map(x => parseFloat(x))
                        let bboxArrayReversedConverted = [proj4('EPSG:4326', 'EPSG:2180', [bboxArray[0], bboxArray[1]]), proj4('EPSG:4326', 'EPSG:2180', [bboxArray[2], bboxArray[3]])]
                        aoiPulseIconRef.current.setLatLng([body.geom_point.lat, body.geom_point.lon]).addTo(mapRef.current)
                        aoiMarkerRef.current.setLatLng([body.geom_point.lat, body.geom_point.lon]).addTo(mapRef.current)
                        setPoiRouteOrigin([body.geom_point.lat, body.geom_point.lon])
                        setAoiGeometry(body.geom)
                        setAoiWkt(bboxArrayReversedConverted.toString())
                        setResult(body)
                        setBlockSaving(false)
                    }).catch(reason => console.log(reason))
            }
        })
    }, []);


    //Aoi handling
    const aoiMarkerRef = useRef(null);
    const aoiAreaRef = useRef(null)
    const aoiPulseIconRef = useRef(null)

    useEffect(() => {
        if (aoiAreaRef.current) {
            aoiAreaRef.current
                .setStyle({
                    fill: false,
                    weight: 1.5,
                    color: '#1a73e8',
                    // dashArray: '10,2',
                    fillOpacity: 0.05,
                    opacity: 0.8,
                    // name: "userIsochrone"
                })
                .setLatLngs(aoiGeometry);
            aoiAreaRef.current.addTo(mapRef.current);
            mapRef.current.fitBounds(aoiAreaRef.current.getBounds())
        } else {
            aoiAreaRef.current = L.polyline([]);
            aoiMarkerRef.current = L.marker([], {
                icon: L.IconMaterial.icon({
                    icon: 'fiber_manual_record',        // Name of Material icon
                    // iconColor: '#ffffff',              // Material icon color (could be rgba, hex, html name...)
                    markerColor: '#1a73e8',  // Marker fill color
                    // outlineColor: 'yellow',            // Marker outline color
                    outlineWidth: 1,                   // Marker outline width
                    iconSize: [26, 37]                 // Width and height of the icon
                })
            })
            aoiPulseIconRef.current = L.marker([], {icon: L.icon.pulse({iconSize: [12, 12], color: '#1a73e8'})})


        }
    }, [aoiGeometry])

    useEffect(() => {
        if (aoiPoint) {
            mapRef.current.flyTo(aoiPoint, 16)
            aoiPulseIconRef.current.setLatLng(aoiPoint).addTo(mapRef.current)
            aoiMarkerRef.current.setLatLng(aoiPoint).addTo(mapRef.current)

            // mapRef.current.panTo(aoiPoint)
        }

        if (aoiPoint && aoiIsochrone) {
            // console.log("mam wszystko")
            aoiMarkerRef.current.setLatLng(aoiPoint).addTo(mapRef.current)
            const params = JSON.parse(aoiIsochrone)
            getLocalAnalysisByIsochrone(aoiPoint, params.type, params.time)

            if (PoiFeatureGroupRef.current) {
                PoiFeatureGroupRef.current.clearLayers()
            }

        } else {
            console.log("określ paramtery")
        }
    }, [aoiPoint, aoiIsochrone])

    function getLocalAnalysisByIsochrone(coords, type, time) {
        apiExternal.getApisIsochrone({
            localization: coords,
            time: time,
            transportType: type
        }).then((isochroneGeojson) => {
            const encodedString = isochroneGeojson.isolines[0].polygons[0].outer
            const decoded = decode(encodedString);
            let array = decoded.polyline
            if (array[0] !== array[array.length - 1]) {
                array.push(array[0])
            }
            setAoiGeometry(array)

            let bboxArray = L.polyline(array).getBounds().toBBoxString().split(",").map(x => parseFloat(x))
            let bboxArrayReversedConverted = [proj4('EPSG:4326', 'EPSG:2180', [bboxArray[0], bboxArray[1]]), proj4('EPSG:4326', 'EPSG:2180', [bboxArray[2], bboxArray[3]])]
            setAoiWkt(bboxArrayReversedConverted.toString())

            if (result === null) {
                console.log("Nowa analiza")
                apiLocalAnalysis.postLocalanalysis({
                    body: {
                        y: coords[0],
                        x: coords[1],
                        // geom_wkt: wkt_str
                        geom: array
                    }
                }).then((body) => {
                    setAnalysisID(body.id)
                    setResult(body)
                }).catch(reason => {
                    setInfo({message: reason.message,type: "error"})
                    console.log(reason)
                })
            } else {
                apiLocalAnalysis.patchLocalanalysisByAnalysisId({
                    body: {
                        y: coords[0],
                        x: coords[1],
                        // geom_wkt: wkt_str
                        geom: array
                    }, analysis_id: analysisId
                })
                    .then((body) => {
                        // console.log("patch", body)
                        // setResult(body)
                    }).finally(() => {
                    apiLocalAnalysis.getLocalanalysisByAnalysisId({analysis_id: analysisId})
                        .then((body) => {

                            setRouteOrigin([body.geom_point.lat, body.geom_point.lon])
                            setResult(body)
                        }).catch(reason => {
                            setInfo(reason)
                        console.log(reason)
                    })

                }).catch(reason => console.log(reason))
                console.log("do updateu")


                // apiLocalAnalysis.putLocalanalysisByAnalysisId({analysis_id: analysisId})

            }
        })
            .catch(reason => {
                console.log(reason)
            })
    }

    useEffect(() => {
        function addPointAoiToMap(e) {
            setAoiPoint([e.latlng.lat, e.latlng.lng])
            setPoiRouteOrigin([e.latlng.lat, e.latlng.lng])
            // setRouteOrigin([e.latlng.lat, e.latlng.lng])
            setAoiDrawingActive(false)
            document.getElementById(props.id).style.cursor = ''
        }

        if (aoiDrawingActive) {
            // window.document.style.cursor = 'crosshair'
            document.getElementById(props.id).style.cursor = 'crosshair'
            mapRef.current.on("click", addPointAoiToMap)
        }

        mapRef.current.eachLayer((layer) => {
            if (layer.options.name === "userRoute") layer.removeFrom(mapRef.current)
        })


        return () => {
            mapRef.current.off("click", addPointAoiToMap)
        }
    }, [aoiDrawingActive, aoiPoint])

    //Filtering by BBOX, regular wkt generated from polygon is too long for request. More than 9k.
    function getRoute(coords, type) {
        console.log(coords, type)
        console.log([coords[1], coords[0]])
        apiExternal.getApisRouting({
            transportMode: type,
            origin: PoiRouteOrigin,
            destination: [coords[1], coords[0]]
        })
            .then(body => {
                const route = body.routes[0].sections[0].polyline
                const duration = body.routes[0].sections[0].spans[0].duration
                const duration_time = moment.utc(duration * 1000).format('HH:mm:ss');
                const popup = `${duration_time} </br>        
                                   ${body.routes[0].sections[0].spans[0].length / 1000} km </br> ${(type === "car") ? "autem" : "pieszo"}`


                new HighlightablePolyline(route, {
                    generateStyles: (options, renderer) => ({
                        main: {
                            opacity: 0, weight: 20, pane: 'lhl-almost-over',
                            name: "userRoute",
                            snakingSpeed: 800,
                            // raised: false
                        },
                        line1: {
                            ...options,
                            color: '#ffffff', weight: 4,
                            raised: false,
                            renderer
                        },
                        line2: {
                            ...options,
                            dashArray: (type === "car") ? '10,0' : '8,5',
                            color: '#3355DD', weight: 2,
                            renderer
                        }
                    })
                }).on({
                    mouseover: (e) => {
                        e.target.setStyle({weight: 4});
                    },
                    mouseout: (e) => {
                        e.target.setStyle({weight: 4})
                    },
                    click: (e) => {
                    },
                    dblclick: (e) => {
                        e.target.removeFrom(mapRef.current)
                    },
                })
                    .bindPopup(popup)
                    .addTo(mapRef.current)
                    .snakeIn()


                //     .on("click", () => {
                //     // Make the line look selected/unselected on click
                //     const shouldHighlight = !line.realOptions.raised;
                //     line.setStyle({ opacity: shouldHighlight ? 1 : 0.35, raised: shouldHighlight });
                // })


                // new L.featureGroup([
                //
                //     new L.polyline(route, {
                //         fill: false,
                //         weight: 5,
                //         color: 'white',
                //         // dashArray: (isoTransportType === "car") ? '10,0' : '10,5',
                //         // fillOpacity: 0.5,
                //         // opacity: 0.8,
                //         snakingSpeed: 500,
                //         name: "userRoute"
                //     }).on({
                //         mouseover: (e) => {
                //             // e.target.openTooltip()
                //             // var popup = e.target.getPopup();
                //             // popup.setLatLng(e.latlng).openOn(mapRef.current);
                //             e.target.setStyle({weight: 6});
                //         },
                //         mouseout: (e) => {
                //             e.target.setStyle({weight: 5})
                //             //     e.target.closePopup();
                //         }
                //     }),
                //     new L.polyline(route, {
                //         fill: false,
                //         weight: 3,
                //         color: '#3355DD',
                //         // color: '#3a3838',
                //         dashArray: (type === "car") ? '10,0' : '10,5',
                //         fillOpacity: 0.05,
                //         opacity: 0.8,
                //         name: "userRoute",
                //         snakingSpeed: 500
                //     }).on({
                //         mouseover: (e) => {
                //             // e.target.openTooltip()
                //             // var popup = e.target.getPopup();
                //             // popup.setLatLng(e.latlng).openOn(mapRef.current);
                //             e.target.setStyle({weight: 4});
                //         },
                //         mouseout: (e) => {
                //             e.target.setStyle({weight: 3})
                //             //     e.target.closePopup();
                //         }
                //     })
                //         // .setText(`${duration_time} minut ${(type === "car") ? "autem" : "pieszo"}`,
                //         //     {
                //         //         repeat: false,
                //         //         // center:true,
                //         //
                //         //         offset: 10,
                //         //         orientation: 'flip',
                //         //         attributes: {
                //         //             'font-weight': 'regular',
                //         //             'font-size': '8',
                //         //             'box-shadow': '6px 6px 10px black',
                //         //
                //         //         }
                //         //     })
                //         .bindPopup(popup)
                //
                // ]).on({
                //     mouseover: (e) => {
                //         // e.target.openTooltip()
                //         // var popup = e.target.getPopup();
                //         // popup.setLatLng(e.latlng).openOn(mapRef.current);
                //         e.target.bringToFront()
                //     },
                //     dblclick: (e) => {
                //         // e.target.setStyle({weight: 2})
                //         // e.target.closePopup();
                //         e.target.removeFrom(mapRef.current)
                //     },
                // })
                //     .addTo(mapRef.current)
                //     .snakeIn()
                //     .bringToFront()
            })
    }

    useEffect(() => {
        if (result) {
            result.result.map((element, index) => {
                let categoryId = index
                element.objects.map((layers) => {
                    if (layers.count !== 0) {
                        fetch(instance.geoserverUrl + "/pai/ows" +
                          "?service=WFS&version=1.0.0&request=GetFeature" +
                          "&typeName=" + layers.layer_name +
                          "&outputFormat=application%2Fjson" +
                          "&srsName=EPSG:4326" +
                          "&cql_filter=" + `BBOX(geom,${aoiWkt})` +
                          "&jwt=" + JSON.parse(localStorage.pai_tokens).access_token
                        ).then(
                          (res) => res.json()
                        ).then((data) => {
                            setPois(oldArray => [...oldArray, {
                                [layers.layer_name]: L.geoJSON(data, {
                                    layerName: layers.layer_name,
                                    layerCategory: categoryId,
                                    pointToLayer: (feature, latlng) => {
                                        // var opt = {
                                        //     borderColor: '#000000',
                                        //     borderWidth: 0,
                                        //     // html: `<span class="material-icons md-10">${indicatorLayers.value[0].symbol}</span>`,
                                        //     html: `<span class="material-icons md-10">${layers.layer_symbol}</span>`,
                                        //     // backgroundColor:indicatorLayers.value[0].style,
                                        //     backgroundColor: 'rgb(255,217,102,1)',
                                        //     // backgroundColor: indicatorLayers.value[0].style.replaceAll('"','\''),
                                        //     textColor: "white",
                                        //     // icon: 'leaf',
                                        //     iconSize: [20, 20],
                                        //     popupAnchor: [7, 0],
                                        //     //     html: `<span style="{{font-size: 10px}}" class="material-icons md-36">${indicatorLayers.value[0].symbol}</span>`,
                                        //     // html: `<span class="material-icons">indicatorLayers.value[0].symbol</span>`,
                                        //     iconShape: 'circle-dot',
                                        //     highlight: "temporary",
                                        // }

                                        let poiObj = turf.point([latlng.lat, latlng.lng]);
                                        let areaObj = turf.polygon([aoiGeometry]);

                                        if (turf.booleanPointInPolygon(poiObj, areaObj))
                                            return L.marker(latlng, {
                                                icon: L.BeautifyIcon.icon(layers.layer_style)
                                            })
                                    },
                                    onEachFeature: (feature, layer) => {
                                        var carRoute = L.DomUtil.create('input', 'material-icons button');
                                        carRoute.type = "button";
                                        carRoute.title = "no cat";
                                        carRoute.value = 'time_to_leave'


                                        L.DomEvent.addListener(carRoute, 'click', function () {
                                            getRoute(feature.geometry.coordinates, "car")
                                        });

                                        var pedestrianRoute = L.DomUtil.create('input', 'material-icons button ');
                                        pedestrianRoute.type = "button";
                                        pedestrianRoute.value = "directions_walk";

                                        L.DomEvent.addListener(pedestrianRoute, 'click', function () {
                                            getRoute(feature.geometry.coordinates, "pedestrian")
                                        });

                                        var popupContent = document.createElement('div');

                                        for (const [key, value] of Object.entries(feature.properties)) {
                                            popupContent.innerHTML += `<b>${key}</b>: ${value !== null ? value : "-"}</br>`
                                        }

                                        popupContent.appendChild(carRoute);
                                        popupContent.appendChild(pedestrianRoute);

                                        layer.bindPopup(popupContent);
                                    }
                                })
                            }]);
                        })
                          .catch((err) => {
                              console.log('coś poszło nie tak', err);
                          });
                    }
                })
            })
        }
        return () => {
            setPois([])
        }
    }, [result])


    const PoiFeatureGroupRef = useRef(null)
    useEffect(() => {
        if (PoiFeatureGroupRef.current) {
            pois.map(el => {
                for (const [key, value] of Object.entries(el)) {
                    (categoriesVisibility[value.options.layerCategory]) ?
                        PoiFeatureGroupRef.current.addLayer(value) :
                        PoiFeatureGroupRef.current.removeLayer(value)
                }
            })
            PoiFeatureGroupRef.current.addTo(mapRef.current)
        } else {
            PoiFeatureGroupRef.current = new L.FeatureGroup()
        }
        // console.log(pois)
    }, [pois, categoriesVisibility])

    useEffect(() => {
        PoiFeatureGroupRef.current.eachLayer(layer => {
            if (layer.options.layerName === highlights) {
                layer.eachLayer((subLayer) => {
                    // bounce(layer2);
                    // layer2.bounce({
                    //     duration: 1000,
                    //     height: 50,
                    //     // loop: -1
                    // }, () => {
                    //     // console.log("done");
                    // });
                    subLayer.setOpacity(1)
                    subLayer.setZIndexOffset(2000);
                    // console.log(layer2)
                    // .enableTemporaryHighlight()
                })
            } else {
                layer.eachLayer((subLayer) => {
                    subLayer.setZIndexOffset(1000);
                    // layer3.bounce({
                    //     duration: 0,
                    //     height: 0,
                    //     loop: 0
                    // }, () => {
                    //     // console.log("done");
                    // });
                    subLayer.setOpacity(0)
                })
            }

            if (highlights === null) {
                PoiFeatureGroupRef.current.eachLayer(layer => {
                    layer.eachLayer((subLayer) => {
                        subLayer.setOpacity(1)
                        // subLayer.bounce({
                        //     duration: 0,
                        //     height: 0,
                        //     loop: 0
                        // }, () => {
                        //     // console.log("done");
                        // });
                    })
                })
            }
        })
    }, [highlights])

    // mapTools hooks
    useOverLayerSwitcher(overLayer, mapRef.current)
    useBaseLayerSwitcher(baseLayer, mapRef.current)

    //Isochrones hooks
    useIsochroneTool(addIsoPoint, mapRef.current)
    useRemoveIsochrones(removeIso, mapRef.current)
    useGenerateIsochrone(generateIso, isochroneStart, isoTransportType, isoTime, mapRef.current)
    //measuring hooks
    useMeasureTools(measureLine, measurePolygon, measurePoint, clearMeasures, mapRef.current)
    // user Pois hooks
    useHideUserPoints(hideUserPoint, mapRef.current)
    useUserPoint(addUserPoint, userPointLabel, userPointColor, mapRef.current)

    //routing hooks
    useRouteDestination(addRouteDestination, mapRef.current)
    useRouteOrigin(addRouteOrigin, mapRef.current)
    useGenerateRoute(generateRoute, routeOrigin, routeDestination, routeTransportType, mapRef.current)
    useRemoveRoutes(removeRoutes, mapRef.current)

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "calc(100vh - 64px)",
                    position: "relative"
                }}
            >
                <div id={props.id} style={{
                    position: "absolute",
                    // top: 0,
                    // left: 0,
                    width: "100%",
                    height: "calc(100vh - 64px)",
                }}></div>
                <MapLayerSwitcher/>
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "200px",
                    height: "70%",
                    // backgroundColor: "white",
                    zIndex: 402,
                    pointerEvents: "none",
                    // position: "relative !important"
                }}>
                    <SpatialTools/>
                </div>
            </div>
        </>
    );
}
