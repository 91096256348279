import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useEffect} from "react";


const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        width: 500,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: 'black',
        },
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
}));

function UpDownButton() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles()
    const [checked, setChecked] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
        setChecked((prev) => !prev);
    };

    useEffect(() => {
        window.scrollTo({top: (checked) ? 0 : document.body.scrollHeight, behavior: 'smooth'})
    }, [checked])


    return (
        <>
            {matches &&
            <IconButton
                style={{position: "fixed", bottom: 40, right: 45, zIndex: 9000}}
                boxShadow={1}
                variant="contained"
                // size="small"
                color="primary"
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label={(expanded) ? "do góry" : "w dół"}
            >
                <ExpandMoreIcon/>
            </IconButton>

            }
        </>

    )

}

export default UpDownButton
